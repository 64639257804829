import MediaQuery from '@/assets/ts/media_query';

const maskText = (value: string, pattern: string): string => {
    if (!value?.length) return;
    let i = 0;
    const v = value.toString();
    return pattern
        .replace(/#/g, (_) => v[i++])
        ?.replaceAll(undefined, '')
        ?.trim();
};

const isMobileBrowser = (): boolean => {
    return window.innerWidth < MediaQuery.mediumDeviceWidth;
};
const sanitizeTitle = (title: string): string => {
    let slug = '';
    const titleLower = title.toLowerCase();
    slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
    slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
    slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
    slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
    slug = slug.replace(/đ/gi, 'd');
    slug = slug.replace(/\s*$/g, '');
    slug = slug.replace(/\s+/g, '-');
    return slug;
};

const isEmptyObject = (obj: object) => {
    return obj ? Object.keys(obj).length == 0 : true;
};

const createUUID = (id: number): string => {
    let localId: number = id;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (letter) {
        const replacement = (localId + Math.random() * 16) % 16 | 0;
        localId = Math.floor(localId / 16);
        return (letter === 'x' ? replacement : (replacement & 0x3) | 0x8).toString(16);
    });
};

const getHexColorFromBase16 = (color?: string): string => {
    if (!color) return '#e73f51';
    return `#${color.substr(color.length - 6)}`;
};

const getFacebookImage = (facebookUserId: string): string => {
    return `https://graph.facebook.com/v20.0/${facebookUserId}/picture?type=large`;
};

const generateRandomColor = (): string => {
    //generates a random HEX color without #...Eg. ff9012
    return Math.floor(Math.random() * 16777215).toString(16);
};

const isFicoaDomain = (): boolean => {
    return /catalog-ficoa/i.test(window.location.pathname);
};

export {
    isFicoaDomain,
    isMobileBrowser,
    sanitizeTitle,
    isEmptyObject,
    createUUID,
    maskText,
    getHexColorFromBase16,
    getFacebookImage,
    generateRandomColor,
};
