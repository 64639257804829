<template>
    <footer v-if="showFooter && !isScreenCheckout" class="footer-main">
        <div class="footer-main__content">
            <aside class="footer-main-brand">
                <tipti-with-slogan :positive-contrast="true" />

                <nav class="footer-main-brand__social-network">
                    <a :href="countrySelected?.twitter" rel="noopener" target="_blank">
                        <icon-twitter :size="0.8" />
                    </a>
                    <a :href="selectInstagram" rel="noopener" target="_blank">
                        <icon-instagram :size="0.8" />
                    </a>
                    <a :href="selectYoutobe" rel="noopener" target="_blank">
                        <icon-youtube :size="1.1" />
                    </a>
                    <a :href="countrySelected?.linkedin" rel="noopener" target="_blank">
                        <icon-linked-in :size="0.8" />
                    </a>

                    <div class="cursor-pointer" @click="openWhatsApp({ text: 'Hey, Tipti...' })">
                        <icon-whats-app :size="0.8" color="#FF9012" />
                    </div>
                </nav>
            </aside>
            <aside class="footer-main-routes">
                <nav class="footer-main-routes__actions">
                    <a
                        v-if="!isAuth"
                        class="footer-main-routes__action cursor-pointer"
                        rel="noopener"
                        target="_blank"
                        @click="switchToLogin"
                        >Iniciar Sesión
                    </a>
                    <a
                        v-if="!isAuth"
                        class="footer-main-routes__action cursor-pointer"
                        rel="noopener"
                        target="_blank"
                        @click="switchToRegister"
                        >Regístrate
                    </a>

                    <router-link
                        v-for="section in primarySection"
                        :key="section.nameRoute"
                        class="footer-main-routes__action"
                        :to="{ name: section.nameRoute }"
                    >
                        {{ section.name }}
                    </router-link>
                </nav>
                <nav class="footer-main-routes__actions">
                    <router-link class="footer-main-routes__action" :to="{ name: appRoutesMap.news.home }">
                       {{ $t("general['news']")}}
                    </router-link>
                    <a
                        class="footer-main-routes__action"
                        href="https://www.tiptisemillerosdetalento.com/"
                        rel="noopener"
                        target="_blank"
                        >{{$t("general['talent-hotbeds']")}}
                    </a>

                    <router-link class="footer-main-routes__action" :to="{ name: appRoutesMap.about }">
                        {{ $t("cta['about-tipti']")}}
                    </router-link>
                    <a
                        class="footer-main-routes__action"
                        href="https://tipti.hiringroom.com/jobs"
                        rel="noopener"
                        target="_blank"
                        >{{ $t("txt['how-works__work-with-us']")}}
                    </a>
                </nav>
                <nav class="footer-main-routes__actions">
                    <router-link
                        v-for="section in thirdSection"
                        :key="section.nameRoute"
                        class="footer-main-routes__action"
                        :to="{ name: section.nameRoute }"
                        target="_blank"
                    >
                        {{ section.name }}
                    </router-link>
                </nav>
            </aside>
            <aside class="footer-main-contact">
                <div class="contac-grid">
                    <icon-email-footer :size="0.9" />
                    <p class="phone-number">{{ countrySelected?.email }}</p>
                </div>
                <div class="contac-grid">
                    <icon-phone :size="1.1" />
                    <div class="number-grid">
                        <div class="number-grid__item">
                            <p class="phone-number">{{ countrySelected?.mainPhone }}</p>
                            <span class="phone-number phone-number--type">
                                {{ $t("general['client-service-option']") }}
                            </span>
                        </div>
                        <template v-if="countrySelected?.secondPhone?.length">
                            <div class="number-grid--divider"></div>
                            <div v-if="countrySelected?.secondPhone?.length" class="number-grid__item">
                                <p class="phone-number">{{ countrySelected?.secondPhone }}</p>
                                <span class="phone-number phone-number--type"> {{ $t('general.elder-people') }}</span>
                            </div>
                        </template>
                        <template v-if="countrySelected?.cellphone?.length">
                            <div class="number-grid--divider"></div>
                            <div class="number-grid__item">
                                <p class="phone-number">{{ countrySelected?.cellphone }}</p>
                                <span class="phone-number phone-number--type"> {{ $t('icon.whatsapp') }}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </aside>
        </div>
        <aside class="stores">
            <div class="footer-phone">
                <aside class="footer-main-brand--mobile">
                    <tipti-with-slogan :positive-contrast="true" :size="1.6" />

                    <nav class="footer-main-brand__social-network">
                        <a :href="countrySelected?.twitter" rel="noopener" target="_blank">
                            <icon-twitter :size="0.9" />
                        </a>
                        <a :href="selectInstagram" rel="noopener" target="_blank">
                            <icon-instagram :size="0.9" />
                        </a>
                        <a :href="selectYoutobe" rel="noopener" target="_blank">
                            <icon-youtube :size="1.2" />
                        </a>
                        <a :href="countrySelected?.linkedin" rel="noopener" target="_blank">
                            <icon-linked-in :size="0.9" />
                        </a>

                        <div class="cursor-pointer" @click="openWhatsApp({ text: 'Hey, Tipti...' })">
                            <icon-whats-app :size="0.9" color="#FF9012" />
                        </div>
                    </nav>
                </aside>
                <div class="footer-apps">
                    <p class="stores__text">{{ $t("general['download-the-app']").toUpperCase() }}</p>
                    <mobile-app-stores can-redirect />
                </div>
            </div>
        </aside>
    </footer>
    <footer-mobile v-if="showFooter && !isScreenCheckout" />
    <auth v-if="showLogin" :is-register="showRegister" @on-close="showLogin = false"></auth>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import FooterMobile from '../mobile/FooterMobile.vue';
import { RoutesOrigin } from '@/enums/routesOrigin';
import { useUser } from '@/composables/useUser';
import { useWhatsApp } from '@/composables/WhastAppComposable';
import IconTwitter from '@/components/icons/IconTwitter.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';
import IconYoutube from '@/components/icons/IconYoutube.vue';
import IconLinkedIn from '@/components/icons/IconLinkedIn.vue';
import IconWhatsApp from '@/components/icons/IconWhatsApp.vue';
import TiptiWithSlogan from '@/components/logos/TiptiWithSlogan.vue';
import MobileAppStores from '@/components/footers/footerMain/MobileAppStores.vue';
import Auth from '@/views/auth/Auth.vue';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconEmailFooter from '@/components/icons/IconEmailFooter.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'FooterMain',
    components: {
        IconEmailFooter,
        IconPhone,
        Auth,
        MobileAppStores,
        IconWhatsApp,
        IconLinkedIn,
        IconYoutube,
        IconInstagram,
        IconTwitter,
        FooterMobile,
        TiptiWithSlogan,
    },
    setup() {
        const { openWhatsApp } = useWhatsApp();
        const { userLocation, countrySelected, isAuth } = useUser();
        const { isScreenCheckout, isScreenSearch, isScreenSeeMore } = useRoutes();
        return {
            openWhatsApp,
            userLocation,
            countrySelected,
            isAuth,
            isScreenCheckout,
            isScreenSearch,
            isScreenSeeMore,
        };
    },
    data() {
        return {
            appRoutesMap,
            primarySection: [
                {
                    nameRoute: appRoutesMap.faq,
                    name: this.$t("general['help']")
                },
                {
                    nameRoute: appRoutesMap.promotions.home,
                    name: this.$t("general['promotions']")
                },
            ],

            thirdSection: [
                { nameRoute: appRoutesMap.privacyData.policies, name: this.$t("general['privacy-policy-about']") },
                {
                    nameRoute: appRoutesMap.privacyData.personalData,
                    name: this.$t("general['protection-of-personal-data']")
                },
            ],
            showLogin: false,
            showRegister: false,
        };
    },
    methods: {
        switchToRegister() {
            this.$store.dispatch('segment/openSignUp');
            this.showLogin = true;
            this.showRegister = true;
        },
        switchToLogin() {
            this.$store.dispatch('segment/openSignIn');
            this.showLogin = true;
            this.showRegister = false;
        },
    },
    computed: {
        ...mapGetters({
            isLastPageProducts: 'product/isLastPage',
        }),
        showFooter(): boolean {
            return isMobileBrowser()
                ? true
                : (this.isLastPageProducts &&
                      (this.$route.path.includes(RoutesOrigin.MAIN_CATEGORY_PRODUCTS) ||
                          this.isScreenSearch ||
                          this.isScreenSeeMore)) ||
                      (!this.$route.path.includes(RoutesOrigin.MAIN_CATEGORY_PRODUCTS) &&
                          !this.isScreenSearch &&
                          !this.isScreenSeeMore);
        },
        selectYoutobe(): string {
            if (this.userLocation?.city?.country?.alphaCode === 'PA') {
                return 'https://www.youtube.com/channel/UCYY4odsCUKAt97mF0ToGFPA';
            }
            return 'https://www.youtube.com/channel/UCT0_fPXAXczZ6lR2_Siulwg';
        },
        selectInstagram(): string {
            if (this.userLocation?.city?.country?.alphaCode === 'PA') {
                return 'https://www.instagram.com/tipti.market.pa';
            }
            return this.countrySelected?.instagram;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './footer-main.scss';
</style>
