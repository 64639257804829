<template>
    <section class="main-info__container">
        <base-card v-if="!isHighValue" class="main-info__card">
            <h2>{{ $t('txt.statistics__accumulated-orders') }}</h2>
            <div class="main-info__card-value">
                <h3>{{ overAllStatistics?.overall_effective_orders }}</h3>
                <p>{{ $t("general['orders']", { count: overAllStatistics?.overall_effective_orders ?? 0 }) }}</p>
            </div>
        </base-card>
        <base-card class="main-info__card" :class="{ 'main-info__card__full': isHighValue }">
            <h2>{{ $t('txt.statistics__total-savings') }}</h2>
            <div class="main-info__card-value" :class="{ 'main-info__card-value__full': isHighValue }">
                <h3 v-currency="monthSavings" />
                <p>{{ currencyName }}</p>
            </div>
        </base-card>
    </section>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';
import { useUser } from '@/composables/useUser';

const { isMobile } = useApp();
const { currencyName } = useUser();
const { overAllStatistics, selectedMonthIndex } = useStatistics();

const isHighValue = computed(() => (isMobile.value && overAllStatistics.value?.overall_savings) >= 1000);
const monthSavings = computed(
    () =>
        overAllStatistics.value?.current_year_monthly_statistics?.find(
            (month) => month.month === selectedMonthIndex.value,
        )?.month_savings,
);
</script>

<style lang="scss" scoped>
@import './main-info.scss';
</style>
