<script lang="ts" setup>
import { useUser } from '@/composables/useUser';
import { PropType, ref, watch } from 'vue';
import { useImage } from '@/composables/ImageComposable';
import IconDiamond from '@/components/icons/IconDiamond.vue';
import IconCrown from '@/components/icons/IconCrown.vue';

const props = defineProps({
    origin: {
        type: String as PropType<'menu' | 'sidebar' | 'profile'>,
        required: true,
    },
    picture: {
        type: Object as PropType<File>,
        default: undefined,
    },
});
const { profile, isPrime } = useUser();

const { displayDefaultUserImg } = useImage();

const file = ref<File | null | undefined>();
let input: HTMLInputElement | undefined;
const $emits = defineEmits<(e: 'on-change', value: File | undefined) => void>();

if (document && props.origin === 'profile') {
    input = document.createElement('input');
    input.type = 'file';

    input.onchange = (event: Event) => {
        const input = event.target as HTMLInputElement;

        if (!input.files || input.files.length <= 0) {
            file.value = undefined;
            $emits('on-change', undefined);
            return;
        }
        file.value = input.files[0];
        $emits('on-change', file.value ?? undefined);
    };
}

const imageTarget = ref(profile.value.profilePicture);

watch(
    () => props.picture,
    (newVal) => {
        if (!newVal) {
            imageTarget.value = profile.value.profilePicture ?? '';
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            imageTarget.value = e.target.result;
        };
        reader.readAsDataURL(newVal);
    },
);

watch(
    () => profile.value.profilePicture,
    (value) => {
        imageTarget.value = value ?? '';
    },
    {
        immediate: true,
    },
);

const open = () => {
    if (!input) return;
    input.multiple = false;
    input.accept = 'image/*';
    input.click();
};
</script>

<template>
    <figure
        class="menu-user-info"
        :class="{
            'menu-user-info--relative': isPrime || origin === 'profile',
        }"
    >
        <img
            class="radius-circular border-primary user-image"
            :class="{
                'user-image--menu': origin == 'menu',
            }"
            :src="imageTarget"
            alt=""
            @error="displayDefaultUserImg"
        />
        <i
            v-if="isPrime || origin === 'profile'"
            class="absolute radius-circular prime-identifier"
            :class="{
                'bg-on-primary': origin === 'profile',
                'bg-primary': !profile.isExclusive && origin !== 'profile',
                'bg-inverse-surface': profile.isExclusive && origin !== 'profile',
                'prime-identifier--menu': origin === 'menu',
            }"
            @click="open"
        >
            <img v-if="origin === 'profile'" alt="" src="@/assets/svg/ic_camera.svg" />
            <IconDiamond v-else-if="profile.isExclusive" :size="origin === 'menu' ? 0.7 : 1" />
            <IconCrown v-else :size="origin === 'menu' ? 0.7 : 1" />
        </i>
    </figure>
</template>

<style lang="scss" scoped>
.user-image {
    max-width: 10rem;
    aspect-ratio: 1/1;

    &--menu {
        max-width: 6rem;
    }
}

.radius-circular {
    border-radius: 50%;
}

.menu-user-info {
    margin: 0;
    position: relative;
}

.border-primary {
    border: 3px solid orange;
}

.prime-identifier {
    position: absolute;
    display: grid;
    place-content: center;
    right: 0;
    bottom: 0;
    width: 2.7rem;
    aspect-ratio: 1/1;

    &--menu {
        width: 2rem;
    }
}

.bg-primary {
    background-color: orange;
}

.bg-inverse-surface {
    background-color: #333333;
}

.bg-on-primary {
    background-color: white;
    border: 1px solid orange;
}
</style>
