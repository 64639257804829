import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/prime_logo.svg'
import _imports_1 from '@/assets/svg/ic_prime_badge.svg'


const _withScopeId = n => (_pushScopeId("data-v-6cffaa1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "banner-prime--img",
  alt: "banner-prime-normal",
  src: _imports_0
}
const _hoisted_2 = { class: "margin--horizontal" }
const _hoisted_3 = { class: "information" }
const _hoisted_4 = { class: "information__name" }
const _hoisted_5 = { class: "information__email" }
const _hoisted_6 = { class: "information__ID" }
const _hoisted_7 = { class: "information__amount" }
const _hoisted_8 = { class: "information__orders" }
const _hoisted_9 = { class: "prime-op__close-session" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserProfilePhoto = _resolveComponent("UserProfilePhoto")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_the_btn_menu = _resolveComponent("the-btn-menu")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_close_session_modal = _resolveComponent("close-session-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, {
      class: "profile-options",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["profile-options__background", $options.getContainerStyle])
        }, [
          (_ctx.profile.isPrime && !_ctx.profile.isExclusive)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true),
          (!_ctx.profile.isPrime)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "banner--img cursor-pointer",
                alt: "se prime",
                src: _imports_1,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: $data.appRoutesMap.account.tiptiPrime })))
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_UserProfilePhoto, { origin: "sidebar" })
        ], 2),
        _createElementVNode("aside", _hoisted_2, [
          _createVNode(_component_base_card, { class: "profile-information" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.profile.name), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString($options.getCorrectEmail()), 1),
                _createElementVNode("p", _hoisted_6, "ID " + _toDisplayString(_ctx.profile.id), 1)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["information", { 'information-active': $data.activeOption === $data.statisticsRoute }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.changeOption($data.statisticsRoute)))
              }, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.profile.totalOrders), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("general['orders']", { count: _ctx.profile.totalOrders }).toUpperCase()), 1)
              ], 2)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.editProfileOptions, (option) => {
            return (_openBlock(), _createBlock(_component_the_btn_menu, {
              key: option.route,
              class: _normalizeClass({ 'hide-profile-option-in-phone': option.route === 'bills-and-document' }),
              "link-img": option.icon,
              selected: $data.activeOption === option.route,
              txt: option.title,
              onClick: ($event: any) => ($options.changeOption(option.route))
            }, null, 8, ["class", "link-img", "selected", "txt", "onClick"]))
          }), 128)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", {
              class: "prime-op__close-session-txt",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.showLogoutModal = true))
            }, _toDisplayString(_ctx.$t('cta.close-session')), 1)
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_snack_bar, {
      body: $data.message,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[3] || (_cache[3] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"]),
    ($data.showLogoutModal)
      ? (_openBlock(), _createBlock(_component_close_session_modal, {
          key: 0,
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => ($data.showLogoutModal = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}