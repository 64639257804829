<template>
    <tipti-input-base
        v-bind="{
            ...$props,
            errors: localErrors,
        }"
    >
        <input
            :id="label"
            ref="inputText"
            v-model="inputValue"
            :aria-label="label"
            :aria-required="isRequired"
            :autocomplete="autocomplete"
            :max="max"
            :maxlength="maxlength"
            :min="min"
            :name="label"
            :pattern="pattern"
            :placeholder="placeholder"
            :readonly
            :required="isRequired"
            :type="type"
            @blur="validateOnBlur"
        />
    </tipti-input-base>
</template>

<script lang="ts" setup>
import TiptiInputBase from '@/components/inputs/primary/normal/TiptiInputBase.vue';
import { PropType, ref } from 'vue';
import { useInput } from '@/components/inputs/primary/normal/useInput';

const inputValue = defineModel({
    required: true,
    type: String,
});

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    type: {
        type: String as PropType<'text' | 'email' | 'date'>,
        default: 'text',
    },
    autocomplete: {
        type: String,
        default: undefined,
    },
    placeholder: {
        type: String,
        default: '',
    },
    isInvalid: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
    maxlength: {
        type: Number,
        default: undefined,
    },
    max: {
        type: [String, Number],
        default: undefined,
    },
    min: {
        type: [String, Number],
        default: undefined,
    },
    pattern: {
        type: String,
        default: undefined,
    },
    hasFocus: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
});
const inputText = ref<HTMLInputElement>();
const $emits = defineEmits<(e: 'blur') => void>();

const { validateOnBlur, localErrors } = useInput<string>(props, $emits, inputValue);
</script>
