<template>
    <base-icon :height="dimensions.height" :icon-color="color" :width="dimensions.width" viewBox="0 0 23 20">
        <path
            d="M18.0592 16.5996H3.97918C3.25142 16.5996 2.65918 17.1918 2.65918 17.9196C2.65918 18.6474 3.25142 19.2396 3.97918 19.2396H18.0592C18.7869 19.2396 19.3792 18.6474 19.3792 17.9196C19.3792 17.1918 18.7869 16.5996 18.0592 16.5996Z"
            fill="white"
        />
        <path
            d="M20.2581 3.39977C19.2874 3.39977 18.4981 4.18914 18.4981 5.15977C18.4981 5.81186 18.8589 6.37591 19.3877 6.68039C18.3696 9.09158 16.7812 10.5709 15.2966 10.4371C13.6457 10.3025 12.2993 8.35062 11.5566 5.08496C12.5087 4.84382 13.218 3.98582 13.218 2.95977C13.218 1.74624 12.2315 0.759766 11.018 0.759766C9.8045 0.759766 8.81802 1.74624 8.81802 2.95977C8.81802 3.98586 9.52731 4.84386 10.4795 5.08496C9.73674 8.35062 8.39031 10.3025 6.73945 10.4371C5.26107 10.5709 3.6656 9.09158 2.64836 6.68039C3.17721 6.37591 3.53802 5.81182 3.53802 5.15977C3.53802 4.18914 2.74864 3.39977 1.77802 3.39977C0.807445 3.39977 0.0180664 4.18914 0.0180664 5.15977C0.0180664 6.06267 0.704449 6.8001 1.58093 6.90039L3.27583 15.7198H18.7603L20.4552 6.90039C21.3317 6.8001 22.0181 6.06267 22.0181 5.15977C22.0181 4.18914 21.2287 3.39977 20.2581 3.39977Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { computed } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FFFFFF',
    },
});

const dimensions = computed(() => ({
    width: 23 * props.size,
    height: 20 * props.size,
}));
</script>
