import { i18Nbreadcrumb } from '@/vi18/i18Nbreadcrumb';
import { i18Seo } from '@/vi18/i18Seo';
import { i18nInput } from '@/vi18/i18nInput';
import { i18Alerts } from '@/vi18/i18Alerts';

export default {
    ...i18Nbreadcrumb.ES,
    ...i18Seo.ES,
    ...i18nInput.ES,
    ...i18Alerts.ES,
    txt: {
        schedule_updated: 'Horario de entrega actualizado.',
        membership_cards: 'Tarjetas de afiliación',
        cards_with_balance: 'Tarjetas con saldo',
        deleted_card_msg:
            '¿Estás seguro de que quieres eliminar tu tarjeta? Al eliminarla no podrás tener acceso al historial de la misma.',
        order: 'Pedido',
        credit_card_no_transactions: 'No tienes consumos realizados con esta tarjeta durante este mes',
        transactions: 'transacciones',
        orders: 'Pedidos',
        expense: 'gasto',
        total_expense: 'Gasto total',
        payments: 'cuotas',
        months: 'meses',
        payment_facilities: 'Facilidades de pago',
        low: 'pocos',
        in_stock: 'en stock',
        reference_fee:
            'Cuotas de referencia. Una vez que seleccione el método de pago para su pedido, se calculará el valor final.',
        resent_payment_title: 'Estás cambiando el método de pago de la orden #{order}',
        credit_card__expired_info: 'Parece que esta tarjeta ha expirado',
        credit_card__expired: 'Tarjeta expirada',
        'by-completing-your-registration':
            'Al completar tu registro, nos permite actualizar y verificar los beneficios de afiliado',
        'error-try-again-in-a-few-minutes': 'Hubo un problema en procesar tu solicitud, inténtalo más tarde',
        'complete-register-title': 'Completa tu registro aquí',
        'complete-register-description': '¡Beneficios de afiliado!',
        'registration-pending-profile': '¡Desbloquea beneficios exclusivos! Completa tu registro en Tipti',
        'pending-affiliation': 'Afiliación pendiente. Forma parte y disfruta de  beneficios',
        'successful-registration-access-to-benefits': '¡Registro exitoso! Accede a beneficios exclusivos.',
        'successful-affiliation': 'Afiliación exitosa. ¡Disfruta de tus beneficios!',
        'renewal-pending': 'Renovación pendiente',
        'successful-renovation': 'Renovación exitosa',
        discount_5x5_applied: 'Por agregar 5 unidades tienes 5% de descuento en este producto',
        discount_5x5_lost: 'Descuento no aplicado, agrega mínimo 5 unidades para activar el beneficio',
        extra_items__header_cart: 'Resumen de Añadidos',
        extra_items__button_cart: 'Añadir Productos',
        order_cancelled: 'Orden cancelada',
        address: 'Domicilio',
        extra_items__active_order: 'Tienes un pedido actual',
        active_order__message: 'Agrega los productos seleccionados a tu pedido actual',
        add_in_order: 'Añadir al pedido activo',
        create_new_cart: 'Crear un nuevo carrito',
        sure_to_leave: '¿Estás seguro de querer salir?',
        any_product_added: 'Ningún producto será añadido si sales de esta pantalla',
        stay_here: 'Permanecer aquí',
        yes_quit: 'Si, salir',
        added_cart: 'carrito de añadidos',
        added_cart__mobile: 'añadidos',
        extra_items__limit_overpassed: 'Has superado el límite de productos a añadir  en tu pedido activo',
        extra_items_current_order: 'Estas añadiendo productos al pedido ',
        extra_item_limit: 'Recuerda: solo puedes añadir {limit} productos a tu pedido en curso',
        search_result_empty: 'Producto no disponible en catálogo digital',
        new_on_tipti: 'Lo nuevo en Tipti',
        product_carry: 'Agrega {take} paga {pay}',
        subscription__not_selected: 'Aquí podrás ver el detalle de la membresía que elijas.',
        deactivated__products: 'Este artículo se agotó en la tienda | Estos artículos se agotaron en la tienda',
        deactivated__products_not_found: 'Lo sentimos, no hemos encontrado productos relacionados con tu elección.',
        deactivated__products_tipti_suggestions: 'Te sugerimos comprar',
        cambrellas_membership: 'Membresía Cambrellas',
        cambrellas__subscription_experience: 'Vive la experiencia Tipti Cambrellas',
        cambrellas__subscription_change: '¿Estás listo para sumarte al cambio?',
        cambrellas__subscription_legend:
            'Ponemos a tu disposición bolsas de tela tipo cambrella para todos tus pedidos.',
        cambrellas__used: 'Cambrellas usadas en tus pedidos',
        subscription__legend:
            'Vive la experiencia Tipti por completo y disfruta de todas las promociones y beneficios con nuestras membresías.',
        subscription__change: '¿Estás listo para sumarte al cambio?',
        subscription__get: '¡Adquiere tu membresía ahora!',
        cambrellas__not_used: 'No has usado ninguna de tus cambrellas disponibles.',
        cambrellas__used_count: 'Has usado {used} de tus {total} cambrellas disponibles.',
        membership__active: 'Membresia activa hasta',
        cambrellas__progress: 'Has utilizado {init} de tus {finish} cambrellas disponibles',
        cambrellas__return:
            'Recuerda devolver las fundas tipo cambrellas a tu repartidor, si se te acaban las fundas disponibles se volverá a realizar el cobro por cada unidad utilizada.',
        cambrellas__expire: 'Membresía activa hasta {date}',
        cambrellas__subscribe_success: 'Felicidades, ahora tienes Cambrellas disponibles para tu compra',
        cambrellas__subscribe_error: 'Uups, algo salio mal, intentalo nuevamente en un momento',
        cambrellas__txt_no_subscription: '¿Quieres reducir este costo?',
        cambrellas__txt_subscription: 'Cambrellas disponibles',
        cambrellas__subscription: 'Suscripción Cambrellas',
        product__delivery_scheduled: 'La entrega del producto {name}, se realizará aproximadamente en {hours}h.',
        product__delivery_scheduled_2:
            'Nuestro repartidor se pondrá en contacto contigo para coordinar la hora de entrega en el día seleccionado.',
        shipping_cost__info:
            'Contratando Tipti Prime disfrutarás de muchos /*BENEFICIOS ESPECIALES/*, incluyendo envio GRATIS',
        shipping_cost__want_free: '¿Quieres el /*ENVIO GRATIS/*?',
        service_fee__info:
            'El valor de comisión de servicios ya esta incluido dentro del valor de los productos y subtotal, aqui podrás ver el desglose a detalle',
        configurable__delivery: 'Este producto tiene una entrega aprox. de {hours}h',
        maxi_dollars__info_moves: 'Aquí se mostrarán solamente los movimientos que se realice',
        maxi_dollars__inside_tipti: 'dentro de Tipti',
        maxi_dollars__cards: 'en tus tarjetas con el beneficio',
        maxi__dollars__benefit: 'MaxiDólares',
        maxi_dollars__error:
            'Tus MaxiDólares disponibles son $ {amount}, no puedes transferir una cantidad mayor a la indicada.',
        maxi_dollars__converter: 'Ingresa los MaxiDólares que deseas convertir a saldo en tu Tipti Card.',
        maxi_dollars__register_card:
            'Si deseas agregar una tarjeta, por favor dirígete a métodos de pago o regístralas',
        maxi_dollars__title: 'Tarjetas disponibles',
        maxi_dollars: 'MaxiDólares',
        no_maxi_cards: 'No tienes ninguna tarjeta con MaxiDólares disponible.',
        maxi_dollars__history_title: 'Historial de Canje MaxiDólares',
        maxi_dollars__amount_text: 'MaxiDólares disponibles',
        see_more_benefits: 'Ver más beneficios',
        available_points: 'Puntos disponibles',
        transfer_to_tipti: 'Transferir a mi Tipti Card',
        transfer_balance: 'Transferir Saldo a Tipti card',
        available_points_error:
            'Tu saldo disponible es {amount}, no puedes transferir una cantidad mayor a la indicada',
        unlink_loyalty: '',
        see_less_benefits: 'Ver menos beneficios',
        available_programs: 'Programa de afiliados disponibles',
        available_rewards: 'Programa de recomponesas disponibles',
        affiliates_and_rewards: 'Afiliados y recompensas',
        show_card__affiliates: 'Tarjeta virtual de afiliados',
        loyalty_program: 'Programa de afiliados',
        loyalty_program__applied: '{program} aplicado con éxito',
        transfer_applied: '{program} transferido con éxito',
        redemption_history: 'Historial de {program} acumulados',
        redeemed_points: 'Puntos Canjeados',
        'profile-options__loyalty': 'Fidelización',
        'profile-options__loyalty_ec': 'Tarjeta de recompensas',
        'profile-options__loyalty_pa': 'Punto de oro',
        billing__required_data: '(*) Campos requeridos',
        billing__document_type: 'Tipo de documento*',
        goal__gift_product: 'Recuerda: este producto será agregado automáticamente despúes de finalizar la compra',
        'pickup__where-do-you': '¿Dónde quieres recoger tu pedido?',
        'pickup__choose-the-point': 'Elige el punto de Pickup',
        'pickup__choose-the-store': 'Elige la tienda por dónde deseas retirar tu pedido',
        'pickup__create-your-order': 'Haz tu pedido',
        'pickup__choose-your-products': 'Elige tus productos favoritos y el horario para pasar por ellas',
        'pickup__go-for-your': 'Pasa por tu pedido',
        'pickup__take-your-order': 'Retira tus compras en el horario seleccionado',
        'pickup__do-your-groceries': 'Haz tu pedido y retira tus compras listas por la tienda',
        pickup__not_avalilable_cash:
            'Por el momento no tenemos Pago Efectivo en Pickup, por favor cambia el método de pago para continuar.',
        'credit-card__should-validate': 'Verificar tarjeta para continuar',
        'credit-card__additionally': 'Adicionalmente el equipo de',
        'credit-card__contact-you': 'se comunicará contigo',
        'credit-card__validate': 'Valida tu tarjeta',
        'credit-card__validate-benefits': 'Beneficios de verificación',
        'credit-card__last-attempt':
            'Recuerda que es tu último intento, de no ser la validación correcta tus ordenes con esta tarjeta seran desactivadas',
        'credit-card__benefit-message':
            'Al tener un método de pago verificado puedes realizar tus pagos de una manera rápida y segura. ',
        'credit-card__validate-failed': 'Verificación fallida',
        'credit-card__amount': 'Este monto será',
        'credit-card__reimbursed': ' reembolsado',
        'credit-card__automatically': 'automáticamente',
        'credit-card__collection-date': 'Cobro realizado: Fecha {date}',
        'credit-card__benefits': '¿Qué beneficios trae verificar mis tarjetas?',
        'credit-card__verification-success': 'Tarjeta verificada con éxito',
        'credit-card__verification-attempt': 'intento | intentos',
        'credit-card__verification-attempts': 'Tienes un máximo de {times} {attempts} para verificar la tarjeta',
        'credit-card__verification-error':
            'Ha ocurrido un problema y no se pudo verificar, por favor intentalo en unos minutos',
        'credit-card__random-value': 'Realizaremos un cargo mínimo a tu tarjeta, por un valor aleatorio a nombre de',
        'credit-card__enter-amount': 'Ingresa el monto cobrado en tu método de pago',
        'credit-card__discount-amount':
            'Te cobraremos un monto aleatorio de hasta {currency}{amount} para verificar que la tarjeta sea válida.',
        credit_card__card_expired: 'Tarjeta caducada',
        credit_card__card_expired_messaje:
            'Su tarjeta ha caducado, para continuar puedes editar los datos o cambiar el método de pago',
        credit_card__remember_expired: ' Recuerda que la tarjeta caducada será desactivada',
        'deactivate-account__shopping-problems': 'He tenido inconvenientes con mis pedidos',
        'deactivate-account__why-deactivate': '¿Cuéntanos por qué quieres desactivar tu cuenta?',
        'deactivate-account__want-deactivate': '¿Deseas desactivar tu cuenta?',
        'deactivate-account__we-sorry': 'Lamentamos que nos dejes. Recuerda que al',
        'deactivate-account__deactivate-acc': 'desactivar tu cuenta',
        'deactivate-account__all': 'todos los',
        'deactivate-account__data': 'datos',
        'deactivate-account__saved': ' guardados en Tipti',
        'deactivate-account__lose': 'se perderán',
        'deactivate-account__not-recovery': 'y no se podrán recuperar.',
        'deactivate-account__expectations': 'No es lo que esperaba',
        'deactivate-account__also': 'Tampoco',
        'deactivate-account__create': 'podrás crear una',
        'deactivate-account__new-acc': 'nueva cuenta',
        'deactivate-account__same-credentials': 'con tus mismas credenciales.',
        'deactivate-account__sure': '¿Estás seguro',
        'deactivate-account__you-want': 'de que quieres',
        'deactivate-account__deactivate': 'desactivar?',
        'deactivate-account__account-deactivated': 'Tu cuenta ha sido desactivada con éxito',
        'page-title__my-account': 'Mi cuenta',
        'page-title__my-account-edit-profile': 'Editar perfil',
        'page-title__my-account-loyalty_program': 'Afiliados y recompensas',
        'page-title__my-account-my-orders': 'Mis órdenes',
        'page-title__my-account-my-order-show': 'Orden #{order_id}',
        'page-title__banner-categories': '{title}',
        'page-title__banner-categories-show-category': '{name}',
        'page-title__my-account-statistics': 'Estadísticas',
        'tipti-prime__no_content': 'Actualmente no existen membresias activas. Vuelve a intetarlo más tarde',
        'tipti-prime__buy-success': 'Felicitaciones! Ahora eres Prime',
        'tipti-card__before': 'Antes de finalizar...',
        'tipti-card__no-reject': 'No, corregir datos',
        'tipti-card__convert-maxi': 'Convertir MaxiDólares',
        'tipti-card__yes-confirm': 'Sí, confirmar transferencia',
        'tipti-card__wrong-data': 'Datos incorrectos',
        'tipti-card__no-beneficiary': 'No existen usuarios con este número de TiptiCard {tiptiCardNumber}',
        'tipti-card__beneficiary-name': 'Nombre del beneficiario:',
        'tipti-card__tipti-card-number': 'Número de Tipti Card:',
        'tipti-card__is-right': '¿Son correctos los datos?',
        'tipti-card__check-your-data': 'Por favor, revisa los datos del beneficiario de tu transferencia',
        'retailer-stats__out-of-coverage': 'Fuera de cobertura',
        'retailer-stats__sector-coverage': 'Esta tienda no se encuentra disponible en tu ubicación actual',
        'giftcard__share-code-link':
            '💵 💵 Te regalo ${value} para tu Tipticard con este código  💵💵  Tu código es: {code}',
        'share-data__terms-and-conditions': 'https://tipti.market/terms_share_data.pdf',
        'my-lists__no-lists': 'No tienes listas creadas',
        'minimum-amount__are-you-sure': '¿Estás seguro de eliminar este producto?',
        'minimum-amount__there-is':
            'Hay un monto mínimo de {currency} {amount} para poder procesar la orden de esta tienda.',
        'minimum-amount__if-deleted':
            'Si lo eliminas tendrás que agregar un item diferente para poder completar el valor mínimo de compra.',
        'minimum-amount__this-store-amount': 'Esta tienda no cumple con el monto mínimo para realizar la compra.',
        'minimum-amount__at-least':
            'Al menos una de nuestras tiendas debe cumplir con el monto mínimo de compra para procesar tu pedido.',
        'minimum-amount__this-store': 'Esta tienda tiene un monto mínimo de compra de',
        'minimum-amount__add': 'Agrega',
        'minimum-amount__to-process': 'para procesar tu pedido.',
        'surplus__your-shopper': 'Tu shopper ha solicitado el pago de la diferencia de',
        'surplus__order-of': 'del pedido',
        'surplus__to-charge-from': 'a cobrar por medio de',
        'institutional__not-meet':
            'Su pago institucional es ilimitado. Por lo tanto, no es necesario utilizar su saldo de Tipti Card. Su pedido se pagará en su totalidad con la institución.',
        'institutional__not-enough': 'No tienes saldo disponible con este método de pago',
        'institutional__this-institution': 'Esta institución no está disponible. Seleccione otro método de pago.',
        'resume__elder-terms-and-conditions':
            'Al seleccionar la opción "Este pedido es para adulto mayor" el usuario estará de acuerdo con las siguientes políticas: Para cambios o dudas sobre los productos que el usuario compró, el Tipti Shopper se comunicará con la persona que realizó el pedido en primera instancia.',
        'resume__elder-information':
            'Activa esta opción si tu pedido es para un adulto mayor (mayor a 65 años). De esta manera lo podremos registrar y tu pedido tendrá una atención preferencial.',
        'wire-transfer__select-your-bank': 'Selecciona el banco para transferencia',
        'cash__only-equal': 'La cantidad debe ser igual o mayor a {currency} {amount}',
        'cash__not-allowed': 'Pago en Efectivo no disponible',
        'cash__over-limit': 'Tu pedido supero el monto máximo para pago en efectivo',
        'credit-card__not-allowed': 'Pago con Tarjetas no disponible',
        'credit-card-deferred__not-allowed': 'Pago diferido con tarjetas no disponible',
        'credit-card__under-limit': 'Para realizar un pago con tarjeta el monto mínimo es de {currency} 1.00',
        'credit-card-deferred__under-limit':
            'Para realizar un pago diferido con tarjeta el monto mínimo es de {currency} {amount}',
        'credit-card__over-limit': 'El monto máximo de pago con tarjeta es de {currency} {amount}',
        'payphone__not-allowed': 'PayPhone no disponible',
        'payphone__under-limit': 'Para realizar un pago a través de PayPhone el monto mínimo es de {currency} 1.00',
        'payphone__over-limit': 'El monto máximo de pago con Payphone es de {currency} {amount}',
        'institutional__not-allowed': 'Pago institucional no disponible',
        'institutional__not-available': 'No tienes ninguna institución con cupo disponible en este momento',
        'online-bank-sevices__avalible-platform': 'Plataformas habilitadas',
        'online-bank-sevices__proccess-pyment-advisor':
            'Una vez ingresado tu pedido deberás procesar el pago en el portal de {bank_name} utilizando el número de pedido.',
        'online-bank-sevices__modify-schedule-msj':
            'Cuando se verifique el pago se ajustará la hora de entrega de ser necesario',
        'online-bank-services__not-allowed': 'Pago por servicios en línea no disponible',
        'online-bank-services__not-available':
            'Actualmente el método de pago mediante servicios en línea no se encuentra disponible para pagos mixtos con instituciones.',
        'online-bank-services__not-loading':
            'Oops, ocurrio un error al cargar las entidades financieras disponibles. Por favor, vuelve a intentarlo o intenta con otro método de pago.',
        'online-bank-services__reloading': 'Recargar',
        online_bank__service_payments: 'Pago de servicios',
        online_bank__steps: 'Para realizar pagos de servicios en línea debes realizar los siguientes pasos:',
        'nequi__not-available-title': 'Pago con Nequi no disponible',
        'nequi__not-available-message': 'El pago mixto entre Institucional y Nequi no se encuentra disponible.',
        'nequi__over-limit': 'El pago máximo con Nequi es de {currency} {amount}',
        'nequi__under-limit': 'Para realizar un pago con Nequi el monto mínimo es de {currency} 1.00',
        default_payment: 'Pago predeterminado',
        'snackbar__product-added': '{product} ha sido agregado al carrito correctamente',
        'codes__no-nodes-yet': 'Todavía no tienes códigos. Canjea un cupón para poder visualizarlo',
        'transfer__once-your-order':
            'Una vez ingresado tu pedido validaremos la transferencia. Una vez validada se ajustará el horario de entrega en caso de ser necesario.',

        'purchase__your-order-delivered':
            'Tu pedido de {retailer} será entregado {date} entre las {firstHour} y {secondHour}',
        'purchase__your-order-rejected':
            'El pago de la orden en {retailer} fue rechazado, Cambia tu método de pago e intenta pagar de nuevo.',
        'purchase__total-saved-had': 'has',
        'purchase__total-saved-saved': 'AHORRADO',
        'purchase__retailer-schedule-to': 'a',
        'purchase__order-accepted': 'Pedido aceptado',
        'purchase__payment-rejected': 'Pago rechazado',
        'resume__instructions-delivery': 'Instrucciones de Entrega',
        'resume__contact-delivery': 'Agrega instrucciones y un contacto adicional para la entrega.',
        resume__delivery_title: 'Ingresa instrucciones de entrega para contarnos lo que necesites aclarar.',
        resume__delivery_contact_additional: 'Contacto adicional para entrega:',
        resume__delivery_description: 'Añade un contacto de respaldo.',
        resume__delivery_placeholder: 'Dejar las compras en la garita del guardia...',
        'resume__are-you-elder-people': '¿Tu pedido es para Adulto Mayor?',
        'resume__automatic-replacements-requested': '¿Reemplazos automáticos?',
        'resume__alert-replacements-title': 'Reemplazos automáticos',
        'resume__alert-replacements-description':
            'Tu shopper reemplazará automáticamente los productos fuera de stock, en caso de haber un excedente, te enviará una solicitud para que la apruebes',
        'resume__alert-replacements-information':
            'Activa esta opción para aceptar automáticamente los reemplazos que nuestros shoppers gastrónomos realizarán en caso de que no encuentren alguno de los productos que solicitaste.',
        'resume__permissions-content':
            'Para mejorar tu experiencia de compra en {retailerName}, recuerda que con tu autorización, podemos ingresar tu cédula al realizar la compra',
        'checkout__complete-purchase': 'Finalizar compra',
        'note__edit-note': 'Editar nota',
        'note__save-note': 'Guardar nota',
        'note__add-photo-as-note':
            'Para adjuntar una imagen en la Nota al Shopper la debes describir con al menos una palabra',
        'note__instructions-shopper': 'Deja instrucciones a tu shopper para que te lleve el producto como lo necesitas',
        my_order__need_help_with: 'Hola, necesito ayuda con mi pedido ({orderId})',
        my_order__what_do_you: '¿Qué quieres hacer con tu pedido?',
        'my-order__i-want-to': 'Quiero hacer un pedido',
        'my-order__no-orders':
            'No tienes pedidos activos al momento, puedes revisar tu hisorial de pedidos al lado izquierdo o comenzar un pedido nuevo.',
        'my-order__forgot-something': '¿Te olvidaste algo en tu pedido?',
        'my-order__shopper-is':
            'Tu shopper es un experto en la selección de alimentos que escogerá para ti los mejores productos',
        'my-order__in-destiny': 'Shopper en el punto de entrega',
        'my-order__shopper-in-destiny': 'Tu delivery shopper se encuentra en el punto de entrega',
        'my-order__wire-transfer': 'Validando transferencia',
        'my-order__validating-wire-transfer':
            'Estamos validando la transferencia del pago de tu pedido, esto puede tardar unos minutos. Una vez validado, se ajustará el horario de entrega de ser necesario',
        'my-order__waiting-replacement': 'En espera de confirmación para reemplazos',
        'my-order__waiting-confirmation': 'Tu shopper te recomendará reemplazos para tus productos fuera de stock.',
        'my-order__waiting-shooper': 'Pedido por recoger',
        'my-order__your-order-waiting':
            'Tu pedido está listo, pronto un delivery shopper lo retirará para llevarlo en perfecto estado.',
        'my-order__forget-to-comment':
            'Tu pedido ha sido entregado, no te olvides de calificar tu experiencia y contarnos como estuvo tu pedido',
        'my-order__delivered-and-rated': 'Orden entregada y calificada',
        'my-order__thanks-for-use': 'Gracias por confiar en Tipti, estamos siempre a las órdenes.',
        'my-order__you-cancelled':
            'Has cancelado la orden, si hubo un error contacta a nuestro Care Team y te ayudaremos lo más pronto posible.',
        'my-order__order-delivered': 'Orden entregada',
        'my-order__delivery-will-hand': 'Tu repartidor te llevará tu pedido en perfecto estado',
        'my-order__shopper-delivering': 'Shopper en camino',
        'my-order__your-shopper-will-arrive': 'Tu shopper pronto llegará con tu pedido.',
        'my-order__purchase-finished': 'Compra terminada en la tienda',
        'my-order__your-shopper-finish': 'Tu shopper ha terminado de comprar todos los artículos de tu pedido.',
        'my-order__shopper-buying': 'Asesor comprando',
        'my-order__your-shopper-picking':
            'Tu shopper está eligiendo productos frescos y de calidad, tal como tu lo harías.',
        'my-order__groceries-advisor-assigned': 'Asesor de compras asignado',
        'my-order__in-a-moment':
            'En un momento un shopper empezará a preparar tu pedido y seleccionar los mejores productos para ti.',
        'my-order__order-accepted': 'Pedido aceptado',
        'my-order__thank-for-trust':
            'Gracias por confiar en Tipti, pronto un shopper preparará tu pedido, seleccionando los mejores productos para ti.',
        'my-order__payment-rejected': 'Pago rechazado',
        'my-order__payment-rejected-please':
            'El pago de tu pedido ha sido rechazado, por favor comunícate con nuestro Care Team o reintenta con otra forma de pago.',
        'my-order__payment-in-progress': 'Pago en proceso',
        'my-order__we-are-processing':
            'Estamos procesando el pago de tu pedido, esto puede tardar unos minutos. Gracias por confiar en Tipti.',
        'my-order__extra-items-payment': 'Pago de extra items en proceso',
        'my-order__we-are-processing-extra':
            'We are processing the payment for your added products, this may take a few minutes. Thank you for trusting Tipti.',
        'my-order__initial-cart': 'Carrito inicial',
        'my-order__final-cart': 'Carrito final',
        'my-order__products-bought': 'Productos comprados',
        'my-order__products-not-bought': 'Productos no comprados',
        'my-order__products-replaced': 'Productos reemplazados',
        'my-order__tipti-items': 'Tipti items',
        'my-orders__no-orders-yet': 'Todavía no tienes pedidos',
        'my-orders__it-looks-like':
            'Al parecer todavía no tienes pedidos realizados. haz un pedido para que se vayan registrando en tu historial.',
        'nutritional-information': 'Información nutricional',
        'schedules__this-retailer-dont': 'Esta tienda no tiene horario disponible para tu sector de entrega',
        'credit-card__dont-have':
            'Al parecer no tienes tarjetas de crédito o débito registradas, añade una nueva tarjeta para continuar.',
        'credit-card__as-on-card': 'Nombre y apellido',
        'credit-card__owner': 'Nombre del propietario de la tarjeta',
        'credit-card__alias': 'Alias',
        'credit-card__code': 'cvv',
        'credit-card__alias_placeholder': 'Nombre para identificar tu tarjeta',
        'credit-card__phone-number': 'Número de celular del titular',
        'credit-card__card-number': 'Número de tarjeta',
        'credit-card__expired-date': 'Fecha de caducidad',
        'credit-card__add-card': 'Añadir Tarjeta',
        'credit-card__once-you-pay':
            'Una vez que se ingrese tu tarjeta se realizará un cobro para su validación de no más de {amount} que se reembolsará de forma automática una vez que tu tarjeta haya sido válida.',
        'credit-card__accepted-cards-to':
            'Para realizar el pago con tus tarjetas, por favor asegúrate de tenerlas habilitadas en tu banco para compras por internet. Aceptamos tarjetas {cards} de todos los bancos emisores (Tarjetas de débito aceptadas según los permisos de tu banco)',
        'credit-card__new-card': 'Nueva tarjeta',
        'credit-card__cards-choosed': 'Selecciona tu tarjeta',
        'credit-card__accepted-cards': 'Tarjetas aceptadas',
        'bills__choose-your-bills': 'Selecciona tus datos de facturación',
        'bills__billing-as-company': 'Facturar como empresa',
        'product-modal__how-would-you': '¿Cómo quieres comprar tu producto?',
        product_modal__buying_legend: 'El producto se agregará por {type} en tu carrito.',
        'product-modal__select-a-suggestion':
            'Selecciona una sugerencia de reemplazo para tu Asesor de compras en caso de que el producto que estas comprando se encuentre fuera de stock en el establecimiento',
        'shared-cart__cart-name': 'Nombre del carrito',
        'shared-cart__create-cart': 'Crear carrito',
        'shared-cart__delete-cart': 'Borrar carrito',
        'shared-cart__name-and-id': '* Nombre de carrito y por lo menos un ID de usuario es requirido',
        'shared-cart__add-a-friend': 'Añade a un amigo a tu carrito',
        'shared-cart__add-its-id': 'Ingresa el ID del usuario Tipti que deseas añadir',
        'shared-cart__only-numbers': '* El ID de usuario debe contener solo números',
        'shared-cart__participants': 'Participantes del carrito',
        'shared-cart__id-of-user': 'ID de usuario',
        'shared-cart__new-cart': 'Nuevo carro compartido',
        'billings__no-content-title': 'No registra datos de facturación',
        'billings__no-content-description': 'Parece que aún no registras datos de facturación',
        'banners__no-content-title': 'Promoción sin productos',
        'banners__no-content-description': 'Al parecer no hay productos disponibles para esta promoción',
        'cart__your-cart-empty': 'No tienes productos en tu carrito',
        'cart__try-adding-some': 'Al parecer no tienes productos en tu carrito, intenta agregar algunos productos',
        'cart__subtotal-fee': 'SUBTOTAL TARÍFA',
        cart__subtotal: 'Subtotal',
        'cart__sales-tax': 'IVA',
        'cart__commission-for-service': 'Revisar detalles de comisión de servicios ',
        'cart__service-fee-description':
            'El valor de comisión de servicio ya está incluido dentro del valor de los productos y subtotal, aquí podrás ver el desglose a detalle.',
        'cart__service-fee': 'Comisión de servicio',
        'cart__hide-details': 'contraer detalles',
        'cart__discount-applied': 'DESCUENTOS APLICADOS',
        'cart__savings-total': 'Ahorro total',
        'cart__shipping-cost': 'Costo del envío',
        'cart__pickup-cost': 'Costo del Pickup',
        'cart__tax-included': 'incluído impuestos',
        'cart__complete-your-register-title': 'Completa tu registro',
        'cart__complete-your-register-alert': '!Gracias por tu registro!',
        'cart__complete-your-register-description':
            'Por favor ayúdanos a completar tu perfil para una atención personalizada',
        'cart__our-shopper-will-assist': 'y que nuestro shopper pueda asesorarte paso a paso en tu pedido',
        'cart__list-added-successfully': 'Producto(s) añadido(s) a las listas seleccionadas',
        product_list__added: 'Producto añadido a la lista: "{name}"',
        'cart__are-you-sure-empty': '¿Estás seguro que quieres vaciar tu carrito de compras?',
        'cart__remember-that':
            'Recuerda que el valor del envío se actualizará en tu cuenta luego de que confirmes el lugar y horario de entrega, el descuento se aplicará según el día de entrega',
        'cart__total-order': 'Total orden',
        'cart__no-cambrellas-title': 'Sin fundas',
        'cart__no-cambrellas-description': 'Por el momento no hay fundas disponibles',
        'dropdown__select-your-city': 'Selecciona tu ciudad',
        'product-no-found__we-will-find': '¡Tenemos más de 200 mil productos disponibles!',
        'product-no-found__select': 'Seguro encontramos algo similar a lo que estás buscando.',
        'product-no-found__report-a-product': 'REPORTA EL PRODUCTO QUE BUSCABAS',
        'become-retailer__what-does-tipti': '¿En qué aporta tipti',
        'become-retailer__with-your-bussiness': 'a tu negocio?',
        'become-retailer__increase-customer-base': 'Incrementa base de clientes',
        'become-retailer__home-penetration': 'Penetracion en el mercado de hogares',
        'become-retailer__increase-purchase-loyalty': 'Incremento fidelidad de compra',
        'become-retailer__increase-purchase-frequency': 'Incremento frecuencia de compra',
        'become-retailer__average-ticket-increase': 'Incremento de ticket promedio',
        'become-retailer__average-ticket-profitability': 'Rentabilización de ticket promedio',
        'become-retailer__brand-adoption': 'Adopción de Marca/Portafolio',
        'become-retailer__brand-preference': 'Preferencia de Marca/Portafolio',
        'become-retailer__brand-benefits': 'Eduación Beneficios de Marca/Portafolio',
        'become-retailer__approval-and-contribution': 'Aprobación y Aporte de Líderes de Opinión',
        'become-retailer__effective-communication': 'Alcance de comunicación efectiva',
        'landing-tipti__select-your-country': 'Seleccione su país para continuar',
        'how-works': '¿Cómo funciona?',
        'how-works__order-online': 'Pedido en línea',
        'how-works__our-platform':
            'Nuestra plataforma digital te permite ordenar desde tu dispositivo móvil la lista de compras de tus víveres y artículos para el hogar.',
        'how-works__shopper-advisors': 'Asistentes de supermercado',
        'how-works__we-take-your':
            'Recibimos los pedidos y los envíamos a nuestra red de shoppers quienes seleccionan y compran los productos de tu pedido para después entregarlos en tu ubicación.',
        'how-works__women-and-men':
            'Mujeres y hombres expertos en selección de productos, entrenados y preparados para brindar el mejor servicio cumpliendo con los más altos estándares de calidad. Se convertirán en tus mejores aliados.',
        'how-works__become-shopper': 'Conviértete en shopper',
        'how-works__become-retailer': 'Conviértete en retailer',
        'how-works__work-with-us': 'Trabaja con nosotros',
        'slogan__online-and': 'TIENDA Y ',
        'slogan__supermarket-and': 'SUPERMERCADO',
        'slogan__home-essenials': 'A DOMICILIO',
        'footer-main__follow-us': 'Síguenos en redes sociales',
        'header-guess__already-has-account': '¿Ya tienes una cuenta?',
        'search__results-for': 'Resultados para',
        'search__products-find-in': 'Productos encontrados en',
        'notifications__tipti-news': 'Tipti Noticias',
        'notifications__have-pending-payments': '¡Tienes un pago pendiente!',
        'notifications__change-method': 'Cambiar método de pago',
        'notifications__no-content-title': 'No tienes pagos pendientes',
        'notifications__no-content-description': 'Al parecer no tienes notificaciones de pago pendiente',
        'notifications__pending-processed-success': 'Tu pago pendiente fue procesado exitosamente',
        'notifications__pending-processed-failure': 'Hubo un error al procesar tu pago pendiente',
        'referred__share-code': 'Compartir código',
        'referred__win-money': 'Gana',
        'referred__every-referred': 'con cada referido',
        'referred__send-this-code':
            'Envía este código a tus amigos o haz que lo escaneen y gana {currency} {amountGift} por cada uno una vez que haya realizado su primera compra mayor a {currency} {amountMin}',
        'referred__get-in': 'Obtén',
        'referred__with-every-friend': 'con cada amigo que invites a probar el servicio de asistencia de',
        'referred__pending-to-claim': 'Pendiente',
        'about-us__we-are': 'Somos ',
        'about-us__is-an-ecomerce':
            'una empresa de e-Commerce orientada a la venta de productos de supermercado y tiendas especializadas a través de medios digitales (App o web) y con entrega a domicilio. Nuestro propósito es conectar a un equipo de compradores especializados y a los usuarios/consumidores que buscan cubrir las necesidades para su hogar.',
        'about-us__in': 'En ',
        'about-us__is-providing-best':
            ' nos encargamos de que las personas tengan más tiempo para sus familias y para si mismas, mientras garantizamos que sus compras llegarán a casa de forma segura.',
        'about-us__time-for-you': 'TIEMPO PARA TI',
        'address__select-right-location': 'Selecciona una dirección válida en el mapa',
        'address-user__delivery-sector': 'Dirección de Entrega',
        'address-user__saved-addresses': 'Direcciones guardadas',
        'address-user__review-address': 'Revisa tus direcciones de entrega',
        'address-user__reference': 'Referencia',
        'address-user__alias': 'Alias dirección',
        'address-user__select-delivery-sector': 'Selecciona tu sector de entrega',
        'address-user__most-nearest': 'el más cercano al mismo',
        'address__new-address': 'Nueva dirección',
        'address-modal__change-address': 'Cambiar dirección',
        'coverage-sector__coverage-sector': 'SECTORES DE COBERTURA',
        'coverage-sector__verify-sector': 'VERIFICA TU SECTOR',
        'coverage-sector__or-select-location': 'O selecciona tu ubicación en el mapa',
        'edit-profile__edit-my-profile': 'Editar mi perfil',
        'edit-profile__to-change-email': 'Para cambiar el email debes ayudarnos con tu contraseña',
        'edit-profile__confirm-new-password': 'Confirma tu nueva contraseña',
        'edit-profile__enter-new-password': 'Ingresa tu nueva contraseña',
        'edit-profile__enter-old-password': 'Ingresa tu antigua contraseña',
        'edit-profile__enter-password': 'Ingrese su contraseña para continuar!',
        'edit-profile__enter-your-new': 'Ingresa tu nueva ',
        'edit-profile__enter-all-data': 'Por favor ingresa todos los campos para continuar',
        'edit-profile__remember-that-need': 'recuerda que debe',
        'edit-profile__between-eight-sixteen': 'tener entre 8 y 16 dígitos alfanuméricos',
        'edit-profile__password-success-change': 'Su contraseña ha sido actualizada exitosamente!',
        'edit-profile__password-error-change': 'Ocurrio un error al actualizar su contraseña intente de nuevo..',
        'edit-profile__password-no-match': 'Las contraseñas no coinciden, intente de nuevo..',
        'how-it-works__online-order': 'Pedido en línea',
        'how-it-works__our-digital-platform':
            'Nuestra plataforma digital te permite ordenar desde tu dispositivo móvil u ordenador la lista de compras de tus víveres y artículos para el hogar.',
        'how-it-works__supermarket-assistants': 'Asistentes de supermercado',
        'how-it-works__get-orders':
            'Recibimos los pedidos y los enviamos a nuestra red de shoppers, quienes seleccionan y compran los productos de tu pedido para entregarlos en la ubicación, día y hora seleccionada.',
        'how-it-works__experts-men-women':
            'Mujeres y hombres expertos en la selección de compras. Agradables en el trato y profesionales comprando. Se convertirán en tus mejores aliados.',
        'how-it-works__establishment': 'Establecimiento',
        'how-it-works__work-with-establishment':
            'Trabajamos con los mismos establecimientos en los que el consumidor hace su compra. El modelo de compra nos permite adquirir productos especializados, frescos, ecológicos o de tu elección y preferencia.',
        'login__forget-your-password': '¿Olvidaste tu contraseña?',
        'login__still-not-account': '¿Aún no tienes cuenta?',
        'login__user-password-incorrect': '*Usuario o contraseña incorrectos',
        'login__enter-your-email': '*No te olvides de ingresar tu correo',
        'login__enter-you-password': '*No te olvides de ingresar tu contraseña',
        'login-attempt__account-deactivated': '*Tu cuenta ha sido desactivada',
        'login__try-login': 'tratar de iniciar sesión',
        'login__account-deactivated':
            'Estás intentando ingresar con un correo electrónico de una cuenta desactivada, por favor ingresa uno nuevo e inténtalo de nuevo',
        'login__alert-by-the-present': 'Por medio de la presente acepto los ',
        'login__alert-authorize-tipti': ' y autorizo a ',
        'login__alert-to-use-my-data':
            ' a almacenar, procesar, y/o utilizar mis datos personales, por tiempo indefinido, para que me envíen información acerca de todo tipo de ofertas, promociones, aperturas de nuevos locales, datos sobre la compañía y, en general, cualquier noticia que ',
        'login__alert-may-consider': ' pueda considerar de interés.',
        'login__accept-terms-conditions': 'Acepto los términos y condiciones',
        'register__register-register': 'Regístrate',
        'register__do-not-forget': '*No te olvides de ingresar tu nombre',
        'register__enter-name-lastname': '*Ingresa nombre y apellido',
        'register__already-have-account': '¿Ya tienes una cuenta?',
        'register__try-create-account': 'tratar de crear cuenta',
        'news__we-are-building':
            'Trabajamos para extender nuestro servicio y que más personas puedan comprar de forma segura y digital sus alimentos. Todos los días, nos preparamos para servir mejor y crear una grata de experiencia para nuestros clientes. En esta sección podrás encontrar nuestros logotipos con sus diferentes aplicaciones, todos descargables. Además están disponibles nuestras más relevantes noticias en medios de comunicación y redes sociales.',
        'news__linkedin-news': 'Noticias en LinkedIn',
        'news__tag-our-deliveries': 'Taggea nuestras entregas, siempre reposteamos a nuestros clientes felices!',
        'news__know-about-our-news': 'Entérate de nuestras noticias más relevantes y logros en nuestra red de LinkedIn',
        'news__new-isotipe': 'Isotipo',
        'news__download-png': 'Descargar PNG',
        'news__new-note': 'Nota',
        'news__all-the-zip':
            'Todos los ZIP contienen versiones en modo CMYK y RGB alineados a los pantones, ambos adaptados a fondos claros y oscuros.',

        'payment-methods__digital-alternative':
            'La alternativa digital a tu efectivo. Recarga tu Tipti Card y utilízala como método de pago.',
        'payment-methods__pay-your-orders':
            'Paga tus compras de hasta {currency} {maxAmount} con tus tarjetas registradas en Tipti.',
        'payment-methods__payment-method-valid-prime': 'Método de pago válido para cuentas institucionales.',
        'payment-methods__download-payphone-app':
            'Descarga Payphone App e ingresa tus tarjetas y selecciónalo como método de pago en Tipti.',
        'payment-methods-cash-description':
            'Puedes pagar en efectivo tus compras de un monto máximo de {currency} {cashAmount}.',
        'payment-methods__cash-payment': 'Pago efectivo',
        'payment-methods__option-cash-payment':
            'Opción para pago en efectivo on line de cualquier valor, únicamente habilitado para web.',
        'payment-methods__pending': 'Aún existe un pago pendiente, por favor escoja otro método de pago',
        'mixed-payment-messages-not-deferred__title': 'Al seleccionar dos métodos de pago no podrás diferir',
        'mixed-payment-messages-not__deferred':
            'Recuerda que al seleccionar dos métodos de pago no podrás diferir tu compra.',
        'payment-methods__change-payment': 'Cambio de método de pago',
        'payment-methods__change-payment-description':
            'Estás seguro de cambiar todos los métodos de pago seleccionados',
        'profile__get-the-user': 'obtener los datos del usuario',
        profile_shared_data__share_id_with: 'Compartir documento de identificación con',
        profile_shared_data__no_content: 'Actualmente no compartes tus datos con ningún retail',
        'profile-shared-data__alert':
            'Autoriza a nuestros shoppers a ingresar tu número de documento de identificación en el retail al momento del pago en la tienda, para que ellos puedan enviarte ofertas personalizadas y mejorar tu experiencia en compra',
        profile_shared_data__affiliate_modal_title: 'Programas de afiliados',
        profile_shared_data__affiliate_modal_paragraph_1:
            'Autoriza a los Tipti shoppers a ingresar tu número de identificación en el retail al momento del pago en la tienda para que el supermercado pueda enviarte ofertas personalizadas y mejorar tu experiencia en compra.',
        profile_shared_data__affiliate_modal_paragraph_2:
            'Para quienes no estén afiliados, al aceptar los documentos (T&C, Convenio de Afiliación, Reglamento de Afiliación, Políticas de Privacidad) estarás afiliado a Supermaxi y Megamaxi.',
        'profile-options__orders': 'ORDENES',
        'profile-options__edit-profile': 'Editar mi perfil',
        'profile-options__delivery-address': 'Dirección de entrega',
        'profile-options__card-register': 'Tarjetas registradas',
        'profile-options__share-data': 'Programas de afiliados',
        'profile-options__share-data-activated': 'Programa de afiliado activado',
        'profile-options__updated-success': 'Los datos de tu perfil han sido actualizados con éxito!',
        'profile-bills__no-content': 'Actualmente no existe ninguna factura disponible',
        'product-modal__complete-your-order': 'Completa tu pedido',
        'product-modal__replace-sugguest': 'Sugerencia de reemplazo',
        'product-modal__send-note': 'Envia una nota al shopper',
        'product-modal__how-you-buy': '¿CÓMO QUIERES COMPRAR TUS PRODUCTOS HOY?',
        'product-modal__please-select-product': 'Por favor, selecciona un producto para continuar',
        'product-modal__name-of-list': 'Ej: Nombre de la lista',
        'product-modal__txt-action-note': 'Nota al Shopper',
        'product-modal__txt-action-note-short': 'Nota',
        new_list: 'Nueva lista',
        'product-modal__txt-add-list': 'Añadir a una Lista',
        'product-modal__txt-add-list-short': 'Listas',
        'product-modal__txt-add-favorite': 'Agregar a Favorito',
        'product-modal__txt-add-favorite-short': 'Favorito',
        'retailer-promotions__delivery': 'Horarios',
        'retailers__no-retailer-filtered': 'No existen tiendas con esa categoria, prueba con otra.',
        retailers__no_retailers:
            'Lo sentimos, pronto tendremos tiendas disponibles para ti en este sector. Por favor, ingresa otra dirección dentro de nuestras zonas de cobertura.',
        'retailers__popular-stores': 'Tiendas destacadas',
        'retailers__popular-view-all': 'Ver todas',
        'retailer__next-delivery': 'Entrega',
        'retailers__orders-express': 'Pedidos express',
        'retailers__reception-same-day': 'Recepción de pedidos el mismo día',
        'retailers__other-stores': 'Otras tiendas',
        'retailers__delivery-schedule-can-change':
            'Los horarios pueden variar según fechas especiales o acontecimientos que dificulten la logística en la entrega de los pedidos.',
        'retailers__repeat-last-order': 'Repetir última orden',
        'setting__settings-language': 'Idioma',
        'setting__want-receive-notifications':
            '¿Deseas recibir notificaciones acerca de nuestras promociones vigentes?',
        'setting__want-receive-mails': '¿Desea recibir mails acerca de nuestras promociones vigentes?',
        'setting__update-language-success': 'El idioma ha sido actualizado, con éxito!',
        'setting__update-language': 'actualizar el lenguaje',
        'setting__want-close-session': '¿DESEAS CERRAR SESIÓN?',
        'setting__want-delete-account': '¿DESEAS ELIMINAR TU CUENTA?',
        'setting__we-are-sorry':
            'Lamentamos que nos dejes. Recuerda que al eliminar tu cuenta todos los datos guardados en Tipti se perderán y no se podrán recuperar.',
        setting__title: 'Configuración',
        setting__language_message: 'Puedes escoger el lenguaje principal de la App',
        setting__language_change: 'Cambiar lenguaje',
        setting__terms_conditions: 'Términos y condiciones',
        'subcategories__sub-categories': 'Subcategorías',
        subcategories__features_products: 'Productos destacados',
        subcategories__all_products: 'Todos los productos',
        subcategories__no_content: 'Ha ocurrido un problema, por favor inténtalo de nuevo',
        subcategories__reload_page: 'Recargar página',
        'filter-options__bigger-price': 'Mayor precio',
        'filter-options__lower-price': 'Menor precio',
        'filter-options__lower-than': 'Inferior a',
        'filter-options__higher-than': 'Superior a',
        'search-autocomplete__results-for': 'Resultados de búsqueda para:',
        'list__list-name': 'Nombre de la lista',
        'list__delete-card': 'No se pudo eliminar la tarjeta, vuelve a intentarlo',
        'list__delete-card-success': 'La tarjeta fue eliminada con éxito',
        'list__add-list': 'Añadir lista',
        'list__list-schedule': 'Programar',
        'list__edit-schedule': 'Editar Programación',
        'list__list-saved': 'Listas Guardadas',
        'list__list-empty': 'Lista vacía',
        'list__items-quantity': 'Lista vacía | un producto | {count} productos',
        'list__list-shopping': 'Listas de compras',
        'list__list-empty-products-msg': 'Al parecer no tienes productos en esta lista',
        'list__create-list-success': 'Tu lista ha sido creada exitosamente',
        'list__create-list-failed': 'No se pudo crear tu lista',
        'list__cancel-list-failed': 'No se pudo cancelar tu lista',
        'list__cancel-list-success': 'Tu lista ha sido cancelada con éxito',
        'list__delete-list-success': 'Tu lista ha sido eliminada exitosamente',
        'list__add-list-success': 'Se ha añadido lista al carrito',
        'list__delete-list-failed': 'No se pudo eliminar tu lista',
        'list__no-name': '*No te olvides de ingresar el nombre de la lista',
        'list__products-added': 'Tus productos han sido agregados exitosamente a tu carrito',
        'list__some-products-added': 'Ciertos productos han sido agregados a tu carrito',
        'list__list-schedule-subtitle':
            'Elige el día y la frecuencia con la que deseas que tu lista de productos se añada al carrito de forma  automática.',
        'list__list-schedule-edit-day': 'EDITA TU DÍA DE ENTREGA',
        'list__list-schedule-edit-frequency': 'FRECUENCIA DEL PEDIDO',
        'lists__freq-weekly': 'Cada semana',
        'lists__freq-peer-week': 'Cada  {count}   semanas',
        'lists__freq-monthly': 'Cada mes',
        'lists__shared-list': 'Lista compartida',
        'lists__add-list-success': 'Tus productos han sido agregados a tus listas',
        'lists__add-list-failure': 'Tus productos no han sido agregados a tus listas',
        'list__not-add-to-card': 'No se pudo agregar a tu carrito',
        'my-orders__current-orders': 'Pedido Actual',
        'my-orders__orders-history': 'Historial de pedidos',
        'my-orders__contact-with-us': 'Contáctate con nuestro equipo Tipti Care Team',
        'my-orders__filter-orders': 'Filtrar órdenes',
        'summary-orders__delivery-hour': 'HORARIO DE ENTREGA',
        'summary-orders__delivery-place': 'LUGAR DE ENTREGA',
        'summary-orders__delivery': 'Entrega',
        'summary-orders__information-delivery-payment': 'Info de envio y pago',
        'summary-orders__get-history-order': 'cargar historial de órdenes',
        'summary-orders__get-active-orders': 'cargar órdenes activas',
        'summary-orders__filter-orders': 'filtrar órdenes',
        'summary-orders__filter-option': 'Opciones de filtro',
        'summary-orders__filter-by-date': 'filtrar por la fecha provista',
        'main-products__discount-products': 'Productos en oferta',
        'main-products__your-products': 'Tus Productos',
        'main-products__recommended_for_you': 'Recomendados para ti',
        'main-products__predictions': '{user} ya es hora de comprar',
        'main-products__new-products': 'Productos nuevos',
        'form-send-success': '¡Formulario enviado exitosamente!',
        'form-send-failed': 'Error al enviar formulario',
        'prime__accept-terms': 'Al activar aceptar los ',
        'prime__accept-terms-final': 'de TIPTI PRIME, puedes cancelar tu suscripción en cualquier momento',
        'prime__plan-header': 'El plan perfecto para ahorrar',
        'prime__plan-subheader':
            'Con Tipti Prime ahorra en promedio {currency} {amount} al mes, puedes cancelarlo en cualquier momento',
        'prime__benefits-delivery': 'Envío ilimitado sin costo',
        'prime__benefits-sampling': 'Sampling bajo demanda',
        'prime__benefits-day': 'Prime Day (Oferta exclusiva para Prime)',
        'prime__benefits-offerts': 'Promociones especiales',
        'prime__annual-plan-payment': 'Un solo pago',
        'prime__monthly-plan-payment': 'Pago mensual',
        'prime__alert-title': 'CONVIÉRTETE EN PRIME TÉRMINOS Y CONDICIONES',
        'prime__alert-body':
            'Una vez activado el periodo de prueba de la membresía Tipti Prime, el usuario podrá disfrutarlos siguientes beneficios: envíos ilimitados gratis y shoppers selectos. La versión Tipti Prime de prueba no incluye: promociones exclusivas y Tipti Prime Day.CONSIDERACIONES:Válida 1 mes desde su activaciónUna vez culminado el mes de prueba, se realizará el respectivo cobro de Tipti Prime (plan mensual)El cobro será automáticamente vía el app a la tarjeta de crédito o débito que tienes registrada, el 31 de cada mes.En caso de que no se pueda realizar el cobro automático, la membresía será automáticamente cancelada.Participan únicamente los productos dentro',
        'prime__alert_accept-terms': 'He leido y acepto los términos y condiciones',
        'prime__alert-terms-accepted': 'Acepto los términos y condiciones',
        'prime__alert-terms': 'Términos y condiciones',
        'prime__membership-name': 'Membresia Prime',
        'prime__subscription-create': 'Tu subscripción ha sido creada con éxito',
        'prime__subscription-no-create': 'Tu subscripción no pudo ser creada',
        'tipti__card-recharge': '¿QUIERES RECARGAR TU TIPTI CARD?',
        'payphone__select-number': 'Números guardados',
        'payphone__delete-number': 'El número de teléfono fue eliminado con éxito',
        'payphone__delete-number-failed': 'El número de teléfono no pudo ser eliminado',
        recipe__share:
            '¡{user} ha compartido la receta {recipe_name} contigo!🍴👨🏻‍🍳😋 Haz click en el link para verla en Tipti 🧡',
        'recipe__ingredients-quantity': 'Sin ingredientes | ingrediente | ingredientes',
        'recipe-rate': 'Califica esta receta',
        'recipe__no-by-sector':
            'Esta receta no se encuentra disponible en tu sector. Encuentra más recetas en "Mis Listas"',
        'recipe__search-input-placeholder': 'Buscar productos',
        'help-support__faqs-description': 'Conócenos, sectores de cobertura, horarios y tiempo de entrega.',
        'help-support__prices-title': 'Precios',
        'help-support__prices-description': 'Stock y precios de productos, tarifa de entrega de pedidos.',
        'help-support__orders-title': 'Pedidos',
        'help-support__orders-description': 'Información sobre mis pedidos y formas de pago.',
        'help-support__shoppers-description': 'Nuestro equipo de trabajo encargado de tu compra.',
        'faqs__what-is-tipti': '¿Qué es Tipti?',
        'faqs__tipti-is':
            'Tipti es “Tiempo para ti”. Somos un servicio de asistencia de compras de supermercado y tiendas especializadas, en donde cada cliente puede escoger donde, cuando y a que hora desea recibir su pedido. Nos caracterizamos por contar con un grupo de Shoppers expertos que realizan las compras eligiendo la mejor calidad y frescura de los productos.',
        'faqs__how-it-works': '¿Cómo funciona Tipti?',
        'faqs__to-access-service':
            'Para tener acceso al servicio, es necesario descargar la aplicación en cualquier dispositivo móvil o acceder a www.tipti.market y crear una cuenta personal. Una vez se haya creado la cuenta colocando toda la información personal como dirección, datos de facturación y número telefónico, se debe seleccionar la tienda de preferencia para acceder a los productos que ofrece y poder colocarlos en el carrito virtual. Cuando todos los productos se hayan seleccionado se procede a finalizar la compra seleccionando el horario de entrega y el método de pago. Finalizado este proceso se asignará un Tipti Shopper ara que realice la compra y entregue el pedido según lo solicitado.',
        'faqs__what-can-buy': '¿Qué puedo comprar?',
        'faqs__you-can-buy':
            'En Tipti puedes comprar tus productos favoritos de supermercados y tiendas especializadas, para facilitar la búsqueda se encuentran clasificados por categorías. Nuestra oferta se divide en supermercado, farmacia, mascotas, delicatessen, papelería, marisquería, florería, panadería, artículos de fiesta, artículos varios y del hogar, así también en alianza con fundaciones se podrán hacer donaciones solidarias.',
        'faqs__what-delivery-schedule': '¿Cuál es el horario de entrega de pedido?',
        'faqs__delivery-schedule':
            'Los horarios de entrega serán de acuerdo al horario de atención de las tiendas afiliadas, Tipti trabaja de lunes a domingo y feriados. Puedes realizar tu pedido en cualquier momento y visualizar los horarios disponibles según cada tienda. El 25 de diciembre y 1 de enero son los únicos día en los que no se brinda atención. Los horarios de atención están sujetos a cambios de acuerdo a los parámetros de cada tienda.',
        'faqs__in-wich-cities': '¿En qué ciudades se encuentra TIPTI ?',
        'faqs__is-available-in-cities':
            'El servicio de TIPTI - Tiempo para ti se encuentra disponible en las ciudades de {availableCities}',
        'faqs__if-not-found-sector':
            'Si no encuentras tu sector, puedes registrar tu zona en nuestro sitio web <a href="https://www.tipti.market" style="color:#FF9012;text-decoration:none">www.tipti.market</a> y nosotros te confirmaremos cuando TIPTI tendrá cobertura.',
        'faqs__address-warning':
            '**ADVERTENCIA: Si la dirección de entrega no corresponde al sector seleccionado, tu pedido puede estar sujeto a cancelación y las políticas del mismo.',
        'faqs__how-fast-are-deliveries': '¿Qué tan rápidas son las entregas?',
        'faqs__type-delivery-schedule': 'Programado',
        'faqs__type-delivery-express': 'Express',
        'faqs__exist-two-deliveries': 'Existen dos tipos de entrega:',
        'faqs__delivery-schedule-description':
            'De dos a tres horas dentro del mismo día, con la posibilidad de planificar hasta una semana posterior. ',
        'faqs__delivery-express-description':
            'Una hora, el tiempo mínimo variará en relación al lugar de entrega y de compra. ',
        'faqs__which-cards-pay': '¿Con qué tarjetas puedo pagar mi pedido?',
        'faqs__before-payment':
            'Antes de realizar el pago, por favor asegúrate de que la tarjeta se encuentre habilitada en el banco para compras por internet. En Tipti aceptamos tarjetas de crédito: {cards} de cualquier banco emisor y aceptamos pagos a través de Payphone.',
        'faqs__why-maximun-amount': '¿Por qué tienen monto máximo con pago de tarjeta?',
        'faqs__subject-authorized-amounts':
            'Tipti está sujeta a los montos autorizados por los bancos afiliados, el monto máximo con tus tarjetas de crédito son {currency} 1000 {currencyName}. Para realizar un pago con tarjeta mayor a {currency} 1000,00 {currencyName}, puedes utilizar las siguientes tarjetas: {cards} de cualquier banco emisor y aceptamos pagos a través de Payphone.',
        'faqs__exist-minumun-amount-card': '¿Hay monto mínimo para realizar la compra con tarjeta?',
        'faqs__no-exist-minumun-amount':
            'En Tipti no existe cantidad mínima para comprar, realiza tu pedido y paga tu pedido con cualquiera de nuestras formas de pago disponibles dentro del app. En el caso de que tu pago sea con tarjeta el monto mínimo es de {currency} 1,00 {currencyName} para que tu transacción sea exitosa. ',
        'faqs__why-not-accept-card': '¿Por qué no aceptan mi tarjeta de crédito? ',
        'faqs__works-for-more-payments':
            'Tipti se encuentra trabajando para que en un futuro tengas más opciones de pago, contáctate con nuestro CARE TEAM al {mainPhone} o al {secondPhone} o por nuestro WHATSAPP {cellphone} o dejanos un correo a {careteam} y cuéntanos con qué tarjeta deseas realizar tu pago y nosotros nos contactaremos contigo cuando la incorporemos a nuestros métodos de pago. ',
        'faqs-prices__fresh-products-by-weight': '¿Hay productos frescos al peso?',
        'faqs-prices__can-select-fresh-products':
            'Sí, puedes seleccionar productos frescos, nuestros shoppers elegirán los mejores para ti. Además, puedes añadir una nota al pedido dentro de cada producto con detalles de cómo quieres el corte del pescado, la carne o incluso qué tan madura quieres la fruta. Puedes comprar tus productos por peso o por unidades.',
        'faqs-prices__what-do-product-no-exist': '¿Qué hago si quiero un producto que no está en Tipti?',
        'faqs-prices__our-product-list-is-based':
            'Nuestro listado de productos se basa en la disponibilidad de los comercios asociados. Dentro de nuestra app existe la opción de reporte cuando no encuentras un producto, déjanos saber qué es lo que buscas y lo reportaremos a nuestras tiendas afiliadas. Otra manera es escogiendo un producto similar en presentación y contenido, pero puedes especificar en una nota a tu shopper el producto que necesitas.',
        'faqs-prices__price-are-different': '¿Son distintos los precios de Tipti que los de las tiendas?',
        'faqs-prices__can-see-prices-in-apps':
            'Los precios que puedes visualizar en el app son los mismos precios de percha de las tiendas (PRECIO TIPTI). Puedes encontrar algunas diferencias debido a la actualización constante de precios que tienen nuestros retails asociados. Sin embargo dentro de Tipti puedes encontrar muchas promociones únicas y mejores precios que las tiendas. ',
        'faqs-orders__why-pay-before-delivery': '¿Por qué debo pagar por mi pedido antes de la entrega?',
        'faqs-orders__we-take-care':
            'En Tipti, nos preocupamos de que nuestros usuarios tengan la mejor experiencia de compra, es por ello que, el proceso de pago previo nos permite agilizar la compra y poder optar por las mejores promociones y descuentos, el dinero que se ahorre en la compra se devolverá al usuario directamente en la App.',
        'faqs-orders__how-check-order-status': '¿Cómo compruebo el estado de mi pedido?',
        'faqs-orders__all-time-show-status':
            'En todo momento la aplicación te muestra el estado de tu pedido en la pantalla y vía email. Una vez que el pedido haya sido aprobado lo podrás validar dentro de tu app como pedido aceptado. ',
        'faqs-orders__can-cancel-order': '¿Puedo cancelar un pedido? ',
        'faqs-orders__yes-although-only':
            'Sí, pero únicamente podrás hacerlo antes de que el shopper se encuentre haciendo la compra en la tienda o supermercado. Recuerda que en caso de cancelación TOTAL del pedido, el pago será reembolsado inmediatamente (validar si es inmediato) a la tarjeta con la que se realizó la compra siempre y cuando se haya cancelado el mismo día de la compra y de la transacción original. Si este no es el caso, se reversará por parte de Tipti en un máximo de 4 horas laborables y el banco lo podrá realizar dentro de los siguientes 30 días a la tarjeta directamente con un 5% de recargo por gatos bancarios. Si deseas conocer más sobre las políticas de devoluciones puedes contactarte con nuestro CARE TEAM a los números {mainPhone} o al {secondNumber} o por nuestro WHATSAPP {cellphone} o dejanos un correo a ',
        'faqs-orders__report-problem-order': '¿Cómo informo de un problema con mi pedido?',
        'faqs-orders__yes-can-contact-careteam':
            'Si tienes cualquier problema con tu pedido puedes contactarte con nuestro CARE TEAM a los números {mainPhone} o al {secondNumber} o por nuestro WHATSAPP {cellphone} o dejanos un correo a {careteam}',
        'faqs-orders__check-purchase-bill': '¿Cómo reviso el factura de la compra?',
        'faqs-orders__in-order-history':
            'En “Historial de Pedidos” puedes comprobar los pedidos realizados y los productos de cada uno de tus pedidos. Además, recibirás un email con el ticket de compra donde podrás ver de forma detallada todo lo que se te ha cobrado y cada uno de los cambios que se han realizado en tu pedido. Si deseas visualizarlo dentro del app en el Historial de tus pedidos podrá revisar tu pedido inicial y tu pedido final ya con los reemplazos y cambios. ',
        'faqs-orders__check-order-bill': '¿Cómo reviso la factura de mi pedido?',
        'faqs-orders__virtual-bill':
            'La factura electrónica de Tipti, llegará al correo registrado en los datos de facturación una vez que se haya entregado el pedido. Si no lo puedes encontrar por favor revisa en tu bandeja de Spam, si es tu primera compra debes seleccionar la opción de correo deseado para que puedas recibir de forma normal siempre a tu correo. Para evitar contratiempos te solicitamos revisar siempre que tu dirección de correo esté escrita correctamente.',
        'faqs-orders__how-use-tipticard-balance': '¿Cómo hago uso de mi saldo de TIPTI CARD?',
        'faqs-orders__choose-tipticard-pay':
            'Para que puedas hacer uso de tu saldo a favor, en la siguiente compra que realices al escoger el método de pago debes seleccionar la opción TIPTI CARD para el pago total o parcial de tu pedido. Si necesitas otro método de pago para completar el valor de la compra puedes usar PayPhone, tarjeta de crédito/débito, transferencia o efectivo hasta {currency} 50 {currencyName}. Así tendrás un pago mixto en tu pedido. Recuerda seguir estos pasos, de lo contrario tu saldo de TIPTI CARD seguirá en tu tarjeta.',
        'faqs-orders__how-return-tipi-bags': '¿Cómo devuelvo las bolsas de Tipti?',
        'faqs-orders__every-time-order-arrive':
            'Cada vez que te llegue un pedido podrás quedarte con las bolsas, pero te animamos a que se las devuelvas al Shopper en el siguiente pedido que te llegue. El medio ambiente te lo agradecerá.',
        'faqs-orders__how-order-without-plastic-bags': '¿Cómo pido mis compras en fundas no plásticas?',
        'faqs-orders__in-all-stores':
            'En todas las tiendas de la App puedes encontrar disponibles las fundas Tipti para comprarlas y que nuestros shoppers te lleven las compras en tus fundas reusables. También tenemos la suscripción de las fundas, en donde puedes comprar un número determinado de dunda y en casa pedido por un año entregaremos esas fundas en todas las compras y reusaremos las fundas. Recuerda que en cada pedido tu shopper entregará nuevas fundas y tú nos devolverás la misma cantidad.',
        'faqs-orders__how-return-product': '¿Cómo devuelvo algún producto?',
        'faqs-orders__only-accepted-return':
            'Solo se aceptará devolución de productos que lleguen en mal estado o que no lo hayas solicitado hasta dentro de 2 días después de la fecha de la entrega. Cualquier producto que fue solicitado por error no es responsabilidad de Tipti S.A. Sin embargo, si hay algún producto en tu pedido que falta o que no corresponde con lo que escogiste puedes contactarte al {mainPhone} o al {secondNumber} o por nuestro WHATSAPP {cellphone} o dejanos un correo a',
        'faqs-orders__some-products-not-delivered':
            'No me entregaron algunos productos que pedí, ¿Quién me devuelve mi dinero?',
        'faqs-orders__products-shopper-not-found':
            'Son productos que tu shopper no los encontró disponibles en tu tienda seleccionada o no se encontraban en buen estado. Todos los valores sobre estos productos se devuelven automáticamente a tu Tipti Card para que puedas usar este saldo en tus futuras compras.',
        'faqs-orders__what-should-payment-declined': '¿Qué debo hacer si mi pago es rechazado?',
        'faqs-orders__if-payment-declined':
            'Si tu pago fue rechazado, puedes cambiar la forma de pago desde tu misma cuenta para que el pago se apruebe o puedes contactarte al {mainPhone} o al {secondNumber} o por nuestro WHATSAPP {cellphone} o dejanos un correo a {careteam}',
        'faqs-orders__pay-order-many-times': '¿Realicé el pago de mi pedido varias veces?',
        'faqs-orders__if-show-double-transactions':
            'Si muestras doble transacciones en tus tarjetas por favor comunícate al {mainPhone} o al {secondNumber} o por nuestro WHATSAPP {cellphone} o dejanos un correo a {careteam}',
        'faqs-orders__how-can-regenerate-orderr': '¿Cómo puedo volver a generar mi pedido si me dio pago rechazado?',
        'faqs-orders__from-order-history':
            'Desde el historial de tus pedidos siempre puedes volver a enviar tu pedido al carrito para no tener que repetir todo tu pedido y volver a realizar el pedido con otra forma de pago. ',
        'faqs-orders__how-can-change-payment': '¿Cómo puedo cambiar de forma de pago si me dio pago rechazado?',
        'faqs-orders__inside-your-order':
            'Dentro de tu pedido en la información vas a visualizar la opción de Cambiar mi forma de Pago, ahí podrás elegir o crear una nueva forma de pago para que tu pedido ingrese de manera exitosa. ',
        'faqs-orders__can-repeat-order':
            '¿Puedo volver a repetir un pedido anterior sin realizar la búsqueda de nuevo?',
        'faqs-orders__yes-inside-app':
            'Si, dentro de tu App tienes la opción de Historial de pedidos, en donde vas a encontrar todos tus pedidos realizados por TIPTI, ingresa a tu pedido y al final de tu lista tendrás la opción de enviar mi pedido al carrito, de manera inmediata podrás visualizar el pedido en tu carrito listo para comprar o realizar modificaciones antes de terminar tu compra.',
        'faqs-orders__tipti-save-data': '¿Tipti guarda mis datos de tarjeta?',
        'faqs-orders__no-tipti-never':
            'No, Tipti jamás almacena datos de las tarjetas de los clientes, ni numeración, ni CVC, ni caducidad. Utilizamos medios de pagos electrónicos provistos por la banca privada y otros servicios internacionales de alta seguridad, evitando así almacenar este tipo de información sensible.',
        'faqs-orders__not-have-card':
            'No tengo tarjeta de crédito ¿De qué otra forma puedo pagar el servicio de Tipti?',
        'faqs-orders__cash-payments':
            'Se pueden realizar pagos con efectivo en consumos no mayores de {currency} 25.00 {currencyName}. Dentro del App tienes la opción de realizar tus pagos mediante transferencia, solo elige la cuenta que corresponde a tu banco, realiza el pago, sube la foto y te ayudaremos con tu pedido. ',
        'faqs-orders__how-can-pay-payphone': '¿Cómo puedo pagar con Payphone?',
        'faqs-orders__to-speed-up':
            'Para agilitar el proceso te recomendamos tener la aplicación de PayPhone descargada y tus tarjetas activas. Se aceptan todas las tarjetas de crédito Visa y Mastercard de cualquier banco emisor en el mundo, tarjetas de débito Produbanco',
        'faqs-orders__no-have-payphonep':
            'En caso de que aún no tengas instalada la aplicación Payphone, visíta <a href="https://livepayphone.com/" style="color:#FF9012;text-decoration:none">https://livepayphone.com/</a>',
        'faqs-orders__after-activate-cards':
            'Luego de tener activas tus tarjetas en PayPhone, ingresa a la aplicación Tipti y continúa la compra normalmente.',
        'faqs-orders__select-payphone-payment':
            'Selecciona la forma de pago Payphone, ingresa el número de teléfono registrado en tu aplicación PayPhone.',
        'faqs-orders__authorize-the-collection':
            'Autoriza el cobro por parte de Tipti y te llegará el voucher a tu teléfono. ',
        'faqs-orders__for-more-information':
            'Para más información sobre las ventajas de PayPhone puedes ingresar a  <a href="https://livepayphone.com/" style="color:#FF9012;text-decoration:none">https://livepayphone.com/</a>',
        'faqs-shoppers__who-take-my-order': '¿Quién se encargará de mi pedido?',
        'faqs-shoppers__your orders-assigned':
            'Tus pedidos se asignan de forma automática en función de una serie de factores a uno de los Tipti shoppers disponibles para esa zona y horario de entrega.',
        'faqs-shoppers__can-multiple-shoppers': '¿Puedo tener varios shoppers en mi pedido?',
        'faqs-shoppers__yes-you-can':
            'Sí, en tu pedido visualizarás a tu shopper de compras, que forma parte del grupo de compradores gourmet expertos en selección de alimentos, y posteriormente visualizarás al repartidor, quien se encargará de tomar tu pedido a tiempo y llevarlo de forma segura a la puerta de tu casa.',
        'faqs-shoppers__what-all-shoppers': '¿Qué pasa si todos nuestros Shoppers están ocupados?',
        'faqs-shoppers__automatic-check':
            'Los horarios de entrega son calculados automáticamente por nuestro sistema, asegurando la disponibilidad de las tiendas, así como de nuestros Tipti shoppers.',
        'faqs-shoppers__can-tip-shopper': '¿Puedo darle propina al shopper?',
        'faqs-shoppers__yes-can-tip':
            'Por supuesto que sí, de hecho te animamos a que lo hagas ya que el 100% de la propina es para el shopper. Puedes darle la propina en la entrega y también puedes realizarlo desde la misma aplicación, una vez que el pedido esté entregado tienes la opción de calificar a tu shopper y dejarle propina. ',
        'faqs-shoppers__what-shopper-do': '¿Qué hace un shopper?',
        'faqs-shoppers__what_permissions': '¿Por qué el aplicativo Tipti Shopper requiere  acceder a mi locación?',
        'faqs-shoppers__with_the_purpose':
            'Con el objetivo de administrar de manera más eficiente la asignación de órdenes. Permitir a Tipti usar tu localización todo el tiempo, aún cuando el app no se encuentra en uso',
        'faqs-shoppers__a-tipti-shopper':
            'Un Tipti Shopper es una persona especializada que se encargará de hacer la compra por el usuario en la tienda elegida y lo llevará a su domicilio a la hora y lugar elegida.',
        'faqs-shoppers__can-work-as': '¿Puedo trabajar como shopper?',
        'faqs-shoppers__in-tipti-always':
            'En Tipti siempre estamos buscando héroes y heroínas que apoyen a la compra y entrega de productos de supermercado!',
        'faqs-shoppers__requirements': 'Requerimientos',
        'faqs-shoppers__requirements-university': 'Universitarios o graduados',
        'faqs-shoppers__requirements-experience': 'Experiencia en retail',
        'faqs-shoppers__requirements-three': 'Experiencia en atención a cliente',
        'faqs-shoppers__requirements-with-car': 'Con y sin vehículo',
        'faqs-shoppers__even-if-we':
            'Incluso si aún no damos servicio en tu ciudad te recomendamos que rellenes la solicitud en <a href="https://www.tipti.market" style="color:#FF9012;text-decoration:none">www.tipti.market</a>',
        'faqs-shoppers__what-knowledge-should': '¿Qué conocimientos debe tener un shopper?',
        'faqs-shoppers__a-shopper-must':
            'Un shopper debe ser experto y profesional en hacer la compra, un asesor personalizado. Deben ser agradables en el trato con los clientes.',
        'faqs-shoppers__people-interested-in': 'Personas interesadas en convertirse en shopper',
        'faqs__how-can-we-help-you': '¿Cómo te podemos asistir?',
        'faqs__tipti-express': 'Tipti Express',
        'faqs__order-in-process': 'Orden en proceso',
        'faqs__make-an-order': 'Hacer un pedido',
        'faqs__my-tipti-card': 'Mi Tipti Card',
        'faqs__delivery-of-your-order': 'Entrega de tu pedido',
        'faqs__promotions-discounts-rates': 'Promociones, descuentos, tarifas',
        'faqs__profile-and-settings': 'Perfil y configuraciones',
        faqs__contact_us: 'Contáctanos',
        'faqs__your-order-is-for-elderly-adult': '¿Tu pedido es para adulto mayor?',
        faqs__problems_with_delivery: 'Problemas con la entrega',
        faqs__coverage_sectors: 'Sectores de cobertura',
        'faqs__what-is-tipti_content': 'Descubre quiénes somos y conoce sobre la mejor experiencia de compras.',
        'faqs__tipti-express_content': 'Disfruta de Caja Express y compras con tiempo de entrega ultra-rápido.',
        'faqs__order-in-process_content': 'Trackea tu orden, aprueba los reemplazos y chatea con tu shopper',
        'faqs__make-an-order_content': 'Solicita tus productos, obtén tus compras y realiza compras compartidas',
        'faqs__my-tipti-card_content': 'Descubre todos los beneficios de tu Tipti Card te ofrece',
        'faqs__delivery-of-your-order_content': 'Recibe tus compras, reporta problemas, resuleve tus dudas de cobros',
        'faqs__promotions-discounts-rates_content':
            'Contamos con tus productos a los mejores precios, tarifas de servicio, cupones y descuentos.',
        'faqs__profile-and-settings_content': 'Configura tu perfil, notificaciones y más, según tus preferencias',
        faqs__contact_us_content:
            'Comunícate con nuestro equipo de servicio al cliente Care Team, siempre dispuesto a asistirte',
        'faqs__your-order-is-for-elderly-adult_content': 'Atención especializada para el adulto mayor',
        'faqs__delivery-of-your-order_content_main':
            'Una vez que tu asesor de compras ha finalizado tu pedido, de acuerdo a nuestros estándares de calidad, tu repartidor llevará tu pedido a tu destino dentro del horario de entrega elegido. Para que puedas recibir tu pedido a la hora solicitada y sin inconvenientes, te recomendamos tener en cuenta estos aspectos:',
        'faqs__delivery-of-your-order_content_main__one':
            'Activar las notificaciones para recibir actualizaciones sobre el estado de tu pedido.',
        'faqs__delivery-of-your-order_content_main__two':
            'Al aproximarse el horario de entrega, estar pendiente de los mensajes o posibles llamadas de tu shopper.',
        'faqs__delivery-of-your-order_content_main__three':
            'Colocar instrucciones que puedan ser de ayuda para que el shopper identifique de manera rápida la dirección de entrega y pueda brindarte un servicio de entrega óptimo.',
        'faqs__delivery-of-your-subtitle__one': 'Mi repartidor no encuentre mi dirección',
        'faqs__delivery-of-your-order_content_main__four':
            'Si tu repartidor no encuentra la dirección descrita, se comunicará contigo vía llamada, chat interno o WhatsApp. En caso de no tener respuesta o no poder recibir la compra en el horario establecido, podrás contactarte con el equipo de atención al cliente Care Team para reagendar tu compra, en caso de tener  alimentos perecibles o congelados en tu pedido no asumimos la responsabilidad sobre los mismos. En caso de no poder recibir la compra definitivamente, se procederá a cancelar la orden con una penalización del valor total de la orden.',
        'faqs__delivery-of-your-subtitle__two': 'Dirección de entrega incorrecta',
        'faqs__delivery-of-your-order_content_main__five':
            'En caso de haber ingresado tu orden, pero al revisar la información te diste cuenta que no es la dirección correcta, contacta a nuestro equipo de atención al Cliente Care Team al siguiente número',
        faqs__number__USA: '1700 22 73 83',
        'faqs__delivery-of-your-order_content_main__six': 'o comunícate por Whatsapp al',
        faqs__number__EC: '+593 99 554 0223',
        'faqs__delivery-of-your-order_content_main__seven': 'para que puedan guiarte con tu cambio de dirección.',
        'faqs__delivery-of-your-order_content_main__eight':
            'Recuerda que es posible modificar tu orden, cambiar la dirección y horario de entrega o cancelar mientras que tu pedido no haya Finalizado en Tienda.',
        'faqs__what-is-tipti_content_main': 'significa más tiempo para tu familia y para ti.',
        'faqs__what-is-tipti_content_main__one':
            'Ofrecemos un servicio personalizado de asistencia de compras, garantizando que tus productos lleguen a donde tú te encuentras de forma segura. Cada uno de nuestros asesores de compra son expertos en selección de productos, entrenados y preparados para brindar el mejor servicio. Nuestros asesores cumplen con los más altos estándares de calidad, haciendo que comprar sea una experiencia mágica.',
        'faqs__what-is-tipti_content_main__two':
            'Realizar compras con Tipti es muy fácil. Primero descarga nuestra app, desde Play Store, App Store, o App Gallery, o ingresa a',
        'faqs__what-is-tipti_content_main__three':
            'y crea tu cuenta personal. Una vez que te hayas registrado, completando todos los datos solicitados, podrás elegir la tienda de tu preferencia y agregar los productos que deseas comprar a tu carrito. Para finalizar tu compra, selecciona el horario de entrega y el método de pago de tu preferencia.',
        'faqs__what-is-tipti_content_main__four':
            '¡Listo! Tu pedido será asignado a uno de nuestros asesores de compra para después ser recogido por tu repartidor y proceder con el envío de tu orden. La entrega se realizará en 1 hora, después que tu asesor de compra haya terminado tu pedido. Recuerda que puedes programar tu pedido, hasta por una semana, en el día y hora que sea de tu preferencia.',
        faqs__content: 'Contenido',
        'faqs__tipti-express_content_main':
            ' Las órdenes express son pedidos de 1 a 15 productos. Estos pedidos tienen un periodo máximo gestión y entrega de 1 hora. El costo de envío podría variar según el monto de tu compra y el sector de entrega.',
        'faqs__tipti-express_subtitle': 'Supermaxi Caja Express',
        'faqs__tipti-express_content_main__one':
            '¿Deseas recibir tus productos más rápido? La tienda Supermaxi Caja Express lleva tus pedidos súper más rápido que en las tiendas común. En esta tienda podrás finalizar un pedido de 1 a 10 productos, y tu asesor de compra gestionará tu compra rápidamente y horario de entrega en media hora. Encuentra esta tienda al seleccionar “Express” en el menú de categorías de tiendas, allí la encontrarás con el nombre “Supermaxi Caja Express”.',
        'faqs__your-order-is-for-elderly-adult_content_main':
            'TIPTI busca brindar la mejor experiencia de compra a todos sus usuarios por lo que ofrece un servicio y soporte especializado para nuestros clientes mayores a 65 años. Puedes solicitar una este servicio contactándote con nuestros agentes de adulto mayor por medio de atención al cliente Care Team quienes se encargarán de crear tu perfil, configurar tu cuenta y tomar tu pedido vía llamada telefónica o WhatsApp, y finalmente ingresarlo en la app por ti.',
        'faqs__your-order-is-for-elderly-adult_content_main__one':
            'Nuestro Care Team es un equipo comprometido y dedicado a dar soporte a todos sus clientes de manera efectiva y pronta, pero sobre todo una asistencia personalizada que mantenga cuidado a todos nuestros adultos mayores.',
        'faqs__your-order-is-for-elderly-adult_content_main__two':
            'Nuestros canales de atención están abiertos de lunes a domingo de 8:30 a 22:00',
        'faqs__your-order-is-for-elderly-adult_content_main__subtitle__one': 'Llamada:',
        'faqs__your-order-is-for-elderly-adult_content_main__three': '1700 227 383 | +593 3826 090',
        'faqs__your-order-is-for-elderly-adult_content_main__four': '+593 99-401-1368 / Opción 2',
        faqs__problems_with_delivery_content_main:
            'Si recibiste tu orden y no estás completamente satisfecho con los productos o el servicio recibido. No te preocupes, nuestro equipo de Care Team está disponible para ti todos los días de 8:30 a 22:00 para ayudarte con cualquier inconveniente.',
        faqs__problems_with_delivery_content_main__one:
            'Recuerda que tienes hasta 24 horas después de recibir tu orden para reportarlo. Una vez que el caso sea gestionado, y de ser el caso, devolveremos el valor del producto a tu Tipti Card o gestionaremos la entrega o cambio del mismo.',
        'faqs__promotions-discounts-rates__subtitle__one': '¿Hay productos frescos al peso?',
        'faqs__promotions-discounts-rates__subtitle__two': '¿Qué hago si quiero un producto que no está en Tipti?',
        'faqs__promotions-discounts-rates__subtitle__three':
            '¿Son distintos los precios de Tipti que los de las tiendas?',
        'faqs__promotions-discounts-rates__main__one':
            'Sí, puedes seleccionar productos frescos, nuestros shoppers elegirán los mejores para ti. Además, puedesañadir una nota al pedido dentro de cada producto con detalles de cómo quieres el corte del pescado, lacarne o incluso qué tan madura quieres la fruta. Puedes comprar tus productos por peso o por unidades.',
        'faqs__promotions-discounts-rates__main__two':
            'Nuestro listado de productos se basa en la disponibilidad de los comercios asociados. Dentro de nuestra app existe la opción de reporte cuando no encuentras un producto, déjanos saber qué es lo que buscas y lo reportaremos a nuestras tiendas afiliadas. Otra manera es escogiendo un producto similar en presentación y contenido, pero puedes especificar en una nota a tu shopper el producto que necesitas.',
        'faqs__promotions-discounts-rates__main__three':
            'Los precios que puedes visualizar en el app son los mismos precios de percha de las tiendas (PRECIO TIPTI). Puedes encontrar algunas diferencias debido a la actualización constante de precios que tienen nuestros retails asociados. Sin embargo dentro de Tipti puedes encontrar muchas promociones únicas y mejores precios que las tiendas.',
        'faqs__my-tipti-card__subtitle__one': '¿Cómo hago uso de mi saldo de TIPTI CARD?',
        'faqs__my-tipti-card__subtitle__two': 'Devoluciones y Saldo en tu Tipti Card',
        'faqs__my-tipti-card__main__one':
            'TIPTI CARD es una herramienta dentro de Tipti con la cuál puedes gestionar tu saldo a favor. Son esta herramienta podrás realizar pedidos, recargar, regalar y transferir saldo a otras cuentas Tipti, o convertir tu saldo en MaxiDólares.',
        'faqs__my-tipti-card__main__two':
            'Para poder hacer uso de tu saldo a favor en tu siguiente compra, al escoger el método de pago debes seleccionar la opción TIPTI CARD, o utilizar saldo, para el pago total o parcial de tu pedido. Si necesitas otro método de pago para completar el valor de la compra puedes usar PayPhone, tarjeta de crédito/débito, transferencia o efectivo hasta $ 50, así tendrás un pago mixto en tu pedido. Recuerda seguir estos pasos, de lo contrario tu saldo de TIPTI CARD seguirá en tu tarjeta.',
        'faqs__my-tipti-card__main__three':
            'Cuando tu shopper no encuentra los productos que solicitaste disponibles en la tienda y no es de tu agrado el reemplazo propuesto por el shopper, este producto no es comprando. En estos casos los valores sobre estos productos se devuelven automáticamente a tu Tipti Card para que puedas usar este saldo en tus futuras compras.',
        'faqs__profile-and-settings__subtitle__one': '¿Cómo puedo ingresar a mi Perfil?',
        'faqs__profile-and-settings__subtitle__two': '¿Cómo puedo activar mis Notificaciones?',
        'faqs__profile-and-settings__main__one':
            'Al ingresar a tu perfil podrás editar: tu cuenta, nombre, dirección, método de pago o teléfono, fecha de cumpleaños y más. También, podrás encontrar tu Tipti ID, el cuál es tu número de usuario. Si deseas editar tu información, sigue los siguientes pasos:',
        'faqs__profile-and-settings__main__two':
            '1. Ve al menú que se encuentra en la esquina superior izquierda de tu app o en la esquina superior izquierda de nuestra web.',
        'faqs__profile-and-settings__main__three':
            '2. Debajo de tu foto de perfil vas a encontrar la opción "Mi Cuenta".',
        'faqs__profile-and-settings__main__four':
            '3. Al hacer clic aquí vas a visualizar tu información y podrás editar todos los campos.',
        'faqs__profile-and-settings__main__five':
            'Tipti te permite activar o desactivar tus notificaciones e mails para que disfrutes de Tipti como tu deseas. Recuerda que al activar las notificaciones podrás revisar fácilmente el estado de tu pedido, estar atento del chat con tu asesor de compra y recibir mails acerca de nuestras promociones vigentes. Para activar notificaciones y mails sigue los siguientes pasos:',
        'faqs__profile-and-settings__main__six':
            '1. Ve al menú que se encuentra en la esquina superior izquierda de tu app o en la esquina superior izquierdo de nuestra web.',
        'faqs__profile-and-settings__main__seven':
            '2. Debajo de tu foto de perfil vas a encontrar la opción "Notificaciones".',
        'faqs__profile-and-settings__main__eight':
            '3. Al hacer clic aquí vas a visualizar en la parte superior derecha el ícono de configuración, al dar clic podrás activar las notificaciones.',
        'faqs__order-in-process__subtitle': '¿Cómo puedo ver el estado de mi pedido?',
        'faqs__order-in-process__main__one':
            'En tu Tipti App o web, podrás visualizar el estado de tu orden desde que ingresas tu pedido hasta que tu compra haya sido entregada en la puerta de tu casa. Recuerda siempre tener actualizada tu app y tener activadas las notificaciones.',
        'faqs__order-in-process__main__two':
            'En caso de requerirlo o presentar algún inconveniente puedes comunicarte con nuestro Care Team al',
        'faqs__order-in-process__main__ten': 'o comunícate por Whatsapp al',
        'faqs__order-in-process__main__three': '1. Encuentra tu pedido:',
        'faqs__order-in-process__main__four':
            'Una vez ingresada tu orden, en la pantalla principal vas a encontrar, en la parte superior, un banner que indica que tienes uno o varios pedidos activos.',
        'faqs__order-in-process__main__five': '2. Estado del pedido:',
        'faqs__order-in-process__main__six':
            'Al dar clic sobre la orden que deseas revisar, podrás encontrar el estado de tus pedidos activos, los detalles de tu pedido e información adicional.',
        'faqs__order-in-process__main__seven': '3.Añadir productos a mi pedido:',
        'faqs__order-in-process__main__eight':
            '¿Alguna vez te haz olvidado de comprar algo en el supermercado? Con Tipti te damos la posibilidad de añadir más productos, o Extra Items, mientras que tu pedido no haya Finalizado en Tienda. Tienes la posibilidad de añadir hasta un máximo de 10 productos, los cuales escogerá tu asesor de compra como parte de tu pedido.',
        'faqs__order-in-process__main__nine':
            'Puedes contactarte con tu asesor de compra mediante el chat o por llamada telefónica. Encontrarás el ícono de llamada y al chat junto a la fotografía de tu asesor de compra dentro del detalle de tu pedido activo. Recuerda que una vez que haya finalizado la compra, ya no podrás comunicarte con tu asesor ni agregar más productos a tu carrito.',
        'faqs__make-an-order__subtitle__one': '¿Cómo realizo mi primer pedido en Tipti?',
        'faqs__make-an-order__subtitle__two': 'Compras compartidas',
        'faqs__make-an-order__main_stg__one': '1.Descarga nuestra app:',
        'faqs__make-an-order__main_stg__two': '2.Selecciona tu tienda favorita:',
        'faqs__make-an-order__main_stg__three': '3.Busca tus productos favoritos:',
        'faqs__make-an-order__main_stg__four': '4.Agrega tus productos al carrito:',
        'faqs__make-an-order__main_stg__five': '5.Revisa que tu carrito esté listo:',
        'faqs__make-an-order__main_stg__six': '6. Para finalizar tus compras confirma tus datos:',
        'faqs__make-an-order__main__one':
            'Elegir tus productos favoritos nunca fue tan fácil, sigue los siguientes pasos para disfrutar la mejor experiencia de compras de tu dispositivo móvil:',
        'faqs__make-an-order__main__two':
            'Descarga Tipti desde Play Store, App Store y App Gallery, o ingresa a www.tipti.market y crea tu cuenta personal.',
        'faqs__make-an-order__main__three':
            'TIPTI pone a tu disposición un diverso catálogo de tiendas donde podrás encontrar gran variedad de productos.',
        'faqs__make-an-order__main__four':
            'Puedes hacerlo directamente desde el buscador o entrando en las categorías de la tienda seleccionada.',
        'faqs__make-an-order__main__five':
            'Una vez que hayas escogido tu producto, da clic sobre este y selecciona la cantidad que deseas usando los botones de + o - para agregarlo al carrito. Si alguno de los productos que deseas no se encuentra en nuestro catálogo, recuerda que puedes seleccionar un producto similar y agregar una nota a tu asesor de compras con las especificaciones del producto que deseas.',
        'faqs__make-an-order__main__six':
            'En la parte superior derecha de nuestra app o web vas a encontrar el ícono de tu carrito de compra. Dentro del carrito, puedes eliminar o agregar productos, disminuir cantidades y comprobar el costo total de tu pedido hasta que esté perfecto.',
        'faqs__make-an-order__main__seven':
            'Elige tu dirección y horario de entrega, colocar tus datos de facturación y selecciona el método de pago con el que deseas realizar la compra.',
        'faqs__make-an-order__main__eight':
            '¡Listo! Pronto uno de nuestros asesores de compras tomará tu pedido y empezará con tu compra',
        'faqs__make-an-order__main__share_stg__one': '1. Ingresa a compras compartidas:',
        'faqs__make-an-order__main__share_stg__two': '2. Crea un carrito compartido:',
        'faqs__make-an-order__main__share_stg__three': '3. Compra juntos: ',
        'faqs__make-an-order__main__share__one':
            '¿Necesitas realizar una compra con muchas personas involucradas? Tipti tiene la solución, haz tus compras con amigos, familia o compañeros de trabajo, utilizando nuestros carritos compartidos:',
        'faqs__make-an-order__main__share__two':
            'Ingresa al carrito, dentro de la pantalla del carrito encontrarás en la sección superior el apartado: Mis carritos. Dentro podrás seleccionar una de las siguientes opciones: crear un carrito personal, crear un carrito compartido o seleccionar un carrito compartido del cuál ya eres integrante.',
        'faqs__make-an-order__main__share__three':
            'Una vez hayas ingresado al apartado encontrarás un botón para generar un nuevo carrito compartido. Al seleccionarlo te llevará a una pantalla donde podrás nombrar el carrito y añadir a los integrantes. Podrás añadir a cuantas personas desees mediante su correo electrónico o su Tipti ID. Recuerda que solo podrás invitar a usuarios Tipti.',
        'faqs__make-an-order__main__share__four':
            'Todos los integrantes pueden añadir productos al carrito compartido. Una vez finalizada la selección de los mismos, únicamente un integrante del carrito podrá finalizar la compra.',
        'faqs__make-an-order__main__share__five':
            '¡Listo! Pronto uno de nuestros asesores de compras tomará el pedido y empezará con su compra',
        'go-back': 'Regresar',
        'bar-vertical': 'barra-vertical',
        'contact-header': 'encabezado de contacto',
        'help-support__contact-us': 'Contáctanos',
        'help-support__your-satisfaction': 'Tu satisfacción es nuestra prioridad',
        'help-support__work-for-you': 'Trabajamos por ti.',
        'help-support__how-help-you': '¿CÓMO TE PODEMOS AYUDAR?',
        'help-support__select-a-faq': 'Selecciona tu tema de interés',
        'order__status-some-orders': 'Tienes varios pedidos activos',
        'order__status-review-status': 'Revisa el estado de cada uno aquí',
        'order__status-show-more': 'Mostrar más',
        'payment-effective__if-your-payment':
            'Si tu pago no es exacto déjanos saber el valor con el que  vas a cancelar para enviar el cambio con nuestro Tipti shopper',
        'payment-effective__need-change-of': 'Necesito cambio de un billete de',
        'payment-effective__have-exact-amount': 'Tengo el monto exacto',
        'payment-transfer__number-account': 'Número de cuenta',
        'payment-transfer__type-account': 'Tipo de cuenta',
        'payment-transfer__wire-transfer-checking': 'Cuenta Corriente',
        'payment-transfer__wire-transfer-savings': 'Cuenta de Ahorro',
        'payment-transfer__attach-your-receipt': 'Adjunta tu comprobante',
        'payment-transfer__voucher-was-successfully': 'El comprobante fue adjuntado con éxito',
        'payment-transfer__voucher-not-be': 'El comprobante no pudo ser adjuntado',
        'payment-transfer__extension_allowed': 'Formatos permitidos (png, jpg, jpeg, pdf)',
        'payment-nequi__title': 'Número de teléfono',
        'payment-nequi__description': 'Ingresa el teléfono de habilitado en Nequi',
        'payment-nequi__input-placeholder': 'Número de telefono habilitado en Nequi',
        'payment-nequi__phone-numbers-list': 'Números utilizados anteriormente',
        'payment-yappy__description': 'Por favor finalizar el proceso de pago \n en la página de Yappy.',
        'checkout__enter-billing': 'Ingresa los datos de facturación de tu pedido',
        'checkout__enter-payment-method': 'Ingresa el método de pago de tu pedido',
        'checkout__enter-address': 'Ingresa dirección de entrega de tu pedido',
        'checkout__enter-billing-title': 'Cambiar datos de facturación',
        'checkout__enter-payment-method-title': 'Cambiar métodos de pago',
        'checkout__enter-address-title': 'Cambiar dirección de entrega',
        'checkout__enter-schedule-title': 'Selección horario de entrega',
        'checkout__enter-billing-data': 'Datos de facturación',
        'checkout__enter-schedule': 'Selecciona el horario de entrega de tu pedido o prográmalo hasta una semana',
        'checkout__order-creation-failure': 'Hubo un error en la creación de la orden',
        'checkout__order-too_many_request':
            'Por seguridad, tenemos un límite de órdenes creadas de forma continua. Por favor, vuelve a intentarlo más tarde.',
        'checkout__order-not_created': 'Orden no creada.',
        'checkout__order-too_many_purchases':
            'Por seguridad, tenemos un limite de compras creadas de forma continua. Por favor, vuelve a intentarlo más tarde.',
        'schedule__free-shipping': 'Envío gratis',
        'schedule__delivery-one-hour': 'Entrega en una hora',
        'schedule__select-delivery': 'Selecciona un horario de entrega para esta tienda',
        'schedule__select-delivery-placeholder':
            'Selecciona un horario de entrega para terminar la compra en esta tienda',
        'schedule__change-schedule': 'Cambio de horario',
        'schedule__change-schedule-alert':
            'Han pasado varios minutos desde que iniciaste tu pedido y los horarios de entrega han cambiado para las siguientes tiendas, por favor seleccione uno nuevo',
        'schedule__change-schedule-header':
            'Han pasado varios minutos desde que iniciaste tu pedido y los horarios de entrega han cambiado, por favor seleccione uno nuevo',
        'add-payphone__the-total-of': 'El total de tu pedido es de',
        'add-payphone__enter-your-number': 'Ingresa tu número para realizar el pago',
        'add-payphone__add-number-success': 'El número fue agregado con éxito',
        'add-payphone__add-number-failed': 'El número no pudo ser agregado',
        'add-payphone__not-valid-number': 'El número de telefono es inválido',
        'tipti-card__min-transfer-amount': '* La cantidad debe ser mayor a {currency} 1',
        'tipti-card__max-transfer-amount': '* La cantidad máxima a transferir debe ser {currency} {amount}',
        'tipti-card__accumulate-balance': 'Acumular saldo',
        'tipti-card__remember-that': 'Recuerda que puedes cancelar tu suscripción cuando quieras',
        'tipti-card__are-you-sure': '¿Estás seguro que quieres cancelar tu Tipti Prime?',
        'tipti-card__btn-share': 'Compartir número de Tipti Card',
        'tipti-card__txt-mail-subject': 'Número de Tipti Card',
        'tipti-card__txt-share':
            '{userName} ha compartido su número de Tipti Card contigo. Puedes copiarlo y utilizarlo para transferencias o giftcards. Número: ',
        'tipti-card__we-are-sorry':
            'Lamentamos que nos dejes, recuerda que puedes retomar tu suscripción cuando desees. tu suscripción Prime estará activa hasta el {date}.',
        'tipti-card__use-balance': 'Utilizar saldo',
        'tipti-card__alert-title': 'Saldo Tipti Card',
        'tipti-card__alert-description':
            'Para completar tu pedido selecciona otro método de pago para pagar la diferencia de {currency} {amount}',
        'tipti-card__do-you-wish': '¿Deseas usar tu saldo de TiptiCard para este pedido?',
        'tipti-card__available-balance': 'Saldo disponible es de',
        'tipti-card__you-need-recharge': 'Debes recargar tu Tipti Card',
        'institutional__alert-title': 'Saldo Institucional',
        'mix-payment__are-you-sure':
            '¿Estás seguro de pagar {currency} {amount} con tu {method}? Puedes pagar la diferencia de {currency} {difference} usando otro método de pago.',
        'address__address-change-success': 'La dirección ha sido cambiada con éxito',
        'address__address-change-failed': 'La dirección no pudo ser cambiada',
        'cambrellas__header-title': 'Fundas',
        'recommendations__header-title': 'También te puede interesar',
        'rate-shopper__alert-success-message': 'Tu calificación ha sido enviada con éxito',
        'rate-shopper__congratulations': 'Felicitaciones',
        'rate-shopper__balance_tip':
            'Tu tip se cancelará con tu saldo de Tipti Card, actualmente solo tienes {currency_symbol}{tipticard_amount}',
        'rate-shopper__your-purchase-made': 'Tu compra ha sido realizada',
        'rate-shopper__rate-your-experience-header':
            'Califica tu experiencia con {shopper_name} y déjanos un comentario',
        'rate-shopper__rate-your-experience': 'Califica tu experiencia',
        'rate-shopper__notification-rate-your-experience': 'Califica tu experiencia de compra',
        'rate-shopper__how-was-purchase': 'Como estuvo tu compra',
        'rate-shopper__how-was-delivery': 'Como estuvo tu entrega',
        'rate-shopper__score': 'Calificación',
        'rate-shopper__question-leave-tip': '¿Quieres dejar una propina?',
        'rate-shopper__tip-greater-than': 'La propina deberá ser igual o mayor a',
        'rate-shopper__leave-a-comment': 'Deja un comentario',
        'rate-shopper__leave-comment-here': 'Deja aquí tu comentario...',
        'rate-shopper__leave-a-compliant': 'DEJALE UN CUMPLIDO A TU SHOPPER',
        'rate-shopper__fields-are-required': '*Los siguientes campos son obligatorios',
        'rate-shopper__profesionalism': 'Profesionalismo',
        'rate-shopper__good-atention': 'Buena atención',
        'rate-shopper__friendly-and-polite': 'Amable y educado',
        'rate-shopper__clean-and-well': 'Limpio y bien presentado',
        'rate-shopper__order-packaging': 'Orden en el empaque de las compras',
        'rate-shopper__puntuality': 'Puntualidad',
        'rate-shopper__comments-and-suggestions': 'TUS COMENTARIOS Y SUGERENCIAS NOS SERVIRÁN',
        'rate-shopper__improve-your-experience': 'PARA MEJORAR TU EXPERIENCIA EN TIPTI',
        'rate-shopper__feature-correct-replacements': 'Reemplazos correctos',
        'rate-shopper__feature-followed-instructions': 'Siguió instrucciones',
        'rate-shopper__feature-complete-order': 'Pedido completo',
        'rate-shopper__feature-quality-products': 'Productos de calidad',
        'rate-shopper__message-complete':
            'Nos ayudas mucho con tu opinión! Si tienes alguna sugerencia para tu siguiente orden, por favor contáctacte con nuestro equipo de CARE TEAM.',
        'tipti-card__free-shipping': 'Envios gratis',
        'tipti-card__send-gift-card': 'Enviar Gift Card',
        'tipti-card__buy-recharge': 'Comprar Recarga',
        'tipti-card__my-gift-card': 'Mis Gift Card',
        'tipti-card__transfer-balance': 'Transferir Saldo',
        'tipti-card__recharge-msj': 'Seleccionar el monto que deseas recargar',
        'tipti-card__recharge-msj-2': 'en',
        'tipti-card__date-send': 'Fecha de envío',
        'tipti-card__user': 'Cliente',
        'tipti-card__empty-moves-msj1':
            'Todavía no tienes movimientos en tu Tipti Card, una vez que empieces a utilizar tu saldo, podrás ver el historial de uso en esta pantalla',
        'tipti-card__empty-moves-msj2':
            'Tu Tipti crad es una tarjeta virtual que puedes utilizar solamente en Tipti, tu saldo se acumula en caso de que haya una devolución de nuestra parte o también si compras una recarga del valor que tu desees.',
        'tipti-card__minimum-rechage': 'El monto mínimo para recargar tu tipticard es de {currency} 1.00',
        'tipti-card__maximum-rechage': 'El monto máximo para recargar tu tipticard es de {currency} {amount}',
        'retailers-modal__title': 'Escoge una de las tiendas participantes para realizar tu compra',
        'retailers-modal__you_may_find': 'Puedes encontrar ',
        'retailers-modal__in_the_following': ' en las siguientes tiendas cerca de ti',
        'product-not-found__title': 'Uups!',
        'product-not-found__message':
            'Al parecer el producto que solicitaste no se encuentra en ninguna tienda al tu alrededor.',
        'deeplink-modal__active-code-success': 'Tu código ha sido activado',
        'deeplink-modal__active-code-error': 'Tu código no ha sido activado',
        'deeplink-modal__active-referred-success': 'Tu código de referido ha sido activado',
        'deeplink-modal__active-referred-error': 'Tu código de referido no ha sido activado',
        'deeplink-modal__active-tipticard': 'Tu saldo de {currency} {balance} ha sido acreditado a tu TiptiCard',
        'deeplink-modal__retailer-not-found': 'No existe este retailer en este sector',
        'order_status__buy-assistant': 'Asesor de compra',
        'order_status__accepted-order-second': ' preparará tu pedido',
        'extra__items-alert-remember-that': 'Recuerda que solo puedes agregar {count} items más a tu compra',
        'extra__items-alert-add-items':
            'Solo puedes agregar hasta {limit} items a tu compra. Actualmente ya tienes seleccionados {actual} items',
        'payment__send-success': 'El pago fue realizado con éxito',
        'payment__send-failed': 'No se pudo realizar el pago, vuelve a intentar',
        'recovery__password-insert-the-mail-one': 'Ingresa el',
        'recovery__password-insert-the-mail-two': 'Correo',
        'recovery__password-insert-the-mail-three':
            'con el que te registraste y te enviaremos un código restablecer tu contraseña',
        'recovery__password-send-mail-succes': 'El correo ha sido enviado con éxito',
        'recovery__password-send-mail-fail':
            'La dirección de correo ingresada no es correcta, por favor vuelva a intentarlo',
        'recovery__password-insert-the-code-one': 'Ingresa el ',
        'recovery__password-insert-the-code-two': 'que enviamos a tu correo electrónico para restablecer tu contraseña',
        'recovery__password-send-code-succes': 'El código ha sido enviado con éxito',
        'recovery__password-send-code-fail': 'El código ingresado no es correcto, por favor vuelva a intentarlo',
        'recovery__password-send-password-success': 'Tu contraseña ha sido cambiada exitosamente',
        'recovery__password-send-password-fail': 'Tu contraseña no pudo ser cambiada',
        'recovery__password-insert-new_password-start': 'Ingresa tu nueva ',
        'recovery__password-insert-new_password-middle': 'contraseña',
        'recovery__password-dont-forget-code': '* No te olvides de ingresar el código',
        'share-product__subject': 'Compartir producto',
        'share-product__message': 'Selecciona tu red favorita para compartir este producto con tus amigos',
        'share-product__message-share':
            'Hola, 😄 {user_name} te ha compartido un {product_name}, puedes encontrarlo en Tipti App',
        'share__list-title': 'Selecciona tu red favorita para compartir la lista con tus amigos',
        'share__list-msg-send':
            'Hola 🤗 haz clic para añadir mi lista de compras a tu carrito en Tipti 🛒 {link} 🍏🍇🍅y te llevan tus compras de supermercado a la puerta de tu casa',
        'cart__continue-to-delivery': 'Continuar a información de entrega',
        'tutorial__store-change': 'Puedes cambiar de tiendas desde este botón en cualquier momento',
        'tutorial__login-or-register': 'Puedes iniciar sesión o registrarte aquí',
        'tutorial__check-shopping-cart': 'Revisa aquí tu carrito una vez que hayas agregado productos',
        'tutorial__store-choose': 'Selecciona una tienda para empezar tus compras',
        'tutorial__news-updates': 'Encuentra aquí noticas relevantes, novedades y nuevas funciones',
        'tutorial__promos-discounts': 'Encuentra aquí las promos y descuentos del día',
        'tutorial__navigate-to-categories': 'Haz click para navegar por las categorías de la tienda',
        'tutorial__shopping-list': 'Haz click para revisar tus listas de compras guardadas',
        'tutorial__check-coupons': 'Haz click para revisar tus cupones disponibles',
        'tutorial__review-active-orders': 'Haz click aquí para revisar tus pedidos activos y tu historial',
        'tutorial__change-sector': 'Puedes cambiar aquí el sector en el que te encuentres para tu compra',
        'tutorial__delivery-choose': 'Selecciona delivery para ver las opciones de tiendas con delivery disponibles',
        'tutorial__pickup-choose': ' Selecciona pickup para ver las opciones de tienda con pickup disponibles',
        'tutorial__location-choose': 'Selecciona la ubicación en las que quieres recibir tus compras',
        'main-products__recommended-categories': 'Categorías recomendadas',
        'address__delete-address-success': 'La dirección fue eliminada con éxito',
        'address__delete-address-failed': 'La dirección no pudo ser eliminada',
        'address__tipti-needs-at': 'Tipti necesita por lo menos una dirección para el proceso de compra',
        'address__add-address-success': 'Dirección agregada',
        'address__edit-address-success': 'Dirección editada',
        'address__edit-address-failed': 'Error al editar dirección',
        'address__no-content-description': 'No tienes direcciones agregadas',
        'address__no-content-title': 'Sin Direcciones',
        'address__failure-one-address-default': 'Necesitas al menos una dirección predeterminada',
        'referred__first-order': 'Para tu primer pedido',
        'referred__code-referred':
            'Ingresa el código de tu amigo y gana {currency} {amountGift} para que los puedas utilizar en tu primera cuenta',
        'referred__button-referred': 'Validar',
        'referred__text-input': 'Escribe tu código',
        'alert__alcoholic-beverages': '¡Espera! ¿Eres Mayor de 18 años?',
        'alert__otp-enter-code': 'Ingresa tu código',
        'alert__wrong-otp': 'Código OTP está equivocado, Vuelve a intentarlo.',
        'burguer__button-news': 'Prensa',
        'retailer__form-we-want-your': 'Queremos ser tus socios',
        'retailer__form-fill-requerid-fields': '* Por favor llenar todos los campos obligatorios',
        'retailer__form-success': 'En breve un agente se contactará con usted',
        'retailer__form-failure': 'Ocurrió un error, intente luego.',
        'retailer__form-procedure-aceept-that':
            'Procedimiento, acepto que Tipti o sus representantes podrán contactarme por correo electrónico, teléfono o mensaje de texto a los datos que registro, incluyendo propósitos comerciales',
        'retailer__form-please-is-important': '* Por favor es importante llenar todos los campos obligatorios',
        'bill__verify-your-data': 'Verifica tus datos',
        'bill__validate-your-data':
            'Verifica que los datos están correctos. Una vez ingresado el pedido no se podrá cambiar los datos de la factura emitida.',
        'bill__form-type-billing-names': 'Nombres y apellidos',
        'bill__form-type-billing-rason': 'Razón Social',
        'bill__form-type-billing-rason-placeholder': 'Juan Pérez',
        'bill__form-identifier-ruc': 'R.U.C',
        'bill__form-identifier-passport': 'Pasaporte',
        'bill__form-no-have-ci': '¿No tienes número de identificación nacional?',
        'resume__purchase-instructions-enter-your': 'Ingresa aquí tus comentarios o instrucciones',
        'resume__purchase-instructions-see-terms': 'Ver términos y condiciones',
        'promotions__without-promotions': 'Sin Promociones',
        'promotions__no-exist-promotions': 'No existen promociones por el momento',
        'promotions__txt-error-loading-promotions': 'Hubo un error al cargar las promociones',
        'home__category-recive-your-purchase': 'Y RECIBE TUS COMPRAS EL MISMO DÍA',
        'home__category-ask-for': 'Pide tus',
        'home__category-ask-products': 'Pide productos de',
        'home__category-vegetables': 'Verduras',
        'home__category-fruits': 'Frutas',
        'home__category-meats': 'Carnes',
        'home__category-babys': 'Bebés',
        'home__category-cleaning': 'Limpieza',
        'news__press-resources-imagotype': 'Imagotipo',
        'add__payphone-example-number': 'Ej: 0988527711',
        'share__cart-my-personal-cart': 'Mi carrito personal',
        'share__cart-create-a-share': 'Crea un carrito compartido',
        'share__cart-make-your-pruchases': 'Haz tus compras con amigos, familia o compañeros de trabajo',
        'login__enter-here': 'Ingresa aquí',
        'credit__cards-registered-cards': 'Tarjetas Registradas',
        'address_add-address': 'Agregar dirección',
        'checkout__menu-no-select-schedule': 'Horario no seleccionado',
        'checkout__menu-please-select-schedule': 'Por favor seleccione un horario para continuar',
        'banner__covid-tipti-shopper-with': 'Tipti shopper con traje de bioseguridad',
        'share__cart-my-carts': 'Mis carritos',
        'share__cart-your-share-carts': 'Tus carritos compartidos',
        'bill__form-message-success-form': 'Se ha {message} sus datos exitosamente!',
        'bill__form-message-failed-form': 'Ocurrió un error al {message} sus datos intente de nuevo...',
        stories: 'Historias',
        sponsor: 'patrocinado',
        'referred__msg-code-send':
            '💵 💵 Te regalo {currency} {amount} para tu primera compra en Tipti, regístrate con esta invitación 💵 💵. {link} Tu código es:  {code}',
        'banner-tipti-prime__tittle': '¡Sácale provecho a tu membresía Tipti Prime!',
        'banner-tipti-prime__subtittle': 'Recuerda que tienes los siguientes beneficios:',
        'banner-tipti-prime__shipping-one': 'Envios ilimitados desde donde sea que estés',
        'banner-tipti-prime__shipping-two': 'Envios ilimitados desde cualquier ubicación en la que estés.',
        'banner-tipti-prime__sale-one': 'Promociones especiales',
        'banner-tipti-prime__sale-two': 'Todos los meses disfruta de promociones exclusivas para ti',
        'banner-tipti-prime__prime-one': 'Tipti Prime Day',
        'banner-tipti-prime__prime-two': '*Una vez al año disfruta de 50% de descuento en todas nuestras tiendas',
        'card__save-success': 'Tu tarjeta se ha guardado con éxito!',
        'card__save-error': 'No se pudo guardar tu tarjeta, vuelve a intentarlo',
        'billing__delete-data-success': 'Se ha eliminado sus datos exitosamente!',
        'billing__change-success': 'Datos de facturación actualizados',
        'billing__change-error': 'No se pudo actualizar sus datos de facturación, intente de nuevo',
        'billing__delete-data-error': 'Ocurrió un error al eliminar sus datos intente de nuevo..',
        'banner-promotions__discover': 'DESCUBRE',
        'banner-promotions__the-offers': 'LAS PROMOS',
        'banner-promotions__that-every': 'QUE CADA',
        'banner-promotions__store-has': 'RETAIL TIENE',
        'banner-promotions__for-you': 'PARA TÍ',
        'retailers__minibanner-offers': 'OBTÉN BENEFICIOS INCLUÍDOS',
        'retailers__minibanner-transport-icon': 'Icono de transporte',
        'retailers__minibanner-discount-icon': 'Icono de descuento',
        'retailers__minibanner-cook-icon': 'Icono de cocina',
        'retailers__minibanner-store-icon': 'Icono de tienda',
        'retailers__minibanner-calendar-icon': 'Icono de calendario',
        'schelude__no-schedule-title': 'No hay horarios disponibles',
        'schelude__no-schedule-subtitle': 'No hay horarios disponibles para este retailer.',
        'statistics__orders-by-stores': 'Pedidos por tienda',
        'statistics__accumulated-orders': 'Pedidos Acumulados',
        'statistics__total-savings': 'Monto mensual ahorrado',
        'statistics__my-orders-with-tipti': 'Mis pedidos con Tipti',
        'statistics__no-orders-detail': 'No tienes pedidos en este mes',
        'statistics__my-experience-tipti': 'Mi experiencia Tipti',
        'banner-goal__txt-footer':
            'La selección de productos y disponibilidad pueden variar según el stock de la tienda',
        'bills-documents__search-bills': 'Buscar por número de pedido',
        'bills-modal__error-identifier':
            'Su documento de identidad no es correcto, por favor verifíquelo y vuelva a intentarlo.',
        'retailer__no-promotion-message': 'No existe retailers con está promoción para tu sector',
        'product-note__save-alert-title': '¿Guardar Nota al Shopper?',
        'product-note__save-alert-content': '¿Tienes una Nota sin guardar, quieres guardarla antes de salir?',
        'tipti-prime__your-amount': 'Tu cuenta es de',
        'complete-profile__not-forget-name': 'No te olvides de ingresar tu nombre',
        'complete-profile__not-forget-lastname': 'No te olvides de ingresar tu apellido',
        'complete-profile__not-forget-phone': 'No te olvides de ingresar tu número telefónico',
        'complete-profile__not-forget-email': 'No te olvides de ingresar tu email',
        'complete-profile__not-forget-document': 'No te olvides de ingresar tu número de identificación',
        'complete-profile__not-forget-birthday': 'No te olvides de ingresar tu fecha de cumpleaños',
        'cart-bar__cashback-title': 'Cashback',
        'cart-bar__cashback-content': 'El valor de tu cashback será acreditado una vez que tu compra sea entregada',
        chat__location: 'Ubicación',
        chat__gallery: 'Galería de fotos',
        chat__camera: 'Cámara',
        chat__placeholder: 'Escribe un mensaje aquí',
        'chat__error-description': 'No se pudo cargar el chat ',
        'chat__notification-title': 'Nuevo mensaje del shopper',
        'chat__error-load-messages': 'Ocurrió un error al cargar los chats, por favor intente nuevamente',
        'chat__error-sending-message': 'Ocurrió un error al enviar el mensaje, por favor intente nuevamente',
        'chat__error-loading-location': 'Ocurrió un error al obtener tu ubicación',
        'chat__chat-by-whatsapp': 'Chatear por whatsapp',
        'chat__finish-chat-alert-content':
            'Este chat está cerrado porque el pedido ha sido entregado, si necesitas ayuda con tu pedido comunícate con nuestro Care Team {phone} o Whatsapp {whatsapp}',
        product_on_demand__shopper_suggestion: 'Sugerencia del shopper',
        delivery__far_delivery_point: 'Punto de entrega lejano',
        delivery__point_of_delivery: 'El punto de entrega seleccionado excede los límites de cobertura en tu ',
        delivery__for_that_motive:
            ' membresía Por tal motivo realizaremos un cobro de la diferencia en tu sector de entrega.',
        delivery__remember_some_sectors: 'Te recordamos que algunos sectores tienen costo de envío adicional.',
        change_address: 'Cambiando dirección de entrega',
        not_found_products: 'Productos no encontrados',
        search_replacements: 'Buscar reemplazos',
        continue_to_payment: 'Continuar a información de pago',
        not_found_in_stock: 'Estos son los productos que no se encontraron en stock:',
        unique_delivery_info_message: 'Entrega en 1 hora | Entrega en una hora | Entrega en {count} horas',
        'secure-shopper__selection-process-at-tipti': 'Procesos de selección en Tipti',
        'secure-shopper__order-status-verification': 'Verificación de estados de pedido',
        'secure-shopper__time-and-quality': 'Tiempo y calidad',
        'secure-shopper__tipti-care-team': 'Tipti Care Team',
        'secure-shopper__verified-identity': 'Identidad verificada',
        'secure-shopper__verified-identity-details':
            'El Shopper ha sido seleccionado por nuestro equipo de Recursos Humanos bajo un intenso proceso de selección validando su documentación, antecedentes penales, recomendaciones y méritos.',
        'secure-shopper__member-since': 'Miembro de Tipti desde',
        'secure-shopper__achievements': 'Reconocimientos',
        'secure-shopper__contact': 'Contacto',
        'secure-shopper__call-shopper': 'Llamar al Shopper',
        'secure-shopper__chat-with-shopper': 'Chatear con Shopper',
        'secure-shopper__security': 'Seguridad',
        'secure-shopper__security__details-1':
            '¡Nos preocupamos por tu seguridad! \nTodos nuestros shoppers, antes de ser contratados, pasan por un proceso de selección y periodicamente reciben capacitaciones. Hoy, respondemos a las condiciones de seguridad que demanda el país.',
        'secure-shopper__security__details-2': 'Aquí puedes obtener más información al respecto:',
        'secure-shopper__security__details-3': 'Si tu duda no aparece aquí, contáctate con nosotros',
        'secure-shopper__selection-process__paragraph-1':
            '¡Aportamos a tu seguridad \nNuestros Shoppers están correctamente identificados, y al igual que todos nuestros colaboradores, pasan por estrictos procesos para su ingreso y permanencia en la empresa.\nProcuramos que todos se apropien de nuestra cultura corporativa y sean personas honestas y responsables. \nLos vehículos de entrega están registrados y cuentan con información legal actualizada.',
        'secure-shopper__order-status__paragraph-1':
            'Para garantizar la máxima transparencia y seguridad; puedes visualizar el estado de tu orden - en la app o en la web - desde que ingresas tu pedido hasta que lo recibes en la puerta de tu casa. ¡Recuerda tener actualizada tu app y activar las notificaciones!',
        'secure-shopper__order-status__heading-1': '¿Cómo puedes seguir tu pedido?',
        'secure-shopper__order-status__paragraph-2':
            '1. Una vez ingresada tu orden, en la parte superior de tu pantalla principal, encontrarás un banner con tus pedidos activos.',
        'secure-shopper__order-status__paragraph-3':
            '2. Dando clic en tu orden podrás ver toda la información y el estado en tiempo real de tu pedido.',
        'secure-shopper__order-status__paragraph-4':
            'Si requieres contactarte personalmente, encontrarás el icono con la foto de tu asesor, ya sea para llamar o activar el chat.',
        'secure-shopper__order-status__paragraph-5':
            'Podrás agregar productos y comunicarte con él antes de que en la aplicación se haya finalizado la compra.',
        'secure-shopper__time-and-quality__paragraph-1':
            'Cumplimos con los más altos estándares de calidad, tanto para la selección de tus productos como para tu seguridad. \nEstamos preparados para brindarte el mejor servicio para que comprar sea una experiencia mágica. ',
        'secure-shopper__time-and-quality__paragraph-2':
            'En Tipti puedes encontrar tus productos favoritos, organizados por categorías, en supermercados y tiendas especializadas. \nTu entrega puede ser programada y la recibirás entre 2 y 3 horas después de que hayas finalizado tu compra. Además tienes la posibilidad de planificar tu entrega hasta dentro de una semana.',
        'secure-shopper__time-and-quality__paragraph-3':
            'Tu entrega puede ser express y recibirás tus productos en el transcurso de 1 hora. Este servicio está sujeto a condiciones de zonas de entrega.',
        'secure-shopper__tipti-care-team__paragraph-1':
            'Somos los encargados de responder con la velocidad que nuestros clientes demandan en caso de que surja alguna duda o dificultad con su experiencia en nuestra aplicación. \nBrindamos soluciones efectivas e inmediatas. ',
        'secure-shopper__tipti-care-team__paragraph-2':
            'Nuestro objetivo principal es garantizar la satisfacción del cliente. \nEstamos preparados para responder y actuar en caso de hubiera alguna duda respecto a la seguridad y fiabilidad en tu entrega.',
        'secure-shopper__tipti-care-team__paragraph-3':
            'Estamos listos para ayudarte de lunes a domingo, desde las 7h00 hasta las 23h00.',
        'secure-shopper__tipti-care-team__any-doubts': '¿Tienes más dudas? Contáctate con nosotros',
        'buy-now': '¡Compra ahora!',
        'ongoing-offers': 'Ofertas en curso',
        'tipti-wallet-gift-cards-title': 'Tipti Giftcards',
        'tipti-wallet-gift-cards-view-expired': 'Ver tarjetas vencidas',
        'gift-card-available-balance': 'Saldo disponible',
        'gift-card-expired-on': 'Vencida el: ',
        'gift-card-latest-transactions': 'Últimas transacciones',
        'gift-card-transactions-placeholder-title': 'Todavía no tienes transacciones con esta tarjeta',
        'gift-card-transactions-placeholder-description':
            'No has realizado ninguna compra con esta tarjeta, comienza un pedido con los productos exclusivos de esta tarjeta.',
        'gift-card-transactions-history-title': 'Historial {card_name}',
        'gift-card-transactions-history-filter-all': 'Todas',
        'gift-card-transactions-history-filter-returns': 'Recargas',
        'gift-card-transactions-history-filter-expenses': 'Gastos',
        'gift-card-transactions-history-view-all': 'Ver todas mis transacciones',
        'gift-card-transaction-return': 'Devolución',
        'gift-card-valid-until': 'Válida hasta: ',
        'gift-card-available-stores-title': 'Tiendas disponibles',
        'gift-card-available-stores-description':
            'Aquí podrás encontrar las tiendas con todos los productos con los que podrás usar tu tarjeta',
        'gift-card-placeholder-text': 'No dispones ninguna Giftcard activa',
        'gift-card-change-card-btn': 'Continuar sin Giftcards',
        'gift-card-checkout-available-balance-modal-description':
            '¿Deseas utilizar el saldo que tienes disponible en tus giftcards de Tipti?',
        'gift-card-checkout-select-card': 'Seleccionar Gift Card',
        'prescription-required': 'Receta médica requerida',
        'format-invalid-file':
            'El archivo que intentas cargar no tiene el formato correcto. Selecciona un archivo diferente y vuelve a cargarlo',
        'for-your-home': 'Para tu hogar',
        'you-have-everything-you-need': '¿Tienes todo lo que necesitas?',
        'text-recommendations-items': 'Agrega productos recomendados para completar tu pedido.',
        'text-product-not-available': 'Selecciona un reemplazo si el producto que deseas se agota en la tienda:',
        'text-recommendations-items-no-available': 'Producto agotado en tienda. Por favor elige un producto similar:',
        'sold-out-in-store-not-available': 'Agotado',
        'sold-out-in-store': 'en tienda',
        'to-sold-out-by': 'Por',
        'to-sold-out': 'agotarse',
        'replacement-suggestions': 'Sugerencias de reemplazo',
        'select-a-replacement': 'Selecciona un reemplazo si el producto que deseas se agota en la tienda:',
        'product-out-of-stock-in-store': 'Producto agotado en tienda. Por favor elige un producto similar:',
        'product-selected-successfully': '¡Producto seleccionado con éxito!',
        'product-is-not-available': 'Si el producto no está disponible, tu shopper seleccionará tu reemplazo.',
        'no-product-was-selected': 'No product was selected to replace the one that was sold out.',
        'product-was-not-added': 'El producto no fue agregado al carrito',
        'availability-of-our-products':
            'La disponibilidad de nuestros productos está sujeta al inventario físico de la tienda y actualmente no se encuentra disponible.',
        'item-was-added-to-replace': 'Este producto se añadió en lugar del producto no disponible.',
        'has-accumulated': 'Has acumulado',
        'points-text': 'PUNTOS',
        'accumulated-points': 'Puntos Acumulados',
    },

    cta: {
        here: 'aqui',
        delete_card: 'Eliminar tarjeta',
        done: 'Hecho',
        maxi_dollars__transfer: 'Transferir a mi Tipti Card',
        communicate_with_advisor: 'Comunicarme con un Asesor',
        need_help_order: 'Necesito ayuda con mi pedido',
        go_to_orders: 'Ir a pedidos',
        contact__care_team: 'Contactar TIPTI CARE TEAM',
        change_payment_method: 'Cambiar método de pago',
        reload_page: 'Volver a cargar página',
        show_card: 'Ver tarjeta',
        'change-to-pickup': 'Probar Pickup',
        'continue-in-delivery': 'Seguir con Delivery',
        try_again: 'Intentar nuevamente',
        exit: 'Salir',
        verify: 'Verificar',
        'no_go-back': 'No, regresar',
        yes_deactivate: 'Si, desactivar',
        'see-image': 'Ver imagen',
        'add-image': 'Adjuntar imagen',
        subscribe: 'Suscribirme',
        close: 'Cerrar',
        'no-delete': 'No eliminar',
        'delete-and-continue': 'Eliminar y seguir comprando',
        'yes-cancel': 'Sí, cancelar',
        'no-return': 'No, ir atrás',
        'cancel-subscription': 'Cancelar suscripción',
        confirm: 'Confirmar',
        'continue-with-facebook': 'Continuar con Facebook',
        'cancel-order': 'Cancelar orden',
        'delete-replacement': 'Elimimar reemplazo',
        'empty-cart': 'Vaciar carrito',
        'report-product': 'Reportar producto',
        'continue-shopping': 'Continuar comprando',
        add: 'Agregar',
        become: 'Conviértete en',
        accept: 'Aceptar',
        'accept-terms': 'Aceptar términos y condiciones',
        reject: 'Rechazar',
        'legal-age': 'Si, soy mayor de edad',
        'not-yet': 'No todavía',
        'add-to-list': 'Añadir a una lista',
        'add-to-list-to-card': 'Enviando lista a carrito',
        'add-list': 'Agregar a lista',
        delete: 'Eliminar',
        'see-more-news': 'Ver más noticias',
        'see-more-sectors': 'Ver más sectores',
        'close-session': 'Cerrar Sesión',
        'deactivate-account': 'Desactivar Cuenta',
        'about-tipti': 'Acerca de Tipti',
        'work-with-us': 'Trabaja con nosotros',
        'coverage-sectors': 'Sectores de cobertura',
        'codes-promotions': 'Códigos y Promociones',
        'order-state': 'Estado',
        'help-and-support': 'Ayuda y soporte',
        change: 'Cambiar',
        cancel: 'Cancelar',
        continue: 'Continuar',
        'save-changes': 'Guardar Cambios',
        'complete-profile': 'Completar',
        'my-account': 'Mi Cuenta',
        notifications: 'Notificaciones',
        referred: 'Referidos',
        subscriptions: 'Suscripciones',
        settings: 'Configuración',
        home: 'Inicio',
        categories: 'Categorías',
        'my-lists': 'Mis Listas',
        'lists-and-recipes': 'Listas y Recetas',
        coupons: 'Códigos',
        'my-coupons': 'Mis códigos',
        orders: 'Mis pedidos',
        'not-find-product': '¿No encuentras lo que buscabas?',
        'shared-carts': 'Carritos compartidos',
        'my-cart': 'Carrito',
        'new-user': 'Nuevo usuario',
        information: 'Información',
        'more-information': '*Para más información  ingresa aquí.',
        save: 'Guardar',
        'change-password': 'Cambiar Contraseña',
        'see-more': 'Ver más',
        'see-less': 'Ver menos',
        'add-note': 'Agregar nota',
        'modified-note': 'Guardar nota',
        'confirm-replace': 'Confirmar reemplazo',
        replacement: 'Reemplazo',
        'add-to-cart': 'Agregar al carrito',
        'add-products': 'Añadir productos',
        'add-products-to-cart': 'Añadir Productos al Carrito',
        'save-list': 'Agregar a mis listas',
        'schedule-list': 'Programar Lista',
        'cancel-schedule-list': 'Cancelar Lista Programada',
        edit: 'Editar',
        back: 'Atrás',
        'annual-plan': 'Activar plan anual',
        'month-plan': 'Activar plan mensual',
        'try-plan': 'Activar prueba',
        'continue-buying': 'Seguir comprando',
        'my-order': 'Mi pedido',
        'rate-shopper': 'Calificar Shopper',
        'send-tip': 'Enviar propina',
        'contact-shopper': 'Contactar shopper',
        'change-payment': 'Cambiar pago',
        'add-schedule': 'Cambiar horario',
        'add-number': 'Agregar número',
        buy: 'Comprar',
        'recharge-my-tipticard': 'Recargar mi Tipti Card',
        'see-more-items': 'Ver más productos',
        search: 'Buscar',
        'skip-rate-shopper': 'Omitir',
        'skip-tutorial': 'Saltar tutorial',
        skip: 'Saltar',
        next: 'Siguiente',
        finish: 'Finalizar',
        'log-in': 'Iniciar sesión',
        menu: 'Menú',
        'continue-with-apple': 'Continuar con Apple',
        'home-register': 'Regístrate aquí',
        authorize: 'Sí, Autorizo',
        'not-authorize': 'No Autorizo',
        'see-bills': 'Ver Facturas',
        'btn-yes-save': 'Sí, Guardar',
        'btn-not-discard': 'No, Descartar',
        'continue-with-google': 'Continuar con Google',
        update: 'Actualizar',
        'btn-login-google': 'Iniciar sesión con Google',
        'btn-login-facebook': 'Iniciar sesión con Facebook',
        'btn-register-google': 'Regístrate con Google',
        'btn-register-facebook': 'Regístrate con Facebook',
        defer: 'diferir',
        'go-payments': 'Ir a Métodos de pago',
        'see-more-suggestions': 'Ver más sugerencias',
        add_product: 'Agregar producto',
        'see-similar-products': 'Ver productos similares',
        add_to_cart: 'Agregar al carrito',
        already_in_cart: 'En el carrito',
        update_quantity: 'Cambiar cantidad',
        protection_of_data: 'Protección de datos personales',
    },

    general: {
        other_payment_option: 'Hemos encontrado que tienes otras opciones para pagar:',
        or: 'o',
        pay_order: 'Pagar pedido',
        add_new_card: 'Agregar una nueva tarjeta',
        more_payments: 'Ver más formas de pago',
        reason: 'Motivo',
        go_to_buy: 'Ir a comprar',
        connection_error: 'Ha ocurrido un problema de conexión, por favor inténtalo nuevamente en unos minutos',
        agree: 'Acepto',
        dni_passport: 'Número de pasaporte',
        all_stores: 'Todas las tiendas',
        retailer_filter_all_store: 'TODAS LAS TIENDAS',
        go_back: 'Volver al inicio',
        hello: 'Hola',
        done: 'Listo',
        units: 'unidades',
        subscriptions: 'Suscripciones',
        pay__only: 'A tan solo',
        terms_and_conditions__message: 'Al adquirir nuestras membresía, aceptas los',
        extra_items_buy__loading: 'Agregando productos a tu pedido',
        extra_items_buy__success: 'Productos añadidos con éxito',
        extra_items__added_products: 'Productos añadidos',
        extra_items__review_products: 'Revisa los productos que vas a añadir',
        extra_items__need_something: '¿Necesitas algo más?',
        extra_items__need_something__legend: 'Hemos seleccionado estos productos de interés basándonos en tu pedido',
        add_products: 'Agregar productos',
        no_close_tab: 'No cierres esta pestaña',
        transferred: 'Transferidos',
        searching_country: '¿Que país buscas?',
        value_payed: 'Valor pagado',
        value_to_pay: 'Valor a pagar',
        select_method: 'Selecciona tu Metodo de pago',
        select_method_description: 'Selecciona el método de pago con el cual deseas cancelar tus pedidos',
        communicate_with_advisor: 'Comunicarme con asesor',
        payment_rejected: 'Pago rechazado',
        keep_shopping: 'Seguir comprando',
        program: 'Programa',
        has_problem: 'Ha ocurrido un problema',
        error_occurred: 'Ha ocurrido un problema, por favor inténtalo de nuevo',
        accredited: 'Acreditado',
        un_link: 'Desvincular',
        affiliation: 'Afiliación',
        converter: 'Convertir',
        your_benefits: 'Tus beneficios',
        active: 'Activo',
        inactive: 'Inactivo',
        validating_data: 'Validando datos',
        'invalid-fields': 'Datos invalidos',
        'invalid-fields-name': 'No se admiten cararcteres especiales,consumidor final o números',
        please_reload: 'Algo salio mal, por favor recargue la página',
        verification_failed: 'Verificación fallida',
        tipti_team: 'Tipti Care Team',
        attempts_remaining: 'Intentos restantes: ',
        value_incorrect: 'El valor ingresado es incorrecto, por favor vuelve a intentarlo',
        ups: 'Ups!',
        and: 'y',

        dont_close_app: '¡No cierres la app o esta pestaña hasta que termine el proceso!',
        dont_close_browser: 'Por favor no cierres el navegador.',
        verifying: 'Verificando',
        approx: 'aprox.',
        success: 'Éxito',
        leave_comment: 'Déjanos tus comentarios',
        skip: 'Omitir',
        recipes: 'Sin recetas | Una receta | {count} Recetas',
        'tipti-recipes': 'Recetas Tipti',
        error: 'Error',
        benefits: 'Beneficios',
        yes: 'Sí',
        no: 'No',
        option: 'Opción',
        activated: 'Activada',
        'retail-name': 'Nombre del local',
        'retail-type': 'Tipo de local',
        'enterprise-type': 'Tipo de empresa',
        'contact-name': 'Nombre de contacto',
        featured: 'Sugerido',
        'product-out-stock': 'Producto fuera de stock.',
        'month-year': 'MM/YY',
        'almost-ready': '¡Casi listos!',
        'wait-a-second': 'Espera un momento, estamos cargando tus datos!',
        'product-added': 'Tu producto ha sido agregado al carrito correctamente.',
        'you-may-interested': 'También te pueden interesar',
        note: 'Nota',
        other: 'Otro',
        'describe-motive': 'Describe el motivo',
        'delivery-time': 'Horario de entrega',
        'pickup-time': 'Horario Pickup',
        'pickup-place': 'Lugar Pickup',
        'load-more': 'Cargar más',
        items: 'un producto | {count} productos',
        'set-as-default': 'Marcar como predeterminada',
        'required-fields': 'Campos requeridos',
        'required-fields-alert': 'Recuerda llenar los campos obligatorios',
        'billing-data': 'Datos de facturación',
        statistics: 'Estadísticas',
        'by-weight': 'peso',
        'in-units': 'unidades',
        description: 'Descripción',
        high: 'Alto',
        medium: 'Medio',
        low: 'Bajo',
        fat: 'Grasa',
        sugar: 'Azúcar',
        salt: 'Sal',
        'your-message-sent': 'Tu mensaje se envió',
        'your-message-not-sent': 'Tu mensaje no se envió, vuelve a intentarlo',
        'credit-debit-card': 'Tarjeta de Crédito / Débito',
        'special-price': 'Precio especial',
        'regular-price': 'Precio',
        'product-name': 'Nombre del producto',
        'download-the-app': 'Descarga nuestra app:',
        'expert-shoppers': 'Shoppers expertos',
        see: 'Ver',
        'name-and-surname': 'Nombre y Apellido',
        total: 'Total',
        'become-part-of': 'Forma parte de nuestra familia',
        'client-service-option': 'Servicio al cliente',
        'elder-people-service': 'Servicio adulto mayor',
        'about-us': 'Acerca de nosotros',
        sectors: 'Sectores',
        select_address_note: 'Puedes seleccionar tu dirección desplazandote por el mapa',
        map_address_direction: 'Ingresa aquí la dirección',
        cardAddress__reference: 'Edificio, piso, departamento',
        cardAddress__alias: 'Casa Quito',
        retailers: 'Tiendas',
        'see-retailers': 'Ver tiendas',
        news: 'Sala de prensa',
        billing: 'Facturación',
        'next-delivery': 'Próxima entrega',
        'terms-and-conditions': 'Términos y condiciones',
        'error-to': 'Error al {message}, intente de nuevo..',
        copy: 'Copiar',
        code: 'Código',
        spanish: 'Español',
        english: 'Inglés',
        name: 'Nombre',
        'last-name': 'Apellidos',
        'document-type': 'Tipo de documento',
        dni: 'Documento de identidad',
        dni_document: 'Número de cédula',
        email: 'Correo electrónico',
        cellphone: 'Número de Teléfono',
        genre: 'Género',
        male: 'Masculino',
        order: 'Pedido',
        female: 'Femenino',
        password: 'Contraseña',
        birthday: 'Fecha de nacimiento',
        'select-delivery-sector': 'Confirma tu sector de entrega',
        address: 'Dirección',
        delivery: 'Delivery',
        default: 'Predeterminado',
        promotions: 'Promociones',
        'coverage-sector': 'SECTORES DE COBERTURA',
        price: 'Precio',
        discounts: 'Descuentos',
        unit: 'Unidad',
        'pick-up': 'Pickup',
        'not-information': 'Sin información',
        'delivery-schedule': 'Horarios de entrega',
        'billing-information': 'Datos de facturación',
        filters: 'Filtros',
        date: 'Fecha',
        hour: 'Hora',
        participants: 'Sin participantes | Un participante | {count} Participantes',
        products: 'Sin productos | Un producto | {count} Productos',
        'payment-methods': 'Métodos de pago',
        'payment-cards': 'Tarjeta de crédito/débito',
        'payment-institutional': 'Pago Institucional',
        'payment-cash': 'Efectivo',
        'payment-deferred': 'Pago diferido',
        'payment-current': 'Pago corriente',
        'payment-wire-transfer': 'Transferencia bancaria',
        'payment-online-bank-services': 'Servicios bancarios en Línea',
        'payment-payphone': 'Payphone',
        'payment-bank-check': 'Cheque',
        'payment-nequi': 'Pago Nequi',
        'payment-yappy': 'Pago Yappy',
        'payment-tipti-card': 'Tipti Card',
        'search-on': 'Buscar en',
        'search-on-stores': 'Buscar en Tipti',
        share: 'Compartir',
        'payment-method': 'Método de pago',
        'no-products': 'Sin Productos',
        send: 'Enviar',
        'covid-msg': 'Entérate acerca de nuestras regulaciones con el COVID-19',
        city: 'Ciudad',
        phone: 'Teléfono',
        message: 'Mensaje',
        annual: 'Anual',
        monthly: 'Mensual',
        'best-offer': 'Mejor Oferta',
        'a-month': 'Al mes',
        'appply-terms-conditions': 'Aplica términos y condiciones',
        try: 'Prueba',
        by: 'por',
        'a-month-free': '1 Mes Gratis',
        subtotal: 'SUBTOTAL',
        'subtotal-fee': 'SUBTOTAL TARIFA',
        iva: 'IVA',
        savings: 'AHORRO',
        'code-to-activate': '¿TIENES UN CÓDIGO POR ACTIVAR?',
        'total-order': 'Total a pagar',
        'your-bill': 'Tu cuenta es de',
        'select-payment-method': 'Selecciona tu método de pago',
        select: 'Seleccionar',
        finalize: 'Finalizar',
        subscribe: 'Subscribirne',
        'available-balance': 'Saldo disponible',
        'card-number': 'Número de Tarjeta',
        'use-balance': 'Usar Saldo',
        'new-number': 'Agregar nuevo número',
        notifications: 'Notificaciones',
        'accept-contact-me':
            'Procedimiento, acepto que Tipti o sus representantes podrán contactarme por correo electrónico, teléfono o mensaje de texto a los datos que registro, incluyendo propósitos comerciales',
        'required-field': 'Este campo es requerido',
        incorrect_number: 'Número incorrecto',
        'enter-valid-mail': 'Ingrese un email válido',
        calories: 'Calorías',
        preparation: 'Preparación',
        'products-added-cart': 'Los productos fueron agregados correctamente al carrito',
        'products-not-added-cart': 'Ocurrió un error al agregar los productos',
        'institutional-assigned': 'Instituciones asignadas',
        ruc: 'R.U.C',
        number_ruc: 'Número de R.U.C.',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre',
        days: 'Días',
        hours: 'Horas',
        minutes: 'Minutos',
        'code-to-refill': '¿TIENES UN CÓDIGO DE RECARGA?',
        apply: 'Aplicar',
        'code-successfully': 'El código fue ingresado con éxito',
        'code-incorrect-or-already': 'El código ingresado es incorrecto o ya fue activado.',
        thanks: 'Gracias',
        'no-thanks': 'No, Gracias',
        no_thanks: 'No gracias',
        attention: 'Atención',
        number: 'Número',
        amount: 'Monto',
        resume: 'Resumen de compra',
        'msj-not-value': 'No olvides ingresar el valor',
        language: 'es',
        'promotion-date': 'Fecha de promoción',
        'participant-cities': 'Ciudades participantes',
        'participant-retailers': 'Tiendas participantes',
        'groceries-advisor': 'Asesor de compras',
        'shopper-deliver': 'Repartidor',
        'tipticard-error': 'Activar tu TiptiCard',
        example: 'Ej: {message}',
        'select-retailer': 'Selecciona tu tienda',
        'recharge-tipticard': 'Recargar tu TiptiCard',
        'buy-giftcard': 'Comprar tu GiftCard',
        'transfer-amount': 'Transferir saldo',
        'buy-gift-success': 'Ha adquirido una GiftCard de {currency} {value}',
        'open-product': 'Abrir producto',
        'open-retailer': 'Abrir tienda',
        'open-recipe': 'Abrir receta',
        'open-list': 'Abrir lista',
        'see-recipe': 'Ver receta',
        'open-categories': 'Abrir categorías',
        'open-promotion': 'Abrir promoción',
        'open-screen': 'Abrir pantalla',
        'open-stories': 'Abrir historia',
        'help-contacts': 'Ayuda y Contactos',
        'client-service-option-mobile': 'Opción {count}',
        'elder-people': 'Adulto Mayor',
        'enter-your-coupon': 'Ingresa tú cupón',
        'recharge-code': 'Código de recarga',
        loading: 'Cargando...',
        'fill-fields-to-continue': 'Llena todos los cambios para continuar',
        here: 'aquí',
        'see-more-products': 'ver más productos',
        'header-user-img': 'imagen de encabezado de usario',
        'header-user-generic-img': 'imagen genérica de encabezado de usario',
        'page-not-found': 'Página no encontrada',
        'close-button': 'Botón cerrar',
        'profile-picture': 'Imagen de perfil',
        'profile-picture-generic': 'Imagen genérica de perfil',
        'remplace-for': 'Reemplazar por',
        added: 'agregado',
        modified: 'modificado',
        available: 'Disponible',
        redeemed: 'Redimido',
        in: 'en',
        'order-without-changes': 'Orden sin cambios',
        'delete-billing-information': 'Eliminar datos de facturación',
        orders: 'Sin Pedidos | Pedido | Pedidos',
        year: 'Año',
        country: 'País',
        'home-register': '¿Eres nuevo?',
        'deeplink-modal__general-error': 'No se encontró información relacionada a este enlace',
        'billing-information__not_found': 'No se ha encontrado ninguna factura relacionada a esta orden.',
        'issue-date': 'Fecha de emisión',
        document: 'Documento',
        'number-document': 'Número de documento',
        bill: 'Factura',
        'bills-documents': 'Facturas y documentos',
        'no-available': 'No disponible',
        dollar: 'Dólar|Dólares',
        'string-number': 'Tres',
        'your-products': 'Tus productos',
        'products-general': 'Productos',
        'search-results': 'Resultados de búsqueda',
        'from-date': ' de ',
        'general-login-with': 'Inicia sesión con',
        'general-register-with': 'Registrarme con',
        'general-error': 'Un error ha ocurrido',
        'try-again': 'Por favor, intenta de nuevo más tarde',
        from: 'de',
        to: 'a',
        'transaction-error': 'Ocurrio un error al durante la transacción, por favor intenta nuevamente',
        chat: 'Chat',
        tipti_card: 'Tipti Card',
        edit_credit_card: 'Editar tarjeta',
        before: 'Antes',
        tipti_card_prime: '¿Quieres envío GRATIS?',
        tipti_card_prime_benefits: 'Obtén beneficios con',
        'news-text-one':
            'Trabajamos para extender nuestro servicio y que más personas puedan comprar de forma segura y digital sus alimentos. Todos los días, nos preparamos para servir mejor y crear una grata experiencia para nuestros clientes.',
        'news-text-two':
            'En esta sección podrás encontrar nuestros boletines de prensa y logotipos con sus diferentes aplicaciones.',
        'news-text-three': 'Además están disponibles nuestras más relevantes noticias en medios de comunicación.',
        'see-more-newsletters': 'Ver más boletines',
        'press-releases': 'Boletines de Prensa',
        'news-in-the-med': 'Noticias en medios de comunicación',
        'download-complete-kit': 'Descargar kit completo',
        'download-brand-manual': 'Descargar manual de marca',
        'brand-text':
            'Selecciona el o los formatos de nuestros Artwork’s que necesites para descargar. Recuerda que al descargar usar cualquier archivo de nuestra página estás aceptando haber leído y respetado el',
        'brand-manual': 'Manual de marca',
        'file-formats': 'FORMATOS DE ARCHIVOS EN SVG/PNG',
        'for-websites-and-applications': 'Para sitios web y aplicaciones',
        'logo-with-description': 'LOGOTIPO CON DESCRIPCIÓN',
        'flat-logo': 'LOGOTIPO PLANO',
        'logo-with-slogan': 'LOGOTIPO CON SLOGAN',
        'logo-text-brand':
            'Cada archivo contiene el logotipo oficial de TIPTI S.A con sus colores oficiales, tanto en positivo como negativo.',
        'download-svg': 'Descargar SVG',
        'related-content': 'Contenido relacionado',
        copied: '¡Copiado!',
        copied_text: 'Copiado',
        source: 'Fuente',
        'news-press-room': 'Noticia',
        'hello-news': 'Hola',
        'text-shared-news': 'ha compartido con ustedes la siguiente noticia:',
        'text-shared-news-two': 'puede encontrarlo en',
        'share-news': 'Compartir',
        'privacy-policy-about':'Politica de Privacidad',
        'help':'Ayuda',
        'protection-of-personal-data':'Proteccion de datos Personales',
        'talent-hotbeds': 'Semilleros de Talento'
        
    },
    icon: {
        card: 'Tarjeta',
        reload: 'Recargar',
        attach_file: 'Adjuntar archivo',
        send: 'Enviar',
        double_check_unread: 'Message sent',
        double_check_read: 'Message sent and read',
        single_check: 'Mensaje no recibido',
        reply: 'Responder',
        default_card: 'Tarjeta por defecto',
        validated_card: 'Tarjeta validada',
        success: 'Éxito',
        replace: 'Reemplazar',
        close: 'Cerrar',
        delete: 'Eliminar',
        add_to_lists: 'Añadir a listas',
        address: 'Dirección',
        no_content: 'Sin contenido',
        adult: 'Censurado, solo adultos',
        all_stores: 'Todas las tiendas',
        all_stores_selected: 'Todas las tiendas - seleccionado',
        arrow: 'Flecha',
        hide: 'Ocultar',
        hidden: 'Oculto',
        show: 'Mostrar',
        shown: 'Visible',
        back: 'Retornar',
        backward: 'Retroceder',
        forward: 'Avanzar',
        replacement: 'Reemplazo seleccionado',
        auto_replace: 'Reemplazar automaticamente',
        no_profile_picture: 'Sin foto de perfil',
        shopping_bag: 'Funda de compras',
        bank: 'Banco',
        notifications: 'Notificaciones',
        bill: 'Factura',
        digital_documents: 'Facturas y documentos electrónicos',
        care_team: 'Care Team',
        cart: 'Carrito',
        full_cart: 'Carrito lleno',
        cash: 'Efectivo',
        categories: 'Categorías',
        copy_to_clipboard: 'Copiar al portapapeles',
        chat: 'Ver chat',
        recipes: 'Recetas',
        error: 'Error',
        remove: 'Remover',
        clean_and_good_presentation: 'Limpio y bien presentado',
        client_profile: 'Perfil del cliente',
        settings: 'Configuración',
        coupons: 'Cupones',
        credit_card: 'tarjeta de crédito',
        yappy: 'yappy',
        delivered_and_rated: 'Entregado y calificado',
        document: 'Documento de identidad',
        unfold: 'Desplegar',
        edit: 'Editar',
        elder: 'Adulto mayor',
        enter_code: 'Ingresar código',
        favorite: 'Favorito',
        facebook: 'Facebook',
        delivery_frequency: 'Frecuencia de entrega',
        friendly: 'Amigable',
        gallery: 'Galería',
        email: 'Correo',
        send_email: 'Enviar Correo',
        good_attention: 'Buena atención',
        help: 'Ayuda',
        home: 'Inicio',
        add: 'Agregar',
        information: 'Información',
        instagram: 'Instagram',
        institution: 'Institución como método de pago',
        linked_in: 'Linked In',
        location: 'Ubicación actual',
        logout: 'Cerrar sesión',
        email_recovery: 'Recuperar email',
        menu: 'Menú',
        mute: 'Silenciar',
        lists: 'Listas',
        note: 'Nota',
        save_note: 'Guardar Nota',
        show_note: 'Ver Nota',
        online_bank: 'Servicio bancario en línea como método de pago',
        order_accepted: 'Orden aceptada',
        order_cancelled: 'Orden cancelada',
        order_delivered: 'Orden entregada',
        orders: 'Mis pedidos',
        order_packaging: 'Orden en el empaque',
        password_recovery: 'Recuperar contraseña',
        payment_success: 'Pago exitoso',
        payment_methods: 'Métodos de pago',
        payment_rejected: 'Pago rechazado',
        payphone: 'Payphone como método de pago',
        person: 'Hecho para',
        phone: 'Teléfono',
        prime: 'Tipti Prime',
        prime_day: 'Día Prime',
        prime_delivery: 'Entrega Prime',
        prime_diamond: 'Prime Diamante',
        prime_discounts: 'Descuentos Prime',
        prime_trial: 'Prime de prueba',
        prime_badge: 'Insignia Prime',
        featured: 'Patrocinado',
        prime_store: 'Tienda Prime',
        product_added: 'Producto añadido',
        product_description: 'Descripción del producto',
        professionalism: 'Profesionalismo',
        promotions: 'Promociones',
        puntuality: 'Puntualidad',
        referreds: 'Referidos',
        schedule: 'Horario',
        search: 'Buscar',
        share: 'Compartir',
        shared_cart: 'Carrito compartido',
        cart_members: 'Miembros del carrito',
        shipping: 'Envío',
        shopper_assigned: 'Shopper asignado',
        shopper_buying: 'Shopper comprando',
        shopper_delivering: 'Shopper entregando',
        shopper_in_destiny: 'Shopper en el destino',
        rate: 'Calificación',
        store_purchase_completed: 'Compra terminada en la tienda',
        store: 'Tienda',
        subscription: 'Subscripciones',
        tipti: 'Logo de Tipti',
        tipti_card: 'Tipti Card',
        buy_recharge: 'Comprar recarga',
        my_gift_card: 'Mis Gift Cards',
        send_gift_card: 'Enviar Gift Card',
        transfer_amount: 'Transferir monto',
        twitter: 'Twitter',
        warning: 'Advertencia',
        whatsapp: 'Whatsapp',
        wire_transfer: 'Transferencia bancaria como método de pago',
        zoom: 'Acercar',
        exchange: 'Convert',
        broken_card: 'No cards',
        data_protection:'Protección de datos',
        rewards: 'Recompensas',
        add_to_cart: 'Agregar al carrito',
        arrow_shaft: 'Flecha con asta',
        minus_circle: 'Círculo con signo mas',
        plus_circle: 'Círculo con signo menos',
        historyExchange: 'historia Intercambio',
    },
};
