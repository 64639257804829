<template>
    <base-icon :height="dimensions.height" :icon-color="color" :width="dimensions.width" viewBox="0 0 23 19">
        <svg fill="none" height="19" viewBox="0 0 23 19" width="23" xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M18.2789 0.113548C18.4157 0.188916 18.5303 0.297948 18.6114 0.429966L22.2011 6.08072C22.3133 6.2563 22.3621 6.464 22.3394 6.67021C22.3167 6.87643 22.224 7.06908 22.0762 7.21701L19.6608 9.73877H2.70357L0.288121 7.21701C0.14034 7.06908 0.0476169 6.87643 0.0249574 6.67021C0.00229794 6.464 0.0510274 6.2563 0.16326 6.08072L3.75299 0.429966C3.83409 0.297948 3.94864 0.188916 4.08544 0.113548C4.22224 0.0381792 4.37659 -0.000941883 4.53337 1.72198e-05H17.831C17.9878 -0.000941883 18.1421 0.0381792 18.2789 0.113548ZM4.23612 11.3388L10.4954 17.8736C10.5858 17.9618 10.693 18.0317 10.8108 18.0791C10.9287 18.1265 11.0549 18.1506 11.1822 18.15C11.3095 18.1506 11.4357 18.1265 11.5535 18.0791C11.6714 18.0317 11.7786 17.9618 11.8689 17.8736L18.1282 11.3388H4.23612Z"
                fill="white"
                fill-rule="evenodd"
            />
        </svg>
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { computed } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FFFFFF',
    },
});

const dimensions = computed(() => ({
    width: 23 * props.size,
    height: 19 * props.size,
}));
</script>
