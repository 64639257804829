<template>
    <change-password v-if="showChangePassword" @on-select-option="passwordChanged" />
    <base-card v-else class="edit-profile" :class="{ 'edit-profile--box-shadow': isFromCart }" padding="0">
        <section-header
            v-if="!isFromCart"
            :has-back="isMobile"
            :title="$t('txt.edit-profile__edit-my-profile')"
            @on-back="$router.back"
        />
        <UserProfilePhoto
            v-if="!isFromCart"
            class="edit-profile__avatar"
            :picture
            origin="profile"
            @on-change="onChangePicture"
        />
        <TabMultiOption
            v-if="!profile.document?.length"
            v-model="documentType"
            class="profile-type-from"
            :options="documentTypes"
        />

        <h5 v-if="isFromCart" class="form_header-profile">{{ $t('txt.billing__required_data') }}</h5>
        <form id="editProfileForm" class="edit-profile-form" @submit.prevent>
            <TiptiInputText
                v-if="!isFromCart"
                v-model="completeForm.name"
                :label="$t('general.name')"
                is-required
                placeholder="Eliza"
            />
            <TiptiInputText
                v-if="!isFromCart"
                v-model="completeForm.lastname"
                :label="$t('general.last-name')"
                is-required
                placeholder="Mejía"
            />
            <TiptiInputText
                v-model="completeForm.identityDocument"
                :errors="messageError.identifier"
                :label="documentType.labelInput ?? $t('general.dni')"
                :placeholder="documentType.labelInput ?? $t('general.dni')"
                :readonly="!!profile.document?.length"
            />
            <input-phone-with-label
                v-if="showPhoneInput"
                v-model="completeForm.phone"
                class="margin-input"
                :errors="messageError.phone"
                :phone-already-exists="isPhoneAlreadyRegistered"
                :show-placeholder-with-out-focus="true"
                is-required
            />
            <TiptiInputText
                v-if="!isFromCart"
                v-model="completeForm.email"
                :label="$t('general.email')"
                is-required
                type="email"
            />
            <TiptiInputPassword
                v-if="needsPassword"
                v-model="completeForm.password"
                :label="$t('general.password')"
                is-required
            />

            <TiptiInputText
                v-model="completeForm.birthday"
                :label="$t('general.birthday')"
                :max="maxDate"
                :min="minDate"
                is-required
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                type="date"
            />
            <label class="edit-profile-form__label">
                {{ $t('general.genre') }}
            </label>
            <div class="edit-profile-form__radio">
                <gender-selected
                    v-for="option in [
                        { value: 'MASCULINO', name: $t('general.male') },
                        { value: 'FEMENINO', name: $t('general.female') },
                        { value: 'OTHER', name: $t('general.other') },
                    ]"
                    :key="option.value"
                    v-model="completeForm.genre"
                    :option="option"
                />
            </div>
            <p v-if="!isFromCart" class="edit-profile-change-password" @click="showChangePassword = true">
                ¿{{ $t('cta.change-password') }}?
            </p>
            <button v-if="!isFromCart" class="edit-profile__deactivate-account" @click="showAlertDeactivateAC = true">
                {{ $t('cta.deactivate-account') }}
            </button>
        </form>
        <section-footer :has-border="false" :with-shadow="false">
            <btn-solid :is-disabled="btnIsDisable" :is-loading="isLoading" :txt="$t('cta.save')" @click="saveChanges" />
        </section-footer>
    </base-card>

    <alert-deactivate-account v-if="showAlertDeactivateAC" @on-close="showAlertDeactivateAC = false" />
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import ChangePassword from '../../components/changePassword/ChangePassword.vue';
import AlertDeactivateAccount from '@/components/alerts/alertDeactivateAccount/AlertDeactivateAccount.vue';
import InputPhoneWithLabel from '@/components/inputs/primary/phone/InputPhoneWithLabel.vue';
import { useCountry } from '@/composables/useCountry';
import dayjs from 'dayjs';
import TabMultiOption from '@/modules/core/tabs/TabMultiOption.vue';
import GenderSelected from '@/views/myAccount/views/profile/components/GenderSlected.vue';
import { useUser } from '@/composables/useUser';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';
import { useApp } from '@/composables/useApp';
import UserProfilePhoto from '@/views/myAccount/views/profile/components/profileOptions/UserProfilePhoto.vue';
import { computed, reactive, ref } from 'vue';
import { removeCountryCode } from '@/utils/phoneUtils';

const props = defineProps({
    isFromCart: {
        type: Boolean,
        default: false,
    },
    showNotifierSuccess: {
        type: Boolean,
        default: true,
    },
});

const { profile } = useUser();
const { countryPhoneFromPhoneNumber, concatenateNumberAndCountryCode, countryCodeSelected } = useCountry();
const { notifier, isMobile, $t, $store } = useApp();

const minDate = dayjs().subtract(90, 'year').format('YYYY-MM-DD');
const maxDate = dayjs().subtract(15, 'year').format('YYYY-MM-DD');

countryPhoneFromPhoneNumber(profile.value.phone);

const completeForm = reactive({
    name: profile.value.name ?? '',
    lastname: profile.value.lastname ?? '',
    email: profile.value.email ?? '',
    birthday: profile.value.birthday ?? '',
    genre: profile.value.genre ?? '',
    phone: removeCountryCode(profile.value.phone),
    identityDocument: profile.value.document ?? '',
    password: '',
});

const messageError = reactive({
    phone: '',
    identifier: '',
});

const documentTypes = [
    {
        id: 'IDENTITY_CARD',
        label: $t("general['dni']"),
        labelInput: $t("general['dni_document']"),
        value: 'IDENTITY_CARD',
    },
    {
        id: 'PASSPORT',
        label: $t("txt['bill__form-identifier-passport']"),
        labelInput: $t("general['dni_passport']"),
        value: 'PASSPORT',
    },
    {
        id: 'RUC',
        label: $t("general['ruc']"),
        labelInput: $t("general['number_ruc']"),
        value: 'RUC',
    },
];

const $emit = defineEmits<(e: 'on-accept') => void>();

const documentType = ref(documentTypes.at(0));
const showChangePassword = ref(false);
const isLoading = ref(false);
const isPhoneAlreadyRegistered = ref(false);
const identificationIsAlreadyRegister = ref(false);
const incorrectPhoneNumber = ref(false);
const showAlertDeactivateAC = ref(false);
const picture = ref();

const btnIsDisable = computed(() => {
    if (isLoading.value) return;
    return (
        !completeForm.phone?.length ||
        !completeForm.identityDocument?.length ||
        !completeForm.birthday?.length ||
        !completeForm.genre?.length
    );
});

const showPhoneInput = computed(() => {
    if (props.isFromCart && !profile.value.phone?.length) return true;
    return !props.isFromCart;
});
const needsPassword = computed(() => {
    return profile.value.email !== completeForm.email;
});

const passwordChanged = (value: string) => {
    if (value === 'edit_profile') showChangePassword.value = false;
};

const isValidPhoneAndCodeCountry = computed(() => {
    if (!completeForm.phone?.length) return false;
    if (!countryCodeSelected.value?.regex?.length) return true;
    const reg = new RegExp(countryCodeSelected.value.regex);
    return reg.test(concatenateNumberAndCountryCode(completeForm.phone));
});

const isInvalidForm = (): boolean => {
    if (props.isFromCart) return !completeForm.phone?.length || !completeForm.identityDocument?.length;
    if (!isValidPhoneAndCodeCountry.value) {
        incorrectPhoneNumber.value = true;
        return true;
    }
    return (
        !completeForm.lastname?.length ||
        !completeForm.name?.length ||
        !completeForm.email?.length ||
        !completeForm.identityDocument?.length ||
        !completeForm.birthday?.length
    );
};

const onChangePicture = (file: File | undefined) => {
    picture.value = file;
};

const saveChanges = async (): Promise<void> => {
    if (isLoading.value) return;
    messageError.phone = '';
    messageError.identifier = '';
    incorrectPhoneNumber.value = false;
    isPhoneAlreadyRegistered.value = false;
    identificationIsAlreadyRegister.value = false;
    if (isInvalidForm()) return;
    if (profile.value.email !== completeForm.email && !completeForm.password.length) {
        notifier({
            type: 'ERROR',
            body: $t("txt['edit-profile__enter-password']"),
        });
        return;
    }
    try {
        isLoading.value = true;
        if (picture.value) await $store.dispatch('user/changeProfilePicture', picture.value);
        await $store.dispatch('user/editProfile', {
            firstname: completeForm.name,
            lastname: completeForm.lastname,
            email: completeForm.email,
            phone: concatenateNumberAndCountryCode(completeForm.phone),
            document: !profile.value.document?.length ? completeForm.identityDocument : undefined,
            genre: completeForm.genre == 'OTHER' ? null : completeForm.genre,
            birthdate: completeForm.birthday,
            password: completeForm.password,
            isEditingEmail: profile.value.email !== completeForm.email,
            documentType: !profile.value.document?.length ? documentType.value.id : undefined,
        });
        picture.value = undefined;

        if (props.showNotifierSuccess) {
            notifier({
                type: 'SUCCESS',
                body: $t("txt['profile-options__updated-success']"),
            });
        }
        $emit('on-accept');
    } catch (value: any) {
        if (
            typeof value !== 'string' &&
            (value['message']?.['phone']?.length || value['message']?.['identifier']?.length)
        ) {
            Object.assign(messageError, value?.message);
            return notifier({
                type: 'INFO',
                body: value['message']['phone'] ?? value['message']['identifier'],
            });
        }
        if (value?.toLowerCase() === 'the phone sent is already used by another client') {
            isPhoneAlreadyRegistered.value = true;
            return notifier({
                type: 'INFO',
                body: $t('alerts.profile_number_exist'),
            });
        }

        notifier({
            type: 'ERROR',
            body: $t('alerts.profile_form_error'),
        });
    } finally {
        isLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import './edit-profile.scss';
</style>
