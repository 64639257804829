/// *** txt [Text] is used for any kind of text from a specific place
//// it should have a sufix of the page separated with double underscore;
// * EXAMPLE
// retailers__popular-stores
// sufix: retailers
// txt || cta : popular-stores
//
/// *** cta [Call To Action] is used for any kind of call to action btn or plain links;
/// *** general is used for names of general purpose that can be used more than once;
// No need of sufix for this case;
//
/// * For more details: https://vue-i18n-next.intlify.dev/guide/
import { i18Nbreadcrumb } from '@/vi18/i18Nbreadcrumb';
import { i18Seo } from '@/vi18/i18Seo';
import { i18nInput } from '@/vi18/i18nInput';
import { i18Alerts } from '@/vi18/i18Alerts';

export default {
    ...i18Nbreadcrumb.EN,
    ...i18Seo.EN,
    ...i18nInput.EN,
    ...i18Alerts.EN,
    txt: {
        schedule_updated: 'Updated delivery schedule.',
        deleted_card_msg:
            'Are you sure you want to delete your card? By deleting it you will not be able to access you card history.',
        cards_with_balance: 'Cards with balance',
        membership_cards: 'Membership cards',
        order: 'Order',
        credit_card_no_transactions: 'You have no expenses made with this card during this month',
        transactions: 'transactions',
        orders: 'orders',
        expense: 'expense',
        total_expense: 'Total expense',
        payments: 'payments',
        months: 'months',
        payment_facilities: 'Payment plans',
        low: 'low',
        in_stock: 'in stock',
        reference_fee:
            'Reference fees. Once you select the payment method for your order, the final value will be calculated',
        resent_payment_title: 'You are changing the payment method for the order #{order}',
        credit_card__expired_info: 'It seems that this card has expired',
        credit_card__expired: 'Expired card',
        'by-completing-your-registration':
            'By completing your registration, you allow us to update and verify your affiliate benefits.',
        'error-try-again-in-a-few-minutes': 'There was a problem processing your request, please try again later.',
        'complete-register-title': 'Complete your registration here',
        'complete-register-description': 'Affiliate Benefits!',
        'registration-pending-profile': 'Unlock exclusive benefits! Complete your Tipti registration',
        'pending-affiliation': 'Pending affiliation. Join and enjoy benefits',
        'successful-registration-access-to-benefits': 'Successful registration! Access to exclusive benefits.',
        'successful-affiliation': 'Successful membership Enjoy your benefits!',
        'renewal-pending': 'Renewal Pending',
        'successful-renovation': 'Successful renovation',
        discount_5x5_applied: 'By adding 5 units you have 5% discount on this product',
        discount_5x5_lost: 'Discount not applied, add minimum 5 units to activate benefit',
        extra_items__header_cart: 'Summary of Additions',
        address: 'Address',
        extra_items__button_cart: 'Add Products',
        extra_items__active_order: 'You have a current order',
        active_order__message: 'Add the selected products to your current order',
        add_in_order: 'Add to active order',
        create_new_cart: 'Create a new cart',
        sure_to_leave: 'Are you sure you want to go out?',
        any_product_added: 'No product will be added if you leave this screen',
        stay_here: 'Stay here',
        yes_quit: 'Yes, exit',
        added_cart: 'add-on cart',
        added_cart__mobile: 'adds',
        order_cancelled: 'Order cancelled',
        extra_items__limit_overpassed: 'You have exceeded the limit of products to add to your active order',
        extra_items_current_order: 'You are adding products to order ',
        extra_item_limit: 'Remember you can only add {limit} products to your current order',
        search_result_empty: 'Product not available in digital catalog',
        product_carry: 'Add {take} pay {pay}',
        new_on_tipti: "What's new in Tipti",
        subscription__not_selected: 'Here you will be able to see the details of the membership you choose.',
        deactivated__products: 'This item is sold out in store | These items are sold out in store',
        deactivated__products_not_found: 'Sorry, we have not found products related to your choice.',
        deactivated__products_tipti_suggestions: 'We suggest you buy',
        cambrellas_membership: 'Cambrellas Membership',
        cambrellas__subscription_experience: 'Live the Tipti Cambrellas experience',
        cambrellas__subscription_change: 'Are you ready to make the change?',
        cambrellas__subscription_legend: 'We put at your disposal cambrella-type cloth bags for all your orders.',
        cambrellas__used: 'Cambrellas used in your orders',
        subscription__legend:
            'Live the full Tipti experience and enjoy all the promotions and benefits with our memberships.',
        subscription__change: 'Are you ready to make the change?',
        subscription__get: 'Get your membership now!',
        cambrellas__not_used: "You haven't used any of your available cambrellas.",
        membership__active: 'Membership active until',
        cambrellas__used_count: 'You have used {used} of your {total} available cambrellas.',
        cambrellas__progress: 'You have used {init} out of your {finish} available cambrellas',
        cambrellas__return:
            'Please remember to return the bell sleeves to your delivery shopper. If you run out of available sleeves, you will be charged again for each unit used.',
        cambrellas__expire: 'Active membership until {date}',
        cambrellas__subscribe_success: 'Congratulations, you now have Cambrellas available for your order',
        cambrellas__subscribe_error: 'Oops, something went wrong, try again in a moment.',
        cambrellas__txt_no_subscription: 'Do you want to reduce this cost?',
        cambrellas__txt_subscription: 'Available cambrellas',
        cambrellas__subscription: 'Cambrellas subscription',
        product__delivery_scheduled: 'The delivery of the product {name}, will take approximately {hours}h.',
        product__delivery_scheduled_2:
            'Our delivery man will contact you to coordinate the delivery time on the selected day.',
        shipping_cost__info:
            'By signing up for Tipti Prime you will enjoy many /*SPECIAL BENEFITS/*, including FREE shipping.',
        service_fee__info:
            'The value of the service commission is already included in the value of the products and subtotal, here you can see the breakdown in detail.',
        shipping_cost__want_free: 'Want /*FREE SHIPPING/*?',
        configurable__delivery: 'This product has an approximate delivery time of {hours} hours.',
        maxi_dollars__info_moves: 'Here will be show only movements made',
        maxi_dollars__inside_tipti: 'within Tipti',
        maxi_dollars__cards: 'on your cards with the ',
        maxi__dollars__benefit: 'MaxiDólares benefit',
        maxi_dollars__error: 'Your available MaxiDólares are $ {amount}, you cannot transfer more than this amount.',
        maxi_dollars__converter: 'Enter the MaxiDólares you wish to convert to balance on your Tipti Card.',
        maxi_dollars__title: 'Available cards',
        maxi_dollars__register_card: 'If you wish to add a card, please go to payment methods or register',
        maxi_dollars: 'MaxiDólares',
        no_maxi_cards: 'You do not have any MaxiDollars card available.',
        maxi_dollars__history_title: 'MaxiDólares Redemption History',
        maxi_dollars__amount_text: 'MaxiDólares available',
        redeemed_points: 'Redeemed points',
        available_points: 'Available points',
        available_points_error:
            'Your available points is {amount}, you cannot transfer one amount greater than indicated',
        transfer_to_tipti: 'Transfer to my Tipti Card',
        transfer_balance: 'Transfer Balance to Tipti card',
        unlink_loyalty: '',
        see_more_benefits: 'See more benefits',
        see_less_benefits: 'See less benefits',
        available_programs: 'Affiliate program available',
        available_rewards: 'Reward program available',
        affiliates_and_rewards: 'Affiliates and rewards',
        show_card__affiliates: 'Affiliated virtual card',
        loyalty_program: 'Affiliate programs',
        loyalty_program__applied: 'Successfully applied {program}',
        redemption_history: 'Redemption history {program}',
        transfer_applied: '{program} successfully transferred',
        'profile-options__loyalty': 'Loyalty',
        'profile-options__loyalty_ec': 'Rewards card',
        'profile-options__loyalty_pa': 'Punto de oro',
        billing__required_data: '*Required fields',
        billing__document_type: 'Document type*',
        'breadcrumb__extra-items-payment': 'Extra items payment',
        'breadcrumb__extra-items-resume': 'Extra items resume',
        'breadcrumb__maxi-cards': 'MaxiDólares',
        'breadcrumb__maxi-dollars': 'Tarjetas',
        'breadcrumb__maxi-card': 'Tarjeta',
        'breadcrumb__resend-payment': 'Resend payment',
        breadcrumb__subscription: 'Subscriptions',
        goal__gift_product: 'Remember: this product will be added automatically after checkout',
        'pickup__where-do-you': 'Where do you want to pickup your order?',
        'pickup__choose-the-point': 'Choose the pickup point',
        'pickup__choose-the-store': 'Choose the store where you want to pickup your order',
        'pickup__create-your-order': 'Make your order',
        'pickup__choose-your-products': 'Choose your favorite products and the time to go for them',
        'pickup__go-for-your': 'Pick up your order',
        'pickup__take-your-order': 'Pick up your purchases at the selected time',
        'pickup__do-your-groceries': 'Place your order and pickup your groceries at the store',
        pickup__not_avalilable_cash:
            'At the moment we do not have Cash Payment in Pickup, please change the payment method to continue.',
        'credit-card__should-validate': 'Verify card to continue',
        'credit-card__additionally': 'Additionally, the',
        'credit-card__contact-you': 'will contact you.',
        'credit-card__validate': 'Validate your card',
        'credit-card__validate-benefits': 'Benefits of validation',
        'credit-card__validate-failed': 'Verification failed',
        'credit-card__amount': 'This amount will be',
        'credit-card__reimbursed': ' reimbursed',
        'credit-card__last-attempt':
            'Remember that it is your last attempt, if the validation is not correct, your orders with this card will be deactivated',
        'credit-card__automatically': ' automatically.',
        'credit-card__collection-date': 'Collection made: Date {date}',
        'credit-card__benefits': 'What are the benefits of verifying my cards?',
        'credit-card__benefit-message':
            'By having a verified payment method you can make your payments quickly and safely',
        'credit-card__verification-success': 'Successfully verified card',
        'credit-card__verification-attempt': 'attempt | attempts',
        'credit-card__verification-attempts': 'You have a maximum of {times} {attempts} to verify the card.',
        'credit-card__verification-error':
            'A problem has occurred and could not be verified, please try again in a few minutes',
        'credit-card__random-value': 'We will make a minimum charge to your card, for a random value in the name of',
        'credit-card__enter-amount': 'Enter the amount charged in your payment method',
        'credit-card__discount-amount':
            'We will charge you a random amount of up to {currency}{amount} to verify that the card is valid.',
        credit_card__card_expired: 'Expired card',
        credit_card__card_expired_messaje:
            'Your card has expired, to continue you can edit the data or change the payment method',
        credit_card__remember_expired: 'Remember that the expired card will be deactivated',
        'deactivate-account__comment-condition': 'To leave a comment you have to select O',
        'deactivate-account__expectations': "That's not what I expected",
        'deactivate-account__shopping-problems': 'I have had problems with my orders',
        'deactivate-account__why-deactivate': 'Tell us why do you want to deactivate your account?',
        'deactivate-account__want-deactivate': 'Do you want to deactivate your account?',
        'deactivate-account__we-sorry': "We're sorry you´re leaving us. Remember that when you",
        'deactivate-account__deactivate-acc': 'deactivate your account,',
        'deactivate-account__all': 'all the',
        'deactivate-account__data': ' data',
        'deactivate-account__saved': ' saved in Tipti',
        'deactivate-account__lose': 'will be lost',
        'deactivate-account__not-recovery': 'and cannot be recovered. You',
        'deactivate-account__also': 'will also not be able',
        'deactivate-account__create': 'to create a',
        'deactivate-account__new-acc': 'new account',
        'deactivate-account__same-credentials': 'with your same credentials. Are you ',
        'deactivate-account__sure': 'sure',
        'deactivate-account__you-want': 'you want to',
        'deactivate-account__deactivate': ' deactivate it?',
        'deactivate-account__account-deactivated': 'Your account has been successfully deactivated',
        'page-title__my-account': 'My account',
        'page-title__my-account-edit-profile': 'Edit my profile',
        'page-title__my-account-loyalty_program': 'Affiliates and rewards',
        'page-title__my-account-my-orders': 'My orders',
        'page-title__my-account-my-order-show': 'Order #{order_id}',
        'page-title__banner-categories': '{title}',
        'page-title__banner-categories-show-category': '{name}',
        'page-title__my-account-statistics': 'Statistics',
        'tipti-prime__no_content': 'Available memberships not found. Please, try again later',
        'tipti-prime__buy-success': 'Congratulations! Now you are Prime',
        'tipti-card__before': 'Before finishing...',
        'tipti-card__no-reject': 'No, update data',
        'tipti-card__convert-maxi': 'Convert MaxiDólares',
        'tipti-card__yes-confirm': 'Yes, confirm transfer',
        'tipti-card__wrong-data': 'Incorrect Data',
        'tipti-card__no-beneficiary': "There are no users with this Tipti Card's number {tiptiCardNumber}",
        'tipti-card__beneficiary-name': 'Beneficiary name:',
        'tipti-card__tipti-card-number': "Tipti Card's number:",
        'tipti-card__is-right': 'Is this information correct?',
        'tipti-card__check-your-data': "Please check the details of the beneficiary that you're transfering",
        'retailer-stats__out-of-coverage': 'Out of cover',
        'retailer-stats__sector-coverage': 'This store is not available in your current location',
        'giftcard__share-code-link':
            '💵 💵 I give you ${value} for your Tipti Card with this code  💵 💵 Your code is: {code}',
        'share-data__terms-and-conditions': 'https://tipti.market/terms_share_data_en.pdf',
        'my-lists__no-lists': "You don't have any list created",
        'minimum-amount__are-you-sure': 'Are you sure to delete this product?',
        'minimum-amount__there-is': 'There is a minimum purchase amount {currency} {amount} to purchase on this store.',
        'minimum-amount__if-deleted':
            'Once deleted it. You will have to add another item until the required minimum amount is met',
        'minimum-amount__this-store-amount':
            'This store does not meet the minimum purchase amount required to continue.',
        'minimum-amount__at-least': 'At least one of our stores should meet the minimum amount required to continue.',
        'minimum-amount__this-store': 'This store has a minimum purchase amount of',
        'minimum-amount__add': 'Add',
        'minimum-amount__to-process': 'to process your order.',
        'surplus__your-shopper': 'Your shopper is requesting the pay of',
        'surplus__order-of': 'of the order',
        'surplus__to-charge-from': 'to charge to you',
        'institutional__not-meet':
            'Your institutional payment is unlimited. Therefore, it is not necessary to use your Tipti Card balance. Your order will be paid in full with the institution.',
        'institutional__not-enough': "You don't have available balance with this payment method",
        'institutional__this-institution': 'This institution is not available. Select another payment method',
        'resume__elder-terms-and-conditions':
            'By selecting the option "This order is for older adults " the user will agree to the following policies: For changes or doubts about the products that the user purchased, the Tipti Shopper will contact the person who placed the order In the first instance.',
        'resume__elder-information':
            'Check this option, if your order is for an elder person (older than 65 years). this way we can register it, and your order will have a preferencial attention.',
        'wire-transfer__select-your-bank': 'Select your bank',
        'cash__only-equal': 'The amount should be equal or higher than {currency} {amount}',
        'cash__not-allowed': 'Payment with cash not available',
        'cash__over-limit': 'Your order exceeds the maximum amount for payment with cash',
        'credit-card__not-allowed': 'Payment with cards not available',
        'credit-card-deferred__not-allowed': 'Deferred Payment with cards not available',
        'credit-card__under-limit': 'To make a payment using a card, the minimum amount is {currency} 1.00',
        'credit-card-deferred__under-limit':
            'To make a deferred payment using a card, the minimum amount is {currency} {amount}',
        'credit-card__over-limit': 'The maximum amount of payment with card is {currency} {amount}',
        'payphone__not-allowed': 'PayPhone not available',
        'payphone__under-limit': 'To make a payment using PayPhone, the minimum amount is {currency} 1.00',
        'payphone__over-limit': 'The maximum amount of payment with Payphone is {currency} {amount}',
        'institutional__not-allowed': 'Institutional payment not available',
        'institutional__not-available': 'There are no institutions with a coupon available at this time.',
        'online-bank-sevices__avalible-platform': 'Available platforms',
        'online-bank-sevices__proccess-pyment-advisor':
            'Once your order has been entered, you must process the payment on {bank_name} portal using the order number.',
        'online-bank-sevices__modify-schedule-msj':
            'When the payment will be verified, the delivery time will be adjusted if necessary.',
        'online-bank-services__not-allowed': 'Payment for online services not available',
        'online-bank-services__not-available':
            'Currently the payment method for online services is not available for mixed payments with institutions',
        'online-bank-services__not-loading':
            'Oops, an error occurred while loading the entities financial resources available. Please try again or try with another payment method.',
        'online-bank-services__reloading': 'Reload',
        online_bank__service_payments: 'Services payment',
        online_bank__steps: 'To make payments for services online you must perform the following steps:',
        'nequi__not-available-title': 'Nequi payment not available',
        'nequi__not-available-message': 'Institutional and Nequi mixed payment is not available at the moment',
        'nequi__over-limit': 'The maximum Nequi payment amount is {currency} {amount}',
        'nequi__under-limit': 'To make a payment using Nequi, the minimum amount is {currency} 1.00',
        default_payment: 'Default payment',
        'snackbar__product-added': '{product} has been added to your cart correctly',
        'codes__no-nodes-yet': 'Not coupons yet, try redeeming one',
        'transfer__once-your-order':
            'Once you have entered your order we will validate your wire transfer and we could adjust your delivery schedule if needed.',

        'purchase__your-order-delivered':
            'Your order of {retailer} will be delivered {date} between {firstHour} and {secondHour}',
        'purchase__your-order-rejected':
            'Your order payment for {retailer} has been rejected. Change your payment method and try it again.',
        'purchase__order-accepted': 'Order accepted',
        'purchase__payment-rejected': 'Payment rejected',
        'purchase__hours-saved-hour': '{hours} HOUR',
        'purchase__total-saved-had': 'you have',
        'purchase__total-saved-saved': 'SAVED',
        'purchase__retailer-schedule-to': 'to',
        'resume__instructions-delivery': 'Delivery instructions',
        'resume__contact-delivery': 'Add instructions and an additional contact for delivery.',
        resume__delivery_title: 'Enter delivery instructions to tell us what you need to clarify.',
        resume__delivery_contact_additional: 'Additional contact for delivery:',
        resume__delivery_description: 'Add a backup contact.',
        resume__delivery_placeholder: "Leave your purchases at the guard's booth...",
        'resume__are-you-elder-people': 'Is this order for Elder people?',
        'resume__automatic-replacements-requested': 'Automatic Replacements?',
        'resume__alert-replacements-title': 'Automatic Replacements',
        'resume__alert-replacements-description':
            'Your shopper will automatically replace the products out of stock, if there is a surplus, it will send you a request for your approval',
        'resume__alert-replacements-information':
            "Check this option, to automatically accept the replacements our gastronomist shoppers will take in case they don't find some of the products you requested.",
        'resume__permissions-content':
            'To improve your buying experience in {retailerName}, remember that with your authorization, we can provide to the store your document number while shopping',
        'checkout__complete-purchase': 'Complete purchase',
        'note__edit-note': 'Edit note',
        'note__save-note': 'Save note',
        'note__add-photo-as-note':
            'To attach an image inside your "Note to the Shopper" you must describe at least one word',
        'note__instructions-shopper':
            'Leave instructions to your shopper in order to he bring the product like you need',
        my_order__what_do_you: 'What do you want to do with your order?',
        my_order__need_help_with: 'Hello, I need help with my order ({orderId})',
        'my-order__i-want-to': 'I want to place an order',
        'my-order__no-orders':
            'You do not have active orders at the moment, you can check your order history on the left side or start a new order.',
        'my-order__shopper-is': 'Your shopper is a food gathering expert. He will choose the best products for you',
        'my-order__forgot-something': 'Did you forget something in your order ?',
        'my-order__in-destiny': 'Shopper at destination',
        'my-order__shopper-in-destiny': 'Your delivery shopper is already at your delivery place.',
        'my-order__wire-transfer': 'Validating wire transfer',
        'my-order__validating-wire-transfer':
            'We are validating your orders payment, this may take a few minutes. Once validated, the delivery schedule will be adjusted if necessary',
        'my-order__waiting-replacement': 'Waiting replaced items confirmation',
        'my-order__waiting-confirmation': 'Your shopper will recommend replacements for your out of stock products.',
        'my-order__waiting-shooper': 'Order to collect',
        'my-order__your-order-waiting':
            'Your order is ready, soon a delivery shopper will pick it up to take it in perfect conditions.',
        'my-order__forget-to-comment':
            'Your order was delivered, don\t forget to evaluate your experience and let us know how was your order.',
        'my-order__delivered-and-rated': 'Order delivered and rated',
        'my-order__thanks-for-use': 'Thank you for trusting Tipti, we are always at your service.',
        'my-order__you-cancelled':
            'You cancelled your order, if it was a mistake please contact our Care Team and we will help you as soon as possible',
        'my-order__order-delivered': 'Order delivered',
        'my-order__delivery-will-hand': 'Your shopper will deliver your order in perfect state.',
        'my-order__shopper-delivering': 'Shopper on the way',
        'my-order__your-shopper-will-arrive': 'Your shopper will arrive soon with your order.',
        'my-order__purchase-finished': 'Purchase finished in the store',
        'my-order__your-shopper-finish': 'Your shopper has finished purchasing all the items in your order.',
        'my-order__shopper-buying': 'Advisor shopping',
        'my-order__your-shopper-picking': 'Your shopper is choosing fresh and quality products, just as you would.',
        'my-order__groceries-advisor-assigned': 'Groceries advisor assigned',
        'my-order__in-a-moment':
            'In a moment your shopper will begin to prepare your order and select the best products for you.',
        'my-order__order-accepted': 'Order accepted',
        'my-order__thank-for-trust':
            'Thank you for trusting Tipti, soon a shopper will prepare your order, selecting the best products for you.',
        'my-order__payment-rejected': 'Payment rejected',
        'my-order__payment-rejected-please':
            'Your orders payment has been rejected, please contact our Care Team or try payment another method ',
        'my-order__payment-in-progress': 'Payment in progress',
        'my-order__we-are-processing':
            'We are processing your orders payment, this may take a few minutes. Thank you for trusting Tipti. ',
        'my-order__extra-items-payment': 'Extra items payment in process',
        'my-order__we-are-processing-extra':
            'We are processing the payment for your added products, this may take a few minutes. Thank you for trusting Tipti.',
        'my-order__initial-cart': 'Initial cart',
        'my-order__final-cart': 'final cart',
        'my-order__products-bought': 'Items bought',
        'my-order__products-not-bought': 'Items not bought',
        'my-order__products-replaced': 'Items replaced',
        'my-order__tipti-items': 'Tipti items',
        'my-orders__no-orders-yet': 'No orders yet',
        'my-orders__it-looks-like':
            "It looks like you don't have orders yet. Buy something to see data reflected in this section.",
        'nutritional-information': 'Nutritional information',
        'schedules__this-retailer-dont': 'This retailer do not have schedule available for you delivery sector',
        'credit-card__dont-have': "It looks like you don't have any credit/debit card register in Tipti yet.",
        'credit-card__as-on-card': 'Name and surname',
        'credit-card__owner': 'Name of the card owner',
        'credit-card__alias': 'Alias',
        'credit-card__code': 'cvv',
        'credit-card__alias_placeholder': 'Name tu identify your card',
        'credit-card__phone-number': "owner's mobile number",
        'credit-card__card-number': 'Card number',
        'credit-card__expired-date': 'Expiration date',
        'credit-card__add-card': 'Register card',
        'credit-card__once-you-pay':
            'Once your card is entered, a charge will be made for its validation of no more than {amount} that will be automatically reimbursed once your card has been validated.',
        'credit-card__accepted-cards-to':
            'To make the payment with your cards, please make sure you have them enabled in your bank for online purchases. We accept {cards} cards from all issuing banks (Debit cards accepted according to the permissions of your bank)',
        'credit-card__new-card': 'New Credit/Debit Card',
        'credit-card__cards-choosed': 'Choose your card',
        'credit-card__accepted-cards': 'Accepted credit/debit cards',
        'bills__choose-your-bills': 'Choose your billing data',
        'bills__billing-as-company': 'Invoice as company',
        'product-modal__how-would-you': 'How would you like to purchase your product?',
        product_modal__buying_legend: 'The product will be added by {type} to your cart.',
        'product-modal__select-a-suggestion':
            'Please select a replacement suggestion for your Groceries Advisor in case the product you are buying is out of stock in the establishment',
        'shared-cart__cart-name': 'Cart name',
        'shared-cart__create-cart': 'Create cart',
        'shared-cart__delete-cart': 'Delete cart',
        'shared-cart__name-and-id': "* Cart's name and at least one user's ID are required",
        'shared-cart__add-a-friend': 'Add a friend to your shared cart',
        'shared-cart__add-its-id': "Enter the user's Tipti ID you want to include",
        'shared-cart__only-numbers': "* User's ID should contain only numbers",
        'shared-cart__participants': "Cart's participants",
        'shared-cart__id-of-user': "User's ID",
        'shared-cart__new-cart': 'New shared cart',
        'billings__no-content-title': 'No bill data registered',
        'billings__no-content-description': 'Looks like you still do not have any bill data registered',
        'banners__no-content-title': 'Promotion without products',
        'banners__no-content-description': 'It seems there are no products for this promotion',
        'notifications__no-content-title': 'No Pending payments',
        'notifications__no-content-description': 'It seems you dont have pending payments notifications',
        'notifications__pending-processed-success': 'Your pending payment was received successfully',
        'notifications__pending-processed-failure': 'Error, we couldnt receive your pending payment',
        'cart__your-cart-empty': 'Your cart is empty',
        'cart__try-adding-some': 'Your shopping cart is empty. Try adding some products',
        'cart__subtotal-fee': 'SUBTOTAL FEE',
        cart__subtotal: 'Subtotal',
        'cart__sales-tax': 'SALES TAX',
        'cart__commission-for-service': 'Check details for service commission ',
        'cart__service-fee': 'Service Fee',
        'cart__service-fee-description':
            "The value for service fee is already included within the product's price and subtotal, here you can see the details.",
        'cart__hide-details': 'hide details',
        'cart__discount-applied': 'DISCOUNT APPLIED',
        'cart__savings-total': 'Total savings',
        'cart__shipping-cost': 'Shipping cost',
        'cart__pickup-cost': 'Pickup cost',
        'cart__are-you-sure-empty': '¿Are you sure you want to empty your cart?',
        'cart__tax-included': 'Tax included',
        'cart__complete-your-register-title': 'Complete your register',
        'cart__complete-your-register-alert': 'Thank you for your registration!',
        'cart__complete-your-register-description':
            'Please, help us to complete your profile for a personalized attention.',
        'cart__our-shopper-will-assist': ' experience, then our shopper will assist you step by step in your order',
        'cart__list-added-successfully': 'Products added to lists successfully',
        product_list__added: 'Product added to list: "{name}"',
        'cart__remember-that':
            'Remember the delivery fee can be updated after you schedule your delivery and verify the address. The discount will be included depending on the day of delivery.',
        'cart__total-order': 'Total order',
        'cart__no-cambrellas-title': 'No Shopping bags',
        'cart__no-cambrellas-description': 'At the moment, there are not available shopping bags',
        'dropdown__select-your-city': 'Select your city',
        'product-no-found__we-will-find': 'We have more than 200 thousand products available!',
        'product-no-found__select': 'We are sure to find something similar to what you are looking for.',
        'product-no-found__report-a-product': 'REPORT THE PRODUCT YOU WERE LOOKING FOR',
        'become-retailer__what-does-tipti': 'What does tipti contribute',
        'become-retailer__with-your-bussiness': 'to your bussiness?',
        'become-retailer__increase-customer-base': 'Increase customer base',
        'become-retailer__home-penetration': 'Penetration in Home Market',
        'become-retailer__increase-purchase-loyalty': 'Increase purchase loyalty',
        'become-retailer__increase-purchase-frequency': 'Increase purchase frequency',
        'become-retailer__average-ticket-increase': 'Average ticket increase',
        'become-retailer__average-ticket-profitability': 'Average ticket profitability',
        'become-retailer__brand-adoption': 'Brand / Portfolio Adoption',
        'become-retailer__brand-preference': 'Brand / Portfolio Preference',
        'become-retailer__brand-benefits': 'Brand / Portfolio Benefits Education',
        'become-retailer__approval-and-contribution': 'Approval and Contribution of Opinion Leaders',
        'become-retailer__effective-communication': 'Effective communication scope',
        'landing-tipti__select-your-country': 'Select your country to continue',
        'how-works': 'How works?',
        'how-works__order-online': 'Order online',
        'how-works__our-platform':
            'Our digital platform allows you to order the shopping list of your groceries and household items from your mobile device.Our digital platform allows you to order the shopping list of your groceries and household items from your mobile device.',
        'how-works__shopper-advisors': 'Groceries advisors',
        'how-works__we-take-your':
            'We receive the orders and send them to our network of shoppers who select and buy the products of your order and then deliver them to your location.',
        'how-works__women-and-men':
            'Women and men who are experts in shopping selection. Pleasant in the treatment and professional buying. They will become your best allies.',
        'how-works__become-shopper': 'Become shopper',
        'how-works__become-retailer': 'Become retailer',
        'how-works__work-with-us': 'Work with us',
        'slogan__online-and': 'ONLINE',
        'slogan__supermarket-and': 'SUPERMARKETS AND',
        'slogan__home-essenials': 'HOME ESSENTIALS STORES',
        'footer-main__follow-us': 'Follow us',
        'header-guess__already-has-account': 'Already has account?',
        'search__results-for': 'Results for',
        'search__products-find-in': 'Products found in',
        'notifications__tipti-news': 'Tipti News',
        'notifications__have-pending-payments': 'You have a pending payment!',
        'notifications__change-method': 'Change payment method',
        'referred__share-code': 'Share code',
        'referred__win-money': 'Win',
        'referred__every-referred': 'with every referred',
        'referred__send-this-code':
            'Send this code to your friends or have them scan it and earn {currency} {amountGift} for each one once they have made their first purchase over {currency} {amountMin}',
        'referred__get-in': 'Get',
        'referred__with-every-friend': 'with every friend that you invite to try our service from',
        'referred__pending-to-claim': 'Pending',
        'about-us__we-are': 'We are ',
        'about-us__is-an-ecomerce':
            "an e-Commerce company oriented to supermarket's sales, specialized stores and home delivery through digital platforms (App or web). Our purpose is to connect a team of specialized buyers with users/consumers who are trying to get their groceries done.",
        'about-us__in': 'In ',
        'about-us__is-providing-best':
            'we take care of our clients. Thus, they can have more time with their families and themselves, while ensuring that their purchases will arrive home safely.',
        'about-us__time-for-you': 'TIME FOR YOU',
        'address__select-right-location': 'Select a valid address to display on map',
        'address-user__delivery-sector': 'Delivery Address',
        'address-user__saved-addresses': 'Saved Addresses',
        'address-user__review-address': 'Review your delivery address',
        'address-user__reference': 'Reference',
        'address-user__alias': 'Alias direction',
        'address-user__select-delivery-sector': 'Select your delivery sector',
        'address-user__most-nearest': 'the nearest to it',
        'address__new-address': 'New Address',
        'address-modal__change-address': 'Change address',
        'coverage-sector__coverage-sector': 'COVERAGE SECTORS',
        'coverage-sector__verify-sector': 'VERIFY YOUR SECTOR',
        'coverage-sector__or-select-location': 'Or select your location in the map',
        'edit-profile__edit-my-profile': 'Edit my profile',
        'edit-profile__to-change-email': 'To change the email you need to help us with your password',
        'edit-profile__enter-new-password': 'Enter your new password',
        'edit-profile__confirm-new-password': 'Confirm your new password',
        'edit-profile__enter-old-password': 'Enter your old password',
        'edit-profile__enter-password': 'Enter your password to continue!',
        'edit-profile__enter-your-new': 'Enter your new ',
        'edit-profile__enter-all-data': 'Please enter all the fields to continue',
        'edit-profile__remember-that-need': 'remember it need',
        'edit-profile__between-eight-sixteen': 'to have between 8 and 16 alphanumeric digits',
        'edit-profile__password-success-change': 'Your password has been successfully updated!',
        'edit-profile__password-error-change': 'An error occurred while updating your password, try again..',
        'edit-profile__password-no-match': 'Passwords do not match, please try again..',
        'how-it-works__online-order': 'Online orders',
        'how-it-works__our-digital-platform':
            'Our digital platform allows you to order from your mobile device the shopping list of your groceries and household items.',
        'how-it-works__supermarket-assistants': 'Supermarket assistants',
        'how-it-works__get-orders':
            'We receive the orders and send them to our shoppers who select and buy the products of your order and then deliver them to your location.',
        'how-it-works__experts-men-women':
            'Women and men who are experts in shopping selection. Pleasant in the treatment and professional buying. They will become your best allies.',
        'how-it-works__establishment': 'Establishment',
        'how-it-works__work-with-establishment':
            'We work with the same establishments in which the consumer makes his purchase. The purchase model allows us to acquire specialized, fresh, organic products or of your choice and preference.',
        'my-orders__current-orders': 'Current Order',
        'my-orders__orders-history': 'Orders history',
        'my-orders__contact-with-us': 'Contact with our Tipti Care Team',
        'my-orders__filter-orders': 'Filter orders',
        'summary-orders__delivery-hour': 'DELIVERY TIME',
        'summary-orders__delivery-place': 'DELIVERY PLACE',
        'summary-orders__delivery': 'Delivery',
        'summary-orders__information-delivery-payment': 'Info about delivery and payment',
        'summary-orders__get-history-order': 'get history orders',
        'summary-orders__get-active-orders': 'get active orders',
        'summary-orders__filter-orders': 'filter orders',
        'summary-orders__filter-option': 'Filter options',
        'summary-orders__filter-by-date': 'filter with the date provider',
        'login__forget-your-password': 'Forget your password?',
        'login__still-not-account': "You don't have an account?",
        'login__user-password-incorrect': '*User o password incorrect',
        'login__enter-your-email': "*Don't forget to enter your email",
        'login__enter-you-password': "*Don't forget to enter your password",
        'login-attempt__account-deactivated': '*Your account has been deactivated',
        'login__try-login': 'login',
        'login__account-deactivated':
            'You are trying to enter with an email from a deactivated account, please enter a new one and try again',
        'login__alert-by-the-present': 'By the present, I accept the ',
        'login__alert-authorize-tipti': ' and I authorize ',
        'login__alert-to-use-my-data':
            ' to save, process, and/or use my personal data, for undefined time, to send me all kind of information about offers, promotions, new stores opening, data about the company, and in general, any information that ',
        'login__alert-may-consider': ' may consider of interest.',
        'login__accept-terms-conditions': 'I accept the terms and conditions',
        'main-products__discount-products': 'Products on sale',
        'main-products__your-products': 'Your Products',
        'main-products__recommended_for_you': 'Recommended for you',
        'main-products__predictions': "{user} it's time to buy",
        'main-products__new-products': 'New products',
        'register__register-register': 'Sign up',
        'register__do-not-forget': "*Don't forget to enter your name",
        'register__enter-name-lastname': '*Enter name and lastname',
        'register__already-have-account': 'Already have an account?',
        'register__try-create-account': 'try to create the account',
        'news__we-are-building':
            'We are building the best way for people anywhere in the country to buy food. Every day, we solve all kinds of problems to create an experience for our clients that is absolutely magical. On this page you can find our logos with their different applications available for download and our most relevant news in the media and social networks.',
        'news__linkedin-news': 'News on LinkedIn',
        'news__tag-our-deliveries': 'Tag out deliveries, we always repost our happy clients!',
        'news__know-about-our-news': 'Find out about our most relevant news and achievements in our LinkedIn network',
        'news__new-isotipe': 'Isotipe',
        'news__download-png': 'Download PNG',
        'news__new-note': 'Note',
        'news__all-the-zip':
            'All ZIPs contain versions in CMYK and RGB mode aligned to the pantones, both adapted to light and dark backgrounds.',

        'payment-methods__digital-alternative':
            'The digital alternative to your cash. Top up your Tipti Card and use it as a payment method.',
        'payment-methods__pay-your-orders':
            'Pay for your purchases of up to {currency} {maxAmount} with your cards registered in Tipti.',
        'payment-methods__payment-method-valid-prime': 'Payment method valid for institutional accounts.',
        'payment-methods__download-payphone-app':
            'Download the Payphone App and enter your cards and select it as the payment method in Tipti.',
        'payment-methods-cash-description':
            'You can pay cash for your purchases of a maximum amount of {currency} {cashAmount}.',
        'payment-methods__cash-payment-title': 'Cash Payment',
        'payment-methods__cash-payment-description': 'Option for online cash payment of any value, only web-enabled.',
        'profile__get-the-user': 'get the user information',
        profile_shared_data__share_id_with: 'Share identification document with',
        profile_shared_data__no_content: 'You are not sharing your personal data with any store',
        'profile-shared-data__alert':
            'It authorize our shoppers to give the store your document ID number at the moment of payment, so they can send you personalized offers and improve your shopping experience',
        profile_shared_data__affiliate_modal_title: 'Affiliate Programs',
        profile_shared_data__affiliate_modal_paragraph_1:
            'Authorize Tipti shoppers to enter your ID number at the time of payment in the retail store so that the supermarket can send you personalized offers and improve your shopping experience.',
        profile_shared_data__affiliate_modal_paragraph_2:
            'For those who are not affiliated, by accepting the documents (T&C, Affiliation Agreement, Affiliation Regulations, Privacy Policy) you will be affiliated to Supermaxi and Megamaxi.',
        'profile-options__orders': 'ORDERS',
        'profile-options__edit-profile': 'Edit my profile',
        'profile-options__delivery-address': 'Delivery address',
        'profile-options__card-register': 'Card registered',
        'profile-options__share-data': 'Affiliate Programs',
        'profile-options__share-data-activated': 'Loyalty program activated',
        'profile-options__updated-success': 'The data of your profile has been updated successfully!',
        'profile-bills__no-content': 'Your bills are not available',
        'product-modal__complete-your-order': 'Complete your order',
        'product-modal__replace-suggestion': 'Replacement suggestion',
        'product-modal__send-note': 'Send note to shopper',
        'product-modal__how-you-buy': 'HOW YOU WANT TO BUY YOUR PRODUCTS TODAY?',
        'product-modal__please-select-product': 'Please, select a product to continue',
        'product-modal__name-of-list': 'Ex: Name of the list',
        'product-modal__txt-action-note': 'Note to Shopper',
        'product-modal__txt-action-note-short': 'Note',
        'product-modal__txt-add-list': 'Add to List',
        'product-modal__txt-add-list-short': 'Lists',
        new_list: 'New list',
        'product-modal__txt-add-favorite': 'Add to Favorites',
        'product-modal__txt-add-favorite-short': 'Favorites',
        'payment-methods__cash-payment': 'Cash Payment',
        'payment-methods__option-cash-payment': 'Option for online cash payment of any value, only web-enabled.',
        'payment-methods__pending': 'There is a pending payment, please add another payment method',
        'mixed-payment-messages-not-deferred__title': 'By selecting two payment methods you can not differ',
        'mixed-payment-messages-not__deferred':
            'Remember that by selecting two payment methods you will not be able to differ your purchase.',
        'payment-methods__change-payment': 'Change payment method',
        'payment-methods__change-payment-description': 'Are you sure to change all the selected mayment methods',
        'retailer-promotions__delivery': 'Delivery hours',
        'retailers__no-retailer-filtered': 'here are no retailers with that category, Try another one.',
        retailers__no_retailers:
            'Sorry, we will soon have stores available for you in this sector. Please enter another address within our coverage areas.',
        'retailers__popular-stores': 'Featured stores',
        'retailers__popular-view-all': 'View all',
        'retailer__next-delivery': 'Delivery',
        'retailers__orders-express': 'Orders express',
        'retailers__reception-same-day': 'Orders taken to deliver the same day',
        'retailers__other-stores': 'Other stores',
        'retailers__delivery-schedule-can-change':
            'Hours may vary according to special dates or events that make logistics difficult in the delivery of orders.',
        'retailers__repeat-last-order': 'Repeat last order',
        'setting__settings-language': 'Language',
        'setting__want-receive-notifications': 'Do you want to receive notifications about our current promotions?',
        'setting__want-receive-mails': 'Do you want to receive emails about our current promotions?',
        'setting__update-language-success': 'The language have been updated successfully!',
        'setting__update-language': 'update the language',
        'setting__want-close-session': 'WANT TO CLOSE SESSION?',
        'setting__want-delete-account': 'WANT TO DELETE YOUR ACCOUNT?',
        'setting__we-are-sorry':
            'We are sorry you left us. Remember that when you delete your account all the data saved in Tipti will be lost and cannot be recovered.',
        setting__title: 'Settings',
        setting__language_message: 'You can choose the main language of the App',
        setting__language_change: 'Change language',
        setting__terms_conditions: 'Terms and Conditions',
        subcategories__sub_categories: 'Subcategories',
        subcategories__features_products: 'Featured products',
        subcategories__all_products: 'All products',
        subcategories__no_content: 'A problem has occurred, please try again.',
        subcategories__reload_page: 'Reload page',
        'filter-options__bigger-price': 'Bigger price',
        'filter-options__lower-price': 'Lower price',
        'filter-options__lower-than': 'Lower than',
        'filter-options__higher-than': 'Higher than',
        'search-autocomplete__results-for': 'Results for the search of:',
        'list__list-name': 'List name',
        'list__delete-card': 'The card could not be deleted. Try again',
        'list__delete-card-success': 'The card was deleted successfully',
        'list__add-list': 'Add list',
        'list__list-schedule': 'Schedule',
        'list__edit-schedule': 'Edit Schedule',
        'list__list-saved': 'Saved Lists',
        'list__items-quantity': 'Empty list | one item | {count} items',
        'list__list-shopping': 'Shopping lists',
        'list__list-empty-products-msg': 'Apparently you have no products in this list',
        'list__create-list-success': 'Your list has been created successfully',
        'list__create-list-failed': 'The list could not be created',
        'list__delete-list-success': 'Your list has been successfully deleted',
        'list__add-list-success': 'List added to cart',
        'list__cancel-list-failed': 'Your list could not be canceled',
        'list__cancel-list-success': 'Your list has been successfully canceled',
        'list__delete-list-failed': 'Your list could not be deleted',
        'list__no-name': '* Do not forget to enter the name of the list',
        'list__products-added': 'Your products have been successfully added to your cart',
        'list__some-products-added': 'Some products have been added to your cart',
        'list__list-schedule-subtitle':
            'Choose the day and the frequency with which you want your list of products to be added to the cart automatically.',
        'list__list-schedule-edit-day': 'EDIT YOUR DELIVERY DAY',
        'list__list-schedule-edit-frequency': 'ORDER FREQUENCY',
        'lists__freq-weekly': 'Weekly',
        'lists__freq-peer-week': 'Each  {count}   weeks',
        'lists__freq-monthly': 'Monthly',
        'lists__shared-list': 'Shared list',
        'lists__add-list-success': 'Your products have been added to your lists',
        'lists__add-list-failure': 'Your products have not been added to your lists',
        'list__not-add-to-card': 'Could not add to your cart',
        'form-send-success': 'Form sent successfully!',
        'form-send-failed': 'Error submitting form',
        recipe__share:
            '{user} has shared the {recipe_name} recipe with you! 🍴👨🏻‍🍳😋 Click the link to see it in Tipti 🧡',
        'recipe__ingredients-quantity': 'No ingredients | ingredient | ingredients',
        'recipe-rate': 'Rate this recipe',
        'recipe__no-by-sector': 'This recipe is not available in your sector. Find more recipes in "My Lists"',
        'recipe__search-input-placeholder': 'Search products',
        'prime__accept-terms': 'By activating accept the ',
        'prime__accept-terms-final': 'of TIPTI PRIME, you can cancel your subscription at any',
        'prime__plan-header': 'The perfect plan to save',
        'prime__plan-subheader':
            'With Tipti Prime you save an average of {currency} {amount} per month, you can cancel it at any time',
        'prime__benefits-delivery': 'Unlimited free shipping',
        'prime__benefits-sampling': 'Sampling on demand',
        'prime__benefits-day': 'Prime Day (Exclusive offer for Prime)',
        'prime__benefits-offerts': 'Special promotions',
        'prime__annual-plan-payment': 'One payment of',
        'prime__monthly-plan-payment': 'Monthly payment of',
        'prime__alert-title': 'BECOME PRIME TERMS AND CONDITIONS',
        'prime__alert-body':
            'Once the Tipti Prime membership trial period is activated, the user will be able to enjoy the following benefits: unlimited free shipping and select shoppers. The Tipti Prime trial version does not include: exclusive promotions and Tipti Prime Day CONSIDERATIONS: Valid 1 month from activation Once the trial month is over, the respective Tipti Prime charge (monthly plan) will be charged automatically via the app to the credit or debit card that you have registered, on the 31st of each month. In the event that automatic payment cannot be made, the membership will be automatically canceled. Only products within ',
        'prime__alert_accept-terms': 'I have read and accept the terms and conditions',
        'prime__alert-terms-accepted': 'Accept the terms and conditions',
        'prime__alert-terms': 'Terms and conditions',
        'prime__membership-name': 'Prime Membership',
        'prime__subscription-create': 'Your subscription has been created successfully',
        'prime__subscription-no-create': 'Your subscription could not be created',
        'tipti__card-recharge': 'DO YOU WANT TO RECHARGE YOUR TIPTI CARD?',
        'payphone__select-number': 'Phone numbers saved',
        'payphone__delete-number': 'The phone number was successfully deleted',
        'payphone__delete-number-failed': 'The phone number could not be deleted',
        'help-support__faqs-description': 'Get to know us, sectors of coverage, hours and delivery time.',
        'help-support__prices-title': 'Prices',
        'help-support__prices-description': 'Stock and product prices, order delivery fee.',
        'help-support__orders-title': 'Orders',
        'help-support__orders-description': 'Information about my orders and payment methods.',
        'help-support__shoppers-description': 'Our team in charge of your purchase.',
        'faqs__what-is-tipti': 'What is Tipti?',
        'faqs__tipti-is':
            "Tipti means “Time for you”. We are an e-Commerce company oriented to supermarket's sales, specialized stores and home delivery through digital platforms (App or web). Our purpose is to connect a team of specialized buyers with users/consumers who are trying to get their groceries done.",
        'faqs__how-it-works': 'How does Tipti work?',
        'faqs__to-access-service':
            'To access the service, it is necessary to download the application on any mobile device or access www.tipti.market and create a personal account. Once the account has been created by placing all the personal information such as address, billing information and telephone number, the preferred store must be selected to access the products they offer and be able to place them in the virtual cart. When all the products have been selected, proceed to finalize the purchase by selecting the delivery time and the payment method. After this process, Tipti Shopper will be assigned to make the purchase and deliver the order as requested.',
        'faqs__what-can-buy': 'What can I buy?',
        'faqs__you-can-buy':
            'In Tipti you can buy your favorite products from supermarkets and specialized stores, to facilitate the search they are classified by categories. Our offer is divided into supermarket, pharmacy, pets, delicatessen, stationery, seafood restaurant, flower shop, bakery, party supplies, various articles and household items, as well as in alliance with foundations solidarity donations can be made.',
        'faqs__what-delivery-schedule': 'What is the order delivery schedule?',
        'faqs__delivery-schedule':
            'The delivery time will be according to the hours of operation of the affiliated stores. TIPTI works from Monday to Sunday and holidays. You can place your order at any time and look for the available hours of each store in your APP. On December 25 and January 1 there is no attention. Hours of operation are subject to change according to changes within the same stores.',
        'faqs__in-wich-cities': 'In which cities is TIPTI located?',
        'faqs__is-available-in-cities': 'TIPTI - Time for you service is available in the cities of {availableCities}',
        'faqs__if-not-found-sector':
            'If you cannot find your sector, you can register your area on our website <a href="https://www.tipti.market" style="color:#FF9012;text-decoration:none" rel="noopener">www.tipti.market</a> and we will confirm when TIPTI will have coverage.',
        'faqs__address-warning':
            '** WARNING: If the delivery address does not correspond to the selected sector, your order may be subject to cancellation and its policies.',
        'faqs__how-fast-are-deliveries': 'How fast are the deliveries',
        'faqs__type-delivery-schedule': 'Scheduled',
        'faqs__type-delivery-express': 'Express',
        'faqs__exist-two-deliveries': 'There are two types of deliveries:',
        'faqs__delivery-schedule-description':
            'Within two to three hours in the same day, with the possibility of planning it up to the next week.',
        'faqs__delivery-express-description':
            'One hour, the minimum time will vary on the place of delivery and purchase',
        'faqs__which-cards-pay': 'With which cards can I pay for my order',
        'faqs__before-payment':
            "To pay with your cards, please make sure you have them enabled in your bank for online purchases. We accept {cards} from any bank and we accept payments through Payphone. (Debit cards accepted according to your bank's permits) ",
        'faqs__why-maximun-amount': 'Why do you have a maximum amount with card payment?',
        'faqs__subject-authorized-amounts':
            'Tipti is subject to the amounts authorized by the affiliated Banks. The maximum amount with your credit cards is {currency} 1000 {currencyName}. To make a card payment over  {currency} 1,000.00 {currencyName}, you can use the following cards: {cards} from any bank and we accept payments through Payphone.',
        'faqs__exist-minumun-amount-card': 'Is there a minimum amount to purchase with a card?',
        'faqs__no-exist-minumun-amount':
            'In Tipti there is no minimum amount to buy, place your order, and pay for your order with any of our payment methods available on the app. If your payment is by card, the minimum amount is {currency} 1.00 {currencyName} for your transaction to be successful.',
        'faqs__why-not-accept-card': "Why won't my credit card be accepted?",
        'faqs__works-for-more-payments':
            'Tipti is working so that in the future you have more payment options, contact our CARE TEAM at {mainPhone} or {secondNumber} or through our WHATSAPP {cellphone} or leave us an email at {careteam} and tell us which card you want to make your payment with, and we will contact you when we incorporate it into our payment methods.',
        'faqs-prices__fresh-products-by-weight': 'Are there fresh produce by weight?',
        'faqs-prices__can-select-fresh-products':
            'Yes, you can select fresh products, our shoppers will choose the best for you. In addition, you can add a note to the order within each product with details of how you want the cut of the fish, the meat or even how ripe you want the fruit. You can buy your products by weight or by units. ',
        'faqs-prices__what-do-product-no-exist': 'What do I do if I want a product that is not in Tipti?',
        'faqs-prices__our-product-list-is-based':
            'Our list of products is based on the availability of the associated shops. Within our app there is the option to report when you cannot find a product, let us know what you are looking for and we will report it to our affiliated stores. Another way is to choose a similar product in presentation and content, but you can specify in a note to your shopper the product you need. ',
        'faqs-prices__price-are-different': "Are Tipti's prices different from those in stores?",
        'faqs-prices__can-see-prices-in-apps':
            'The prices that you can see in the app are the same prices as those of the stores (TIPTI PRICE). You may find some differences due to the constant updating of prices that our associated retails have. However within Tipti you can find many unique promotions and better prices than the stores. ',
        'faqs-orders__why-pay-before-delivery': 'Why should I pay for my order before delivery?',
        'faqs-orders__we-take-care':
            'At Tipti our priority is your peace of mind, which is why we have a team of qualified shoppers who will deliver your order with the quality you expect. The confirmation of your payment allows us to speed up the purchase process and the final delivery of your products.',
        'faqs-orders__how-check-order-status': 'How do I check the status of my order?',
        'faqs-orders__all-time-show-status':
            'At all times, the application shows you the status of your order both on the screen and via email. Once the order has been approved you can validate it within your app as an accepted order.',
        'faqs-orders__can-cancel-order': 'Can I cancel an order?',
        'faqs-orders__yes-although-only':
            'Yes, but you can only do it before the shopper is making purchases in the store or supermarket. Remember that in case of TOTAL cancellation of the order, the payment will be refunded to the credit card with which the purchase was made and if the cancellation is made within the same day of purchase and if it is the original transaction. Otherwise, it will be reversed by TIPTI in a maximum of 48 hours and the bank can do it within the next 30 days to the card directly with a 5% surcharge for bank charges. If you want to know more about the return policies you can contact our CARE TEAM at the numbers {mainPhone} or {secondNumber} or by our WHATSAPP {cellphone} or leave us an email at',
        'faqs-orders__report-problem-order': 'How do I report a problem with my order?',
        'faqs-orders__yes-can-contact-careteam':
            'If you have any problem with your order you can contact our CARE TEAM to the numbers {mainPhone} or {secondNumber} or by our WHATSAPP {cellphone} or leave us an email at {careteam}',
        'faqs-orders__check-purchase-bill': 'How do I check my order invoice?',
        'faqs-orders__in-order-history':
            'In “Order History” you can check the orders placed and the products of each of your orders. Also, you will receive an email with the purchased ticket where you can see in detail everything that has been charged to you and each of the changes that have been made to your order. If you want to view it within the app in the History of your orders, you can review your initial order and your final order with the replacements and changes.',
        'faqs-orders__check-order-bill': 'How do I check the invoice of my order?',
        'faqs-orders__virtual-bill':
            "Tipti's virtual invoice will arrive once the order is delivered to the email registered in your billing information on your App. If you do not see it, and if it is your first purchase please check in your Spam. Once you give permission to the TIPTI account, you will receive your invoices in a normal way to your email. Always check that your email address is spelled correctly to avoid bounces.",
        'faqs-orders__how-use-tipticard-balance': 'How do I use my TIPTI CARD balance?',
        'faqs-orders__choose-tipticard-pay':
            'For you to make use of your credit balance in your next purchase after completing your order, when choosing a payment method, you must select TIPTI CARD for the total or partial payment of your order. If another payment method is needed to complete your purchase you can use payphone, credit/debit card, transfer or cash up to {currency} 50 {currencyName}, so you will have a mixed payment in your order. Remember to follow these steps, otherwise, your TIPTI CARD balance will remain on your card.',
        'faqs-orders__how-return-tipi-bags': "How do I return Tipti's bags?",
        'faqs-orders__every-time-order-arrive':
            'Every time an order arrives, you can keep the bags, but we encourage you to return them to the Shopper in the next order that arrives. The environment will thank you.',
        'faqs-orders__how-order-without-plastic-bags': 'How do I order my purchases in non-plastic bags?',
        'faqs-orders__in-all-stores':
            'In all Tipti stores, you can find Tipti non-plastic bags available to buy, and our shoppers will bring your products inside those. We also have a non-plastic bags suscription where you can buy a certain number of bags for a year. We will deliver your products in these bags in all your orders and we will reuse them in the next orders. In each order, your shopper will give you new non-plastic bags and you will return the same number to us.',
        'faqs-orders__how-return-product': 'How do I return a product?',
        'faqs-orders__only-accepted-return':
            'Returns will only be accepted for products that arrive in poor condition or that you have not requested until within 2 days after the delivery date. Any product that was requested in error is not the responsibility of Tipti S.A. However, if there is a product in your order that is missing or does not correspond to what you chose, you can contact {mainPhone} or {secondNumber} or by our WHATSAPP {cellphone} or leave us an email at',
        'faqs-orders__some-products-not-delivered':
            'They did not deliver some products that I ordered, who will give me my money back?',
        'faqs-orders__products-shopper-not-found':
            'These are products that your shopper did not find in your selected store or they were not in good condition. All the values ​​on these products are automatically returned to your Tipti Card so that you can use this balance in your future purchases.',
        'faqs-orders__what-should-payment-declined': 'What should I do if my payment is declined?',
        'faqs-orders__if-payment-declined':
            'If your payment was rejected, you can change the payment method from your account so that the payment is approved or you can contact {mainPhone} or {secondNumber} or by our WHATSAPP {cellphone} or leave us an email to {careteam}',
        'faqs-orders__pay-order-many-times': 'Did I pay for my order several times?',
        'faqs-orders__if-show-double-transactions':
            'If you show double transactions on your cards please contact {mainPhone} or {secondNumber} or through our WHATSAPP {cellphone} or leave us an email at {careteam}',
        'faqs-orders__how-can-regenerate-orderr': 'How can I re-generate my order if I received a declined payment?',
        'faqs-orders__from-order-history':
            'From the history of your orders, you can always send your order back to the cart to avoid having to repeat your entire order and reorder with another form of payment.',
        'faqs-orders__how-can-change-payment': 'How can I change my payment method if I received a declined payment?',
        'faqs-orders__inside-your-order':
            'Within your order, in the information of it you will see the option to change your payment method. There you can choose or create a new payment method for your order to enter successfully.',
        'faqs-orders__can-repeat-order': 'How do I use my TIPTI CARD as a form of payment? ',
        'faqs-orders__yes-inside-app':
            'Once you have your order ready, you will proceed to buy, and in the payment method section you have to click on the option to change it and choose first the TIPTI CARD payment method. If there is a money difference to cancel, you can choose your preferred payment method to be able to create your order and use your credit balance.',
        'faqs-orders__tipti-save-data': 'Does Tipti save my card details? ',
        'faqs-orders__no-tipti-never':
            "No, Tipti never stores any data of the clients' cards, nor numbering, nor CVC, nor expiration. We use electronic payment methods provided by private banking and other international services, thus avoiding storing such sensitive data.",
        'faqs-orders__not-have-card': "I don't have a credit card, how else can I pay for Tipti's service? ",
        'faqs-orders__cash-payments':
            'Cash payments can be made in consumptions no greater than {currency} 25.00 {currencyName}. Within the App you have the option to make your payments by transfer, just choose the account that corresponds to your bank, make the payment, upload the photo and we will help you with your order. ',
        'faqs-orders__how-can-pay-payphone': 'How can I pay with Payphone?',
        'faqs-orders__to-speed-up':
            'To speed up the process, we recommend having the PayPhone application downloaded and your cards active. All Visa and Mastercard credit cards of any issuing bank in the world are accepted, Produbanco debit cards',
        'faqs-orders__no-have-payphonep':
            'In case you not have the Payphone app installed yet, visit <a href="https://livepayphone.com/" style="color:#FF9012;text-decoration:none" rel="noopener"> https://livepayphone.com / </a> ',
        'faqs-orders__after-activate-cards':
            'After having activated your cards in PayPhone, enter the Tipti application and continue the purchase normally.',
        'faqs-orders__select-payphone-payment':
            'Select the Payphone payment method, enter the phone number registered in your PayPhone application.',
        'faqs-orders__authorize-the-collection':
            'Authorize the collection by Tipti and the voucher will arrive on your phone. ',
        'faqs-orders__for-more-information':
            'For more information about the advantages of PayPhone you can go to <a href="https://livepayphone.com/" style="color:#FF9012;text-decoration:none" rel="noopener"> https://livepayphone.com/ </a>',
        'faqs-shoppers__who-take-my-order': 'Who will take care of my order?',
        'faqs-shoppers__your orders-assigned':
            'our orders are automatically assigned based on a series of factors to one of the Tipti shoppers available for that area and delivery time.',
        'faqs-shoppers__can-multiple-shoppers': 'Can I have multiple shoppers in my order?',
        'faqs-shoppers__yes-you-can':
            'Yes, in your order you can have your shopping shopper who are our expert gourmet shoppers in food selection. They will be your assistants throughout your order ensuring the best replacements, and you can have your delivery shopper that will help you with the delivery of your purchase on time.',
        'faqs-shoppers__what-all-shoppers': 'What if all of our shoppers are busy?',
        'faqs-shoppers__automatic-check':
            'Delivery times are automatically calculated by our system, ensuring the availability of both the stores and our Tipti shoppers.',
        'faqs-shoppers__can-tip-shopper': 'Can I tip the shopper?',
        'faqs-shoppers__yes-can-tip':
            'Of course. In fact, we encourage you to do so as 100% of the tip goes to the shopper. You can give the tip at the delivery or you can also do it from the App. Once the order is delivered you have the option to rate your shopper and leave a tip.',
        'faqs-shoppers__what-shopper-do': 'What does a shopper do?',
        'faqs-shoppers__what_permissions': 'Why does the Tipti Shopper application require access to my location? ',
        'faqs-shoppers__with_the_purpose':
            'In order to more efficiently manage the allocation of orders. Allow Tipti to use your location all the time, even when the app is not in use',
        'faqs-shoppers__a-tipti-shopper':
            'A Tipti Shopper is a specialized person who will be in charge of purchasing for the user in the chosen store and will take them to their home at the chosen time and place.',
        'faqs-shoppers__can-work-as': 'Can I work as a shopper?',
        'faqs-shoppers__in-tipti-always':
            'At Tipti we are always looking for heroes and heroines to support the purchase and delivery of supermarket products!',
        'faqs-shoppers__requirements': 'Requirements',
        'faqs-shoppers__requirements-university': 'University or graduate',
        'faqs-shoppers__requirements-experience': 'Retail experience',
        'faqs-shoppers__requirements-three': 'Experience in customer service',
        'faqs-shoppers__requirements-with-car': 'With and without vehicle',
        'faqs-shoppers__even-if-we':
            'Even if we do not provide service in your city yet, we recommend that you fill out the application at <a href="https://www.tipti.market" style="color:#FF9012;text-decoration:none"> www.tipti.market </a> ',
        'faqs-shoppers__what-knowledge-should': 'What knowledge should a shopper have?',
        'faqs-shoppers__a-shopper-must':
            'A shopper must be an expert and professional in making purchases, a personalized advisor. They must be pleasant in dealing with customers.',
        'faqs-shoppers__people-interested-in': 'People interested in becoming a shopper',
        'faqs__how-can-we-help-you': 'How can we help you?',
        'faqs__tipti-express': 'Tipti Express',
        'faqs__order-in-process': 'Order in process',
        'faqs__make-an-order': 'Make an order',
        'faqs__my-tipti-card': 'My Tipti Card',
        'faqs__delivery-of-your-order': 'Delivery of your order',
        'faqs__promotions-discounts-rates': 'Promotions, discounts, rates',
        'faqs__profile-and-settings': 'Profile and settings',
        faqs__contact_us: 'Contact us',
        'faqs__your-order-is-for-elderly-adult': 'Is your order for seniors?',
        faqs__problems_with_delivery: 'Problems with delivery',
        faqs__coverage_sectors: 'Coverage sectors',
        'faqs__what-is-tipti_content': 'Find out who we are and learn about the best shopping experience.',
        'faqs__tipti-express_content': 'Enjoy Express Box and purchases with ultra-fast delivery time.',
        'faqs__order-in-process_content': 'Track your order, approve replacements and chat with your shopper',
        'faqs__make-an-order_content': 'Request your products, get your purchases and make shared purchases',
        'faqs__my-tipti-card_content': 'Discover all the benefits your Tipti Card offers you',
        'faqs__delivery-of-your-order_content':
            'Receive your purchases, report problems, solve your collection questions',
        'faqs__promotions-discounts-rates_content':
            'We have your products at the best prices, service fees, coupons and discounts.',
        'faqs__profile-and-settings_content':
            'Set up your profile, notifications and more, according to your preferences',
        faqs__contact_us_content: 'Contact our Care Team customer service team, always ready to assist you',
        'faqs__delivery-of-your-order_content_main':
            'Once your shopping consultant has finalized your order, according to our quality standards, your delivery shopper will take your order to your destination within the chosen delivery schedule. In order for you to receive your order at the requested time and without any inconvenience, we recommend you to take into account these aspects:',
        'faqs__delivery-of-your-order_content_main__one':
            'Activate notifications to receive updates on the status of your order.',
        'faqs__delivery-of-your-order_content_main__two':
            'As the delivery time approaches, be on the lookout for messages or possible calls from your shopper.',
        'faqs__delivery-of-your-order_content_main__three':
            'Place instructions that can be helpful for the shopper to quickly identify the delivery address and provide you with an optimal delivery service.',
        'faqs__delivery-of-your-subtitle__one': `My delivery shopper can't find my address`,
        'faqs__delivery-of-your-order_content_main__four':
            'If your delivery shopper does not find the address described, he/she will contact you via call, internal chat or WhatsApp. In case of no response or not being able to receive the purchase in the established schedule, you can contact the Care Team customer service team to reschedule your purchase, in case of having perishable or frozen foods in your order we do not assume responsibility for them. In case of not being able to receive the purchase definitely, we will proceed to cancel the order with a penalty of the total value of the order.',
        'faqs__delivery-of-your-subtitle__two': 'Incorrect delivery address',
        'faqs__delivery-of-your-order_content_main__five':
            'In case you have entered your order, but after reviewing the information you realized that it is not the correct address, please contact our Care Team Customer Service at the following number',
        faqs__number__USA: '1700 22 73 83',
        'faqs__delivery-of-your-order_content_main__six': 'or contact us by Whatsapp at',
        faqs__number__EC: '+593 99 554 0223',
        'faqs__delivery-of-your-order_content_main__seven': 'so that they can guide you with your change of direction.',
        'faqs__delivery-of-your-order_content_main__eight':
            'Remember that it is possible to modify your order, change the delivery address and time or cancel it as long as your order has not been finalized in the store.',
        'faqs__what-is-tipti_content_main': 'means more time for you and your family.',
        'faqs__what-is-tipti_content_main__one':
            'We offer a personalized shopping assistance service, ensuring that your products arrive where you are safely. Each of our shopping advisors are experts in product selection, trained and prepared to provide the best service. Our consultants meet the highest quality standards, making shopping a magical experience.',
        'faqs__what-is-tipti_content_main__two':
            'Shopping with Tipti is easy. First download our app, from the Play Store, App Store, or App Gallery, or log in at',
        'faqs__what-is-tipti_content_main__three':
            'and create your personal account. Once you have registered, filling in all the requested data, you will be able to choose the store of your preference and add the products you wish to buy to your cart. To finalize your purchase, select the delivery time and payment method of your choice.',
        'faqs__what-is-tipti_content_main__four': `You're done! Your order will be assigned to one of our purchase advisors to be picked up by your delivery shopper and proceed with the shipment of your order. Delivery will be made within 1 hour, after your shopping advisor has completed your order. Remember that you can schedule your order, up to one week, on the day and time of your preference.`,
        faqs__content: 'Content',
        'faqs__tipti-express_content_main':
            'Express orders are orders of 1 to 15 products. These orders have a maximum handling and delivery period of 1 hour. The shipping cost may vary depending on the amount of your purchase and the delivery sector.',
        'faqs__tipti-express_subtitle': 'Supermaxi Caja Express',
        'faqs__tipti-express_content_main__one':
            'Do you want to receive your products faster? The Supermaxi Caja Express store delivers your orders much faster than in regular stores. In this store you will be able to finalize an order of 1 to 10 products, and your purchase advisor will manage your purchase quickly and delivery schedule in half an hour. Find this store by selecting "Express" in the store categories menu, there you will find it under the name "Supermaxi Caja Express".',
        'faqs__your-order-is-for-elderly-adult_content_main':
            'TIPTI seeks to provide the best shopping experience to all its users and therefore offers a specialized service and support for our customers over 65 years old. You can request this service by contacting our senior agents through Care Team customer service who will create your profile, set up your account and take your order via phone call or WhatsApp, and finally enter it into the app for you.',
        'faqs__your-order-is-for-elderly-adult_content_main__one':
            'Our Care Team is a committed and dedicated team that provides effective and prompt support to all its clients, but above all a personalized assistance that keeps all our seniors well cared for.',
        'faqs__your-order-is-for-elderly-adult_content_main__two':
            'Our customer service channels are open from Monday to Sunday from 8:30 am to 10:00 pm.',
        'faqs__your-order-is-for-elderly-adult_content_main__subtitle__one': 'Call:',
        'faqs__your-order-is-for-elderly-adult_content_main__three': '1700 227 383 | +593 3826 090',
        'faqs__your-order-is-for-elderly-adult_content_main__four': '+593 99-401-1368 / Option two',
        'faqs__your-order-is-for-elderly-adult_content': 'Specialized care for the elderly',
        faqs__problems_with_delivery_content_main: `If you received your order and you are not completely satisfied with the products or the service you received. Don't worry, our Care Team is available for you every day from 8:30 to 22:00 to help you with any inconvenience.`,
        faqs__problems_with_delivery_content_main__one:
            'Remember that you have up to 24 hours after receiving your order to report it. Once the case is managed, and if it is the case, we will return the value of the product to your Tipti Card or we will arrange the delivery or exchange of the product.',
        'faqs__promotions-discounts-rates__subtitle__one': 'Is fresh produce available by weight?',
        'faqs__promotions-discounts-rates__subtitle__two': 'What do I do if I want a product that is not in Tipti?',
        'faqs__promotions-discounts-rates__subtitle__three': `Are Tipti's prices different from those of the stores?`,
        'faqs__promotions-discounts-rates__main__one':
            'Yes, you can select fresh products, our shoppers will choose the best ones for you. In addition, you can add a note to the order inside each product with details of how you want the fish or meat cut or even how ripe you want the fruit. You can buy your products by weight or by unit.',
        'faqs__promotions-discounts-rates__main__two': `Our product listing is based on the availability of our partner stores. Within our app there is a report option when you can't find a product, let us know what you are looking for and we will report it to our affiliated stores. Another way is to choose a product similar in presentation and content, but you can specify in a note to your shopper the product you need.`,
        'faqs__promotions-discounts-rates__main__three':
            'The prices that you can see in the app are the same as the prices on the hanger of the stores (TIPTI PRICE). You may find some differences due to the constant updating of prices that our partner retailers have. However within Tipti you can find many unique promotions and better prices than the stores.',
        'faqs__my-tipti-card__subtitle__one': 'How do I use my TIPTI CARD balance?',
        'faqs__my-tipti-card__subtitle__two': 'Returns and Balance on your Tipti Card',
        'faqs__my-tipti-card__main__one':
            'TIPTI CARD is a tool within Tipti with which you can manage your credit balance. With this tool you can place orders, recharge, gift and transfer credit to other Tipti accounts, or convert your balance into MaxiDollars.',
        'faqs__my-tipti-card__main__two':
            'In order to use your credit balance in your next purchase, when choosing the payment method you must select the TIPTI CARD option, or use balance, for the total or partial payment of your order. If you need another payment method to complete the value of the purchase you can use PayPhone, credit/debit card, transfer or cash up to $ 50, so you will have a mixed payment in your order. Remember to follow these steps, otherwise your TIPTI CARD balance will remain on your card.',
        'faqs__my-tipti-card__main__three':
            'When your shopper does not find the products you requested available in the store and you do not like the replacement proposed by the shopper, this product is not purchased. In these cases the values on these products are automatically returned to your Tipti Card so that you can use this balance in your future purchases.',
        'faqs__profile-and-settings__subtitle__one': 'How can I access my Profile?',
        'faqs__profile-and-settings__subtitle__two': 'How can I activate my Notifications?',
        'faqs__profile-and-settings__main__one':
            'By logging into your profile you will be able to edit: your account, name, address, payment method or phone number, birthday and more. You will also be able to find your Tipti ID, which is your user number. If you wish to edit your information, follow the steps below:',
        'faqs__profile-and-settings__main__two':
            '1. Go to the menu in the upper left corner of your app or in the upper left corner of our website.',
        'faqs__profile-and-settings__main__three':
            '2. Under your profile picture you will find the option "My Account".',
        'faqs__profile-and-settings__main__four':
            '3. By clicking here you will see your information and you will be able to edit all the fields.',
        'faqs__profile-and-settings__main__five':
            'Tipti allows you to activate or deactivate your notifications and emails so you can enjoy Tipti as you wish. Remember that by activating notifications you will be able to easily check the status of your order, be aware of the chat with your purchase advisor and receive emails about our current promotions. To activate notifications and e-mails follow these steps:',
        'faqs__profile-and-settings__main__six':
            '1. Go to the menu in the top left corner of your app or in the top left corner of our website.',
        'faqs__profile-and-settings__main__seven':
            '2. Under your profile picture you will find the "Notifications" option.',
        'faqs__profile-and-settings__main__eight':
            '3. When you click here you will see the configuration icon on the upper right side, when you click on it you will be able to activate the notifications.',
        'faqs__order-in-process__subtitle': 'How can I see the status of my order?',
        'faqs__order-in-process__main__one':
            'In your Tipti App or web, you will be able to view the status of your order from the moment you place your order until your purchase has been delivered to your door. Remember to always have your app updated and notifications activated.',
        'faqs__order-in-process__main__two':
            'In case you need it or have any inconvenience you can contact our Care Team at',
        'faqs__order-in-process__main__ten': 'or contact us by Whatsapp at',
        'faqs__order-in-process__main__three': '1. Find your order:',
        'faqs__order-in-process__main__four':
            'Once you have entered your order, you will find a banner at the top of the main screen indicating that you have one or more active orders.',
        'faqs__order-in-process__main__five': '2. Order status:',
        'faqs__order-in-process__main__six':
            'By clicking on the order you wish to review, you will be able to find the status of your active orders, the details of your order and additional information.',
        'faqs__order-in-process__main__seven': 'Add products to my order:',
        'faqs__order-in-process__main__eight':
            'Have you ever forgotten to buy something at the supermarket? With Tipti we give you the possibility to add more products, or Extra Items, as long as your order has not been finalized in the Store. You have the possibility to add up to a maximum of 10 products, which your shopping advisor will choose as part of your order.',
        'faqs__order-in-process__main__nine':
            'You can contact your purchase advisor via chat or phone call. You will find the call and chat icon next to the picture of your purchase advisor in the detail of your active order. Remember that once the purchase is completed, you will not be able to contact your advisor or add more products to your cart.',
        'faqs__make-an-order__subtitle__one': 'How do I place my first order with Tipti?',
        'faqs__make-an-order__subtitle__two': 'Shared purchases',
        'faqs__make-an-order__main_stg__one': '1.Download our app:',
        'faqs__make-an-order__main_stg__two': '2.Select your favorite store:',
        'faqs__make-an-order__main_stg__three': '3.Search for your favorite products:',
        'faqs__make-an-order__main_stg__four': '4.Add your products to the cart:',
        'faqs__make-an-order__main_stg__five': '5.Check that your cart is ready:',
        'faqs__make-an-order__main_stg__six': '6. To finalize your purchases confirm your data:',
        'faqs__make-an-order__main__one':
            'Choosing your favorite products has never been easier, follow the steps below to enjoy the best shopping experience on your mobile device:',
        'faqs__make-an-order__main__two':
            'Download Tipti from the Play Store, App Store and App Gallery, or go to www.tipti.market and create your personal account.',
        'faqs__make-an-order__main__three':
            'TIPTI offers you a diverse catalog of stores where you can find a wide variety of products.',
        'faqs__make-an-order__main__four':
            'You can do it directly from the search engine or by entering the categories of the selected store.',
        'faqs__make-an-order__main__five':
            'Once you have chosen your product, click on it and select the quantity you want using the + or - buttons to add it to the cart. If any of the products you want is not in our catalog, remember that you can select a similar product and add a note to your shopping advisor with the specifications of the product you want.',
        'faqs__make-an-order__main__six':
            'At the top right of our app or website you will find your shopping cart icon. Inside the cart, you can delete or add products, decrease quantities and check the total cost of your order until it is perfect.',
        'faqs__make-an-order__main__seven':
            'Choose your address and delivery time, enter your billing information and select the payment method you wish to use to make the purchase.',
        'faqs__make-an-order__main__eight': `You're done! Soon one of our purchasing advisors will take your order and start with your purchase.`,
        'faqs__make-an-order__main__share_stg__one': '1. Log in to shared shopping:',
        'faqs__make-an-order__main__share_stg__two': '2. Create a shared cart:',
        'faqs__make-an-order__main__share_stg__three': '3. Shop together: ',
        'faqs__make-an-order__main__share__one':
            'Need to make a purchase with many people involved? Tipti has the solution, do your shopping with friends, family or co-workers, using our shared carts:',
        'faqs__make-an-order__main__share__two':
            'Enter the cart, inside the cart screen you will find in the upper section the section: My carts. Inside you will be able to select one of the following options: create a personal cart, create a shared cart or select a shared cart of which you are already a member.',
        'faqs__make-an-order__main__share__three':
            'Once you have entered the section you will find a button to generate a new shared cart. Selecting it will take you to a screen where you can name the cart and add members. You can add as many people as you wish using their email address or Tipti ID. Remember that you can only invite Tipti users.',
        'faqs__make-an-order__main__share__four':
            'All members can add products to the shared cart. Once the selection of products has been completed, only one member of the cart will be able to finalize the purchase.',
        'faqs__make-an-order__main__share__five': `You're done! Soon one of our purchasing advisors will take the order and start with your purchase.`,
        'go-back': 'go back',
        'bar-vertical': 'bar-vertical',
        'contact-header': 'contact-header',
        'help-support__contact-us': 'Contact us',
        'help-support__your-satisfaction': 'Your satisfaction is our priority',
        'help-support__work-for-you': 'We work for you.',
        'help-support__how-help-you': 'HOW CAN WE HELP YOU?',
        'help-support__select-a-faq': 'Select your topic of interest',
        'order__status-some-orders': 'You have several active orders',
        'order__status-review-status': 'Check the status of each one here',
        'order__status-show-more': 'Show more',
        'payment-effective__if-your-payment':
            'If your payment is not exact let us know the value with which you are going to cancel to send the change with our Tipti shopper',
        'payment-effective__need-change-of': 'I need change for a dollar bill',
        'payment-effective__have-exact-amount': 'I have the exact amount',
        'payment-transfer__number-account': 'Account number',
        'payment-transfer__type-account': 'Account type',
        'payment-transfer__wire-transfer-checking': 'Current Account',
        'payment-transfer__wire-transfer-savings': 'Savings Account',
        'payment-transfer__attach-your-receipt': 'Attach your receipt',
        'payment-transfer__voucher-was-successfully': 'The voucher was attached successfully',
        'payment-transfer__voucher-not-be': 'The voucher could not be attached',
        'payment-transfer__extension_allowed': 'Allowed formats (png, jpg, jpeg, pdf)',
        'payment-nequi__title': 'Phone number',
        'payment-nequi__description': 'Enter the phone number enabled in Nequi',
        'payment-nequi__input-placeholder': 'Nequi phone number',
        'payment-nequi__phone-numbers-list': 'Previous numbers used',
        'payment-yappy__description': 'Please finish the payment process \n on the Yappy page.',
        'checkout__enter-billing': 'Choose the billing information for your order',
        'checkout__enter-payment-method': 'Choose the payment method for your order',
        'checkout__enter-address': 'Choose a delivery address for your order',
        'checkout__enter-billing-title': 'Change billing information',
        'checkout__enter-payment-method-title': 'Change payment method',
        'checkout__enter-address-title': 'Change delivery address',
        'checkout__enter-schedule-title': 'Select delivery schedule',
        'checkout__enter-billing-data': 'Billing data',
        'checkout__enter-schedule': 'Choose the delivery time or schedule it for one week from today',
        'checkout__order-creation-failure': 'An error occured in the order creation',
        'checkout__order-too_many_request':
            'For security, we have a limit of orders created in a row. Please try again later.',
        'checkout__order-not_created': 'Order not created.',
        'checkout__order-too_many_purchases':
            'For security, we have a limit of purchases created in a row. Please try again later.',
        'schedule__free-shipping': 'Free shipping',
        'schedule__delivery-one-hour': 'Delivery in one hour',
        'schedule__select-delivery': 'Select a delivery schedule for this store',
        'schedule__select-delivery-placeholder': 'Select a delivery schedule for placing the order of this store',
        'schedule__change-schedule': 'Change of schedule',
        'schedule__change-schedule-alert':
            'There are several minutes since you started placing your order and the schedules have changed for the following stores, please select a new one',
        'schedule__change-schedule-header':
            'There are several minutes since you started placing your order and the schedules have changed, please select a new one',
        'add-payphone__the-total-of': 'The total of your order is',
        'add-payphone__enter-your-number': 'Enter your number to make the payment',
        'add-payphone__add-number-success': 'The number was added successfully',
        'add-payphone__add-number-failed': 'The number could not be added',
        'add-payphone__not-valid-number': 'The phone number is invalid',
        'tipti-card__you-need-recharge': 'You must recharge your Tipti Card',
        'institutional__alert-title': 'Institucional amount',
        'mix-payment__are-you-sure':
            'Are you sure to pay {currency} {amount} with your {method}? The difference of {currency} {difference} can be paid using another payment method.',
        'address__address-change-success': 'The address has been changed successfully',
        'address__address-change-failed': 'The address could not be changed',
        'cambrellas__header-title': 'Shopping bags',
        'recommendations__header-title': 'You may also be interested',
        'rate-shopper__alert-success-message': 'Your rating has been sent successfully',
        'rate-shopper__balance_tip':
            'Your tip will be payed with your Tipti Card balance, currently you only have {currency_symbol}{tipticard_amount}',
        'rate-shopper__congratulations': 'Congratulations',
        'rate-shopper__your-purchase-made': 'Your purchase has been made',
        'rate-shopper__rate-your-experience-header': 'Rate your experience with {shopper_name} and leave us a comment',
        'rate-shopper__rate-your-experience': 'Rate your experience',
        'rate-shopper__notification-rate-your-experience': 'Rate your shopping experience',
        'rate-shopper__how-was-purchase': 'How was your purchase',
        'rate-shopper__how-was-delivery': 'How was your delivery',
        'rate-shopper__score': 'Score',
        'rate-shopper__question-leave-tip': 'Do you want to leave a tip?',
        'rate-shopper__tip-greater-than': 'The tip must be greater than',
        'rate-shopper__leave-a-comment': 'Leave a comment',
        'rate-shopper__leave-comment-here': 'Leave your comment here...',
        'rate-shopper__leave-a-compliant': 'LEAVE A COMPLIANT TO YOUR SHOPPER',
        'rate-shopper__fields-are-required': '*The following fields are required',
        'rate-shopper__profesionalism': 'Profesionalism',
        'rate-shopper__good-atention': 'Good atention',
        'rate-shopper__friendly-and-polite': 'Friendly and polite',
        'rate-shopper__clean-and-well': 'Clean and well presented',
        'rate-shopper__order-packaging': 'Order in the packaging of purchases',
        'rate-shopper__puntuality': 'Puntuality',
        'rate-shopper__comments-and-suggestions': 'YOUR COMMENTS AND SUGGESTIONS WILL SERVE US',
        'rate-shopper__improve-your-experience': 'TO IMPROVE YOUR EXPERIENCE IN TIPTI',
        'rate-shopper__feature-correct-replacements': 'Correct replacements',
        'rate-shopper__feature-followed-instructions': 'Followed instructions',
        'rate-shopper__feature-complete-order': 'Complete order',
        'rate-shopper__feature-quality-products': 'Quality Products',
        'rate-shopper__message-complete':
            'You help us a lot with your opinion! If you have any suggestions for your next order, please contact our CARE TEAM team.',
        'tipti-card__min-transfer-amount': '* The amount should be higher than {currency} 1',
        'tipti-card__max-transfer-amount': '* The maximum amount to transfer should be {currency} {amount}',
        'tipti-card__accumulate-balance': 'Accumulate balance',
        'tipti-card__remember-that': 'Remember that you can cancel your subscription at any time.',
        'tipti-card__are-you-sure': 'Are you sure to cancel Tipti PRIME?',
        'tipti-card__btn-share': 'Share Tipti Card number',
        'tipti-card__txt-mail-subject': "Tipti Card's number",
        'tipti-card__txt-share':
            '{userName} has shared his/her Tipti Card number with you. You can copy it and use it for transfers or giftcards. Number: ',
        'tipti-card__we-are-sorry':
            'We are sorry you left us, remember that you can retake your subscription whenever you want. Your subscription will be active until {date}',
        'tipti-card__use-balance': 'Use balance',
        'tipti-card__alert-title': 'Tipti Card Balance',
        'tipti-card__alert-description':
            'To complete your order, please select another payment method to pay the difference of {currency}{amount}',
        'tipti-card__do-you-wish': 'Would you like to use your Tipti Card Balance?',
        'tipti-card__available-balance': 'Available balance is',
        'tipti-card__free-shipping': 'Free shipping',
        'tipti-card__send-gift-card': 'Send Gift Card',
        'tipti-card__buy-recharge': 'Buy Recharge',
        'tipti-card__my-gift-card': 'My Gift Card',
        'tipti-card__transfer-balance': 'Transfer Balance',
        'tipti-card__recharge-msj': 'Select the amount you want to recharge',
        'tipti-card__recharge-msj-2': 'in',
        'tipti-card__date-send': 'Send date',
        'tipti-card__user': 'Client',
        'tipti-card__empty-moves-msj1':
            'You still have no movements on your Tipti Card, once you start using your balance, you will be able to see the usage history on this screen',
        'tipti-card__empty-moves-msj2':
            'Your Tipti Card is a virtual card that you can only use in Tipti, your balance is accumulated in case there is a refund from us or also if you buy a recharge of the value you want.',
        'tipti-card__minimum-rechage': 'The minimum amount to recharge your tipticard is {currency} 1.00',
        'tipti-card__maximum-rechage': 'The maximum amount to recharge your tipticard is {currency} {amount}',
        'retailers-modal__title': 'Choose one of the participating stores to make your purchase',
        'retailers-modal__you_may_find': 'You can find  ',
        'retailers-modal__in_the_following': ' in the following stores near to you',
        'product-not-found__title': 'Uups!',
        'product-not-found__message': 'Apparently the product you requested is not found in any store around you.',
        'deeplink-modal__active-code-success': 'Your code has been activated',
        'deeplink-modal__active-code-error': 'Your code has not been activated',
        'deeplink-modal__active-referred-success': 'Your referred code has  been activated',
        'deeplink-modal__active-referred-error': 'Your referred code has not been activated',
        'deeplink-modal__active-tipticard': 'Your balance of {currency} {balance} has been credited to your TiptiCard',
        'deeplink-modal__retailer-not-found': 'There is no such retailer for this sector',
        'order_status__buy-assistant': 'Purchase advisor',
        'order_status__accepted-order-second': 'will prepare your order',
        'extra__items-alert-remember-that': 'Remember that you can only add {count} more items to your purchase',
        'extra__items-alert-add-items':
            'You can only add up to {limit} items to your purchase you already have {actual} items selected',
        'payment__send-success': 'The payment was successful',
        'payment__send-failed': 'Payment could not be made, please try again',
        'recovery__password-insert-the-mail-one': 'Enter the',
        'recovery__password-insert-the-mail-two': 'Mail',
        'recovery__password-insert-the-mail-three':
            'with which you registered and we will send you a code to reset your password',
        'recovery__password-send-mail-succes': 'The mail has been sent successfully',
        'recovery__password-send-mail-fail': 'The email address entered is not correct, please try again',
        'recovery__password-insert-the-code-one': 'Enter the',
        'recovery__password-insert-the-code-two': 'that we send to your email to reset your password',
        'recovery__password-send-code-success': 'The code has been sent successfully',
        'recovery__password-send-code-fail': 'The code entered is not correct, please try again',
        'recovery__password-send-password-success': 'The password has been changed successfully',
        'recovery__password-send-password-fail': 'The password could not be changed',
        'recovery__password-insert-new_password-start': 'Please, enter your new ',
        'recovery__password-insert-new_password-middle': 'password',
        'recovery__password-dont-forget-code': '* Do not forget to enter the code',
        'share-product__subject': 'Share product',
        'share-product__message': 'Select your favorite network to share this product with your friends',
        'share-product__message-share':
            'Hello, 😄 {user_name} has shared an {product_name} with you, you can find it in Tipti App ',
        'share__list-title': 'Select your favorite network to share the list with your friends',
        'share__list-msg-send':
            'Hello 🤗 Click here to add my buying list to your Tipti Cart 🛒  {link} 🍏🍇🍅 so we can send your supermarket buyings to your home',
        'cart__continue-to-delivery': 'Continue to delivery information',
        'tutorial__store-change': 'You can change stores from this button at any time',
        'tutorial__login-or-register': 'You can login or register here',
        'tutorial__check-shopping-cart': 'Check your cart here every time you have added products',
        'tutorial__store-choose': 'Choose a store to make your purchases',
        'tutorial__news-updates': 'Find relevant news, updates and new features here.',
        'tutorial__promos-discounts': 'Find here the promos and discounts of the day',
        'tutorial__navigate-to-categories': 'Click to browse categories from selected store',
        'tutorial__shopping-list': 'Click to check your saved shopping lists',
        'tutorial__check-coupons': 'Click to review your available coupons',
        'tutorial__review-active-orders': 'Click here to review your active orders and your history',
        'tutorial__change-sector': 'You can change your location for your purchase here',
        'tutorial__delivery-choose': 'Choose delivery to see the available delivery options',
        'tutorial__pickup-choose': 'Choose pickup to check stores with available pickup',
        'tutorial__location-choose': 'Set the location where you want to receive your purchases.',
        'main-products__recommended-categories': 'Recommended categories',
        'address__delete-address-success': 'The address was successfully removed',
        'address__delete-address-failed': 'The address could not be removed',
        'address__tipti-needs-at': 'Tipti needs at least one address for purchase process',
        'address__add-address-success': 'Address added',
        'address__edit-address-success': 'Address edited',
        'address__edit-address-failed': 'Error editing address',
        'address__no-content-description': "You don't have addresses yet",
        'address__no-content-title': 'No Addresses',
        'address__failure-one-address-default': 'You need at least one default address',
        'referred__first-order': 'Your first order',
        'referred__code-referred':
            'Enter your friends code and win {currency} {amountGift} so you can use it on your first order',
        'referred__button-referred': 'Validate',
        'referred__text-input': 'Type your code',
        'alert__alcoholic-beverages': 'Wait! Are you older than 18?',
        'alert__otp-enter-code': 'Enter your code',
        'alert__wrong-otp': 'OTP entered is wrong, Try again',
        'burguer__button-news': 'Press',
        'retailer__form-we-want-your': 'We want to be your partners',
        'retailer__form-fill-requerid-fields': '* Please fill in all required fields',
        'retailer__form-success': 'An agent will contact you in a while',
        'retailer__form-failure': 'An error occurred, try again later',
        'retailer__form-procedure-aceept-that':
            'Procedure, I accept that Tipti or its representatives may contact me by email, phone or text message to the data that I register, including commercial purposes',
        'retailer__form-please-is-important': '* Please fill in all required fields',
        'bill__verify-your-data': 'Verify your data',
        'bill__validate-your-data':
            'Verify that the data is correct. Once the order has been entered, the information on the invoice issued cannot be changed.',
        'bill__form-type-billing-names': 'First and last names',
        'bill__form-type-billing-rason': 'Company name',
        'bill__form-type-billing-rason-placeholder': 'John Smith',
        'bill__form-identifier-ruc': 'T.I.N.',
        'bill__form-identifier-passport': 'Passport',
        'bill__form-no-have-ci': "Don't you have a National Document ID number?",
        'resume__purchase-instructions-enter-your': 'Enter your comments or instructions here',
        'resume__purchase-instructions-see-terms': 'See terms and conditions',
        'promotions__without-promotions': 'No Promotions',
        'promotions__no-exist-promotions': 'There are no promotions at the moment',
        'promotions__txt-error-loading-promotions': 'An error occured while loading promotions',
        'home__category-recive-your-purchase': 'AND RECEIVE YOUR PURCHASES THE SAME DAY',
        'home__category-ask-for': 'Ask for your',
        'home__category-ask-products': 'Ask for products of',
        'home__category-vegetables': 'Vegetables',
        'home__category-fruits': 'Fruits',
        'home__category-meats': 'Meats',
        'home__category-babys': 'Babys',
        'home__category-cleaning': 'Cleaning',
        'news__press-resources-imagotype': 'Imagotype',
        'add__payphone-example-number': 'Eg: 0988527711',
        'share__cart-my-personal-cart': 'My personal cart',
        'share__cart-create-a-share': 'Create a shared cart',
        'share__cart-make-your-pruchases': 'Make your purchases with friends, family or colleagues',
        'login__enter-here': 'Login here',
        'credit__cards-registered-cards': 'Registered Cards',
        'address_add-address': 'Add address',
        'checkout__menu-no-select-schedule': 'Schedule not selected',
        'checkout__menu-please-select-schedule': 'Please select a time to continue',
        'banner__covid-tipti-shopper-with': 'Tipti shopper with biosafety suit',
        'share__cart-my-carts': 'My carts',
        'share__cart-your-share-carts': 'Your shared carts',
        'bill__form-message-success-form': 'Your data has been {message} successfully!',
        'bill__form-message-failed-form': 'An error occurred while {message} your data try again ...',
        'product-modal__replace-sugguest': 'Replacement suggestion',
        stories: 'Stories',
        sponsor: 'sponsored',
        'referred__msg-code-send':
            '💵 💵 I give you {currency} {amount} as a present for you to buy in Tipti for the first time, Register with this invitation 💵 💵. {link} Your code is:  {code}',
        'banner-tipti-prime__tittle': 'Enjoy your Tipti Prime membership!',
        'banner-tipti-prime__subtittle': 'Remember you have these benefits:',
        'banner-tipti-prime__shipping-one': 'Unlimited shipping from wherever you are',
        'banner-tipti-prime__shipping-two': 'Unlimited shipping from wherever you are.',
        'banner-tipti-prime__sale-one': 'Special sales',
        'banner-tipti-prime__sale-two': 'Every month enjoy exclusive promotions for you',
        'banner-tipti-prime__prime-one': 'Tipti Prime Day',
        'banner-tipti-prime__prime-two': '*Once a year enjoy a 50% discount in all our stores',
        'card__save-success': 'Your card has been saved successfully!',
        'card__save-error': 'Your card could not be saved.Try again',
        'billing__delete-data-success': 'Your data has been deleted successfully!',
        'billing__change-success': 'Updated billing information.',
        'billing__change-error': 'Could not update.',
        'billing__delete-data-error': 'Error on delete your data, please try again ..',
        'banner-promotions__discover': 'DISCOVER',
        'banner-promotions__the-offers': 'THE OFFERS',
        'banner-promotions__that-every': 'THAT EVERY',
        'banner-promotions__store-has': 'STORE HAS',
        'banner-promotions__for-you': 'FOR YOU',
        'retailers__minibanner-offers': 'OBTAIN EXCLUSIVE OFFERS',
        'retailers__minibanner-transport-icon': 'Transport icon',
        'retailers__minibanner-discount-icon': 'Discount icon',
        'retailers__minibanner-cook-icon': 'Store icon',
        'retailers__minibanner-store-icon': 'Cook icon',
        'retailers__minibanner-calendar-icon': 'Calendar icon',
        'schelude__no-schedule-title': 'There are not schedule',
        'schelude__no-schedule-subtitle': 'There are not schedules avalible for this retailer.',
        'statistics__orders-by-stores': 'Orders by store',
        'statistics__accumulated-orders': 'Accumulated Orders',
        'statistics__total-savings': 'Monthly amount saved',
        'statistics__my-orders-with-tipti': 'My Orders with Tipti',
        'statistics__no-orders-detail': "You don't have orders this month",
        'statistics__my-experience-tipti': 'My Tipti experience',
        'banner-goal__txt-footer': 'Product selection and availability may change depending on store stock',
        'bills-documents__search-bills': 'Search by order number',
        'bills-modal__error-identifier': 'Your identity document is not correct, please verify it and try again.',
        'retailer__no-promotion-message': "There aren't retailers with this promotion for your sector",
        'product-note__save-alert-title': 'Do you want to save your note?',
        'product-note__save-alert-content': 'You have an unsaved note, do you want to save it before exiting?',
        'tipti-prime__your-amount': 'Your bills is',
        'complete-profile__not-forget-name': "Don't forget to enter your name",
        'complete-profile__not-forget-lastname': "Don't forget to enter your lastname",
        'complete-profile__not-forget-phone': "Don't forget to enter your phone number",
        'complete-profile__not-forget-email': "Don't forget to enter your email",
        'complete-profile__not-forget-document': "Don't forget to enter your identification number",
        'complete-profile__not-forget-birthday': "Don't forget to enter your birthday date",
        'cart-bar__cashback-title': 'Cashback',
        'cart-bar__cashback-content': 'The value of your cashback will be credited once your purchase is delivered',
        chat__location: 'Location',
        chat__gallery: 'Photo gallery',
        chat__camera: 'Camera',
        chat__placeholder: 'Write a message here',
        'chat__error-description': 'The chat could not be loaded',
        'chat__notification-title': 'New message from shopper',
        'chat__error-load-messages': 'An error ocurred while loading the chats, please try again',
        'chat__error-sending-message': 'An error ocurred while sending the message, please try again',
        'chat__error-loading-location': 'An error ocurred while getting your location',
        'chat__chat-by-whatsapp': 'Chat by whatsapp',
        'chat__finish-chat-alert-content':
            'This chat is closed because the order has been delivered, if you need help with your order please contact our Care Team {phone} or Whatsapp {whatsapp}',
        product_on_demand__shopper_suggestion: "Shopper's suggestion",
        delivery__far_delivery_point: 'Far delivery point',
        delivery__point_of_delivery: 'The selected delivery point exceeds the coverage limits in your',
        delivery__for_that_motive:
            ' membership. For this reason we will charge the difference in your delivery sector.',
        delivery__remember_some_sectors: 'We remind you that some sectors have an additional shipping cost.',
        change_address: 'Changing delivery address',
        not_found_products: 'Products not found',
        search_replacements: 'Search for replacements',
        continue_to_payment: 'Continue to payment information',
        not_found_in_stock: 'These are the products that were not in stock:',
        unique_delivery_info_message: 'Delivery in one hour | Delivery in a hour | Delivery in {count} hours',
        'secure-shopper__selection-process-at-tipti': 'Selection process at Tipti',
        'secure-shopper__order-status-verification': 'Order status verification',
        'secure-shopper__time-and-quality': 'Time and quality',
        'secure-shopper__tipti-care-team': 'Tipti Care Team',
        'secure-shopper__verified-identity': 'Verified Identity',
        'secure-shopper__verified-identity-details':
            'The Shopper has been selected by our Human Resources team under an intense selection process validating their documentation, criminal record, recommendations and merits.',
        'secure-shopper__member-since': 'Tipti member since',
        'secure-shopper__achievements': 'Achievements',
        'secure-shopper__contact': 'Contact',
        'secure-shopper__call-shopper': 'Call the Shopper',
        'secure-shopper__chat-with-shopper': 'Chat with the Shopper',
        'secure-shopper__security': 'Security',
        'secure-shopper__security__details-1':
            '¡We care about your safety! \nAll of our shoppers, before being hired, go through a selection process and periodically receive training. Today, we respond to the security conditions demanded by the country.',
        'secure-shopper__security__details-2': 'Here you can learn more about it:',
        'secure-shopper__security__details-3': 'If your question does not appear here, please contact us.',
        'secure-shopper__selection-process__paragraph-1':
            'We contribute to your safety! \nOur Shoppers are properly identified, and like all our employees, they go through strict processes for their entry and permanence in the company. \nWe make sure that all of them take ownership of our corporate culture and that they are honest and responsible people. Delivery vehicles are registered and have updated legal information. ',
        'secure-shopper__order-status__paragraph-1':
            'To ensure maximum transparency and security; you can review the status of your order - in the app or on the web - from the moment you place your order until you receive it at your doorstep. Remember to keep your app up to date and activate notifications!',
        'secure-shopper__order-status__heading-1': ' How can you track your order?',
        'secure-shopper__order-status__paragraph-2':
            '1. Once you have entered your order, you will find a banner with your active orders at the top of your main screen.',
        'secure-shopper__order-status__paragraph-3':
            '2. By clicking on your order you will be able to see all the information and real-time status of your order.',
        'secure-shopper__order-status__paragraph-4':
            'If you need to contact you personally, you will find the icon with the picture of your advisor, either to call or activate the chat.',
        'secure-shopper__order-status__paragraph-5':
            'You will be able to add products and communicate with him/her before the purchase has been finalized in the application.',
        'secure-shopper__time-and-quality__paragraph-1':
            'We comply with the highest-quality standards, both for the selection of your products and for your safety. \nWe are prepared to provide you with the best service to make shopping a magical experience.',
        'secure-shopper__time-and-quality__paragraph-2':
            'At Tipti you can find your favorite products, organized by categories, in supermarkets and specialty stores. \nYour delivery can be scheduled, and you will receive it within 2 to 3 hours after you have completed your purchase. You also have the possibility to schedule your delivery up to a week from now.',
        'secure-shopper__time-and-quality__paragraph-3':
            'Your delivery can be express and you will receive your products within 1 hour. This service is subject to delivery zone conditions.',
        'secure-shopper__tipti-care-team__paragraph-1':
            'We are in charge of responding with the speed that our clients demand in case any doubt or difficulty arises with their experience in our application. \nWe provide effective and immediate solutions. ',
        'secure-shopper__tipti-care-team__paragraph-2':
            'Our main objective is to guarantee customer satisfaction. \nWe are prepared to respond and act in case there is any doubt about the security and reliability of your delivery.',
        'secure-shopper__tipti-care-team__paragraph-3':
            'We are ready to help you from Monday to Sunday, from 7:00 am to 11:00 pm.',
        'secure-shopper__tipti-care-team__any-doubts': 'If your question does not appear here, please contact us.',
        'buy-now': 'Buy now!',
        'ongoing-offers': 'Ongoing Offers',
        'tipti-wallet-gift-cards-title': 'Tipti Giftcards',
        'tipti-wallet-gift-cards-view-expired': 'View expired cards',
        'gift-card-available-balance': 'Available balance',
        'gift-card-expired-on': 'Expired on: ',
        'gift-card-latest-transactions': 'Latest transactions',
        'gift-card-transactions-placeholder-title': 'You still have no transactions with this card',
        'gift-card-transactions-placeholder-description':
            'You have not made any purchase with this card, start an order with the exclusive products of this card.',
        'gift-card-transactions-history-title': '{card_name} History',
        'gift-card-transactions-history-filter-all': 'All',
        'gift-card-transactions-history-filter-returns': 'Returns',
        'gift-card-transactions-history-filter-expenses': 'Expenses',
        'gift-card-transactions-history-view-all': 'View all my transactions',
        'gift-card-transaction-return': 'Return',
        'gift-card-valid-until': 'Valid until: ',
        'gift-card-available-stores-title': 'Available Stores',
        'gift-card-available-stores-description':
            'Here you can find the stores with all the products that you can use your card with.',
        'gift-card-placeholder-text': 'You dont have any active Giftcards',
        'gift-card-change-card-btn': 'Continue without Giftcards',
        'gift-card-checkout-available-balance-modal-description':
            'Do you want to use the balance you have available in your Tipti giftcards?',
        'gift-card-checkout-select-card': 'Select Gift Card',
        'for-your-home': 'For your home',
        'prescription-required': 'Prescription required',
        'format-invalid-file':
            'The file you are trying to upload is not in the correct format. Select a different file and reload it.',
        'you-have-everything-you-need': '¿You have everything you need?',
        'text-recommendations-items': 'Add recommended products to complete your order.',
        'text-product-not-available': 'Select a replacement if the product you want is out of stock in the store:',
        'text-recommendations-items-no-available': 'Product out of stock in store. Please choose a similar product:',
        'sold-out-in-store-not-available': 'Not',
        'sold-out-in-store': 'available',
        'to-sold-out-by': 'Very',
        'to-sold-out': 'Low',
        'replacement-suggestions': 'Replacement Suggestions',
        'select-a-replacement': 'Select a replacement if the product you want is out of stock in the store:',
        'product-out-of-stock-in-store': 'Product out of stock in store. Please choose a similar product:',
        'product-selected-successfully': 'Product selected successfully!',
        'product-is-not-available': 'If the product is not available, your shopper will select your replacement.',
        'no-product-was-selected': 'No product was selected to replace the one that was sold out.',
        'product-was-not-added': 'Product was not added to cart',
        'availability-of-our-products':
            'The availability of our products is subject to the physical inventory of the store and is currently unavailable.',
        'item-was-added-to-replace': 'This item was added to replace the unavailable product.',
        'has-accumulated': 'Has Accumulated',
        'points-text': 'POINTS',
        'accumulated-points': 'Accumulated Points',
    },

    cta: {
        here: 'here',
        delete_card: 'Delete card',
        done: 'Done',
        maxi_dollars__transfer: 'Transfer to my Tipti Card',
        communicate_with_advisor: 'Contact an Advisor',
        need_help_order: 'I need help with my order',
        go_to_orders: 'Go to orders',
        contact__care_team: 'Contact TIPTI CARE TEAM',
        change_payment_method: 'Change payment method',
        reload_page: 'Reload page',
        show_card: 'Show card',
        'change-to-pickup': 'Try Pickup',
        'continue-in-delivery': 'Continue with Delivery',
        try_again: 'Try again',
        exit: 'Exit',
        verify: 'Verify',
        'no_go-back': 'No, go back',
        yes_deactivate: 'Yes, deactivate',
        'see-image': 'View image',
        'add-image': 'Attach image',
        subscribe: 'Subscribe',
        close: 'Close',
        'no-delete': "Don't delete it",
        'delete-and-continue': 'Delete and continue purchasing',
        'yes-cancel': 'Yes, cancel',
        'no-return': 'No, return',
        'cancel-subscription': 'Cancel subscription',
        confirm: 'Confirm',
        'continue-with-facebook': 'Continue with Facebook',
        'cancel-order': 'Cancel order',
        'delete-replacement': 'Remove replacement',
        'empty-cart': 'Empty cart',
        'report-product': 'Report product',
        'continue-shopping': 'Continue shopping',
        add: 'Add',
        become: 'Become',
        accept: 'Accept',
        'accept-terms': 'Accept terms and conditions',
        reject: 'Reject',
        'legal-age': 'Yes, I am of legal age',
        'not-yet': 'Not yet',
        'add-to-list': 'Add to a list',
        'add-list': 'Add to list',
        'add-to-list-to-card': 'Sending list to cart',
        'see-more-news': 'See more news',
        delete: 'Delete',
        'see-more-sectors': 'See more sectors',
        'close-session': 'Log out',
        'deactivate-account': 'Deactivate Account',
        'about-tipti': 'About Tipti',
        'work-with-us': 'Work with us',
        'coverage-sectors': 'Coverage sectors',
        'order-state': "Order's state",
        information: 'Information',
        'codes-promotions': 'Code and Promotions',
        'help-and-support': 'Help and support',
        change: 'Change',
        'save-changes': 'Save Changes',
        'complete-profile': 'Complete',
        'my-account': 'My Account',
        notifications: 'Notifications',
        referred: 'Referrals',
        subscriptions: 'Subscriptions',
        settings: 'Settings',
        cancel: 'Cancel',
        continue: 'Continue',
        home: 'Home',
        categories: 'Categories',
        'my-lists': 'My Lists',
        'lists-and-recipes': 'Lists and Recipes',
        coupons: 'Codes',
        'my-coupons': 'My codes',
        orders: 'My orders',
        'not-find-product': 'Did not find what you searched?',
        'shared-carts': 'Shared carts',
        'my-cart': 'My Cart',
        'new-user': 'New user',
        'more-information': '*For more information, click here.',
        save: 'Save',
        'see-more': 'See more',
        'see-less': 'See less',
        'add-note': 'Add note',
        'change-password': 'Change Password',
        'modified-note': 'Save note',
        'confirm-replace': 'Confirm replace',
        replacement: 'Replacement',
        'add-to-cart': 'Add to cart',
        'add-products': 'Add products',
        'add-products-to-cart': 'Add Products to Cart',
        'save-list': 'Add to my lists',
        'schedule-list': 'Schedule List',
        'cancel-schedule-list': 'Cancel Schedule List',
        edit: 'Edit',
        back: 'Back',
        'annual-plan': 'Activate annual plan',
        'month-plan': 'Activate monthly plan',
        'try-plan': 'Activate test',
        contact: 'Contact shopper',
        'change-payment': 'Change payment',
        'add-schedule': 'Add schedule',
        'add-number': 'Add number',
        'continue-buying': 'Continue buying',
        'my-order': 'My order',
        'rate-shopper': 'Rate Shopper',
        'send-tip': 'Send tip',
        'contact-shopper': 'Contact shopper',
        buy: 'Buy',
        'recharge-my-tipticard': 'Recharge my Tipti Card',
        'see-more-items': 'See more items',
        search: 'Search',
        'skip-rate-shopper': 'Skip',
        'skip-tutorial': 'Skip tutorial',
        skip: 'Skip',
        next: 'Next',
        finish: 'Finish',
        'log-in': 'Log in',
        menu: 'Menu',
        'continue-with-apple': 'Continue with Apple',
        'home-register': 'Sign up here',
        authorize: 'Authorize',
        'not-authorize': "Don't Authorize",
        'see-bills': 'See Bills',
        'btn-yes-save': 'Yes, save',
        'btn-not-discard': 'No, don´t save',
        'continue-with-google': 'Continue with Google',
        update: 'Update',
        'btn-login-google': 'Sign in with Google',
        'btn-login-facebook': 'Sign in with Facebook',
        'btn-register-google': 'Sign up with Google',
        'btn-register-facebook': 'Sign up with Facebook',
        defer: 'defer',
        'go-payments': 'Go to payment methods',
        'see-more-suggestions': 'See more suggestions',
        add_product: 'Add product',
        'see-similar-products': 'See similar products',
        add_to_cart: 'Add to cart',
        update_quantity: 'Update quantity',
        already_in_cart: 'In the cart',
        protection_of_data: 'Protection of personal data protection',
    },

    general: {
        other_payment_option: 'We have found that you have other options to pay:',
        or: 'either',
        pay_order: 'Pay order',
        add_new_card: 'Add new card',
        more_payments: 'See more payment methods',
        reason: 'Reason',
        go_to_buy: 'Go to buy',
        connection_error: 'A connection problem has occurred, please try again in a few minutes.',
        agree: 'I agree',
        dni_passport: 'Enter your passport',
        all_stores: 'All stores',
        retailer_filter_all_store: 'ALL STORES',
        go_back: 'Go back',
        hello: 'Hi',
        done: 'Continue',
        units: 'units',
        subscriptions: 'Subscriptions',
        pay__only: 'Just',
        terms_and_conditions__message: 'By acquiring our membership, you accept this',
        extra_items_buy__loading: 'Adding items to your order',
        extra_items_buy__success: 'Products added successfully',
        extra_items__added_products: 'Products added',
        extra_items__review_products: 'Review the products you are going to add',
        extra_items__need_something: 'Do you need anything else?',
        extra_items__need_something__legend: 'We have selected these products of interest based on your order',
        add_products: 'Add products',
        no_close_tab: 'Do not close this tab',
        need_something_more: '¿Necesitas algo más?',
        // x_selling__products_selected
        transferred: 'Transferred',
        searching_country: 'What country are you looking for?',
        value_payed: 'Paid value',
        value_to_pay: 'Value to pay',
        select_method: 'Select your payment method',
        select_method_description: 'Select the payment method with which you want to pay for your orders',
        communicate_with_advisor: 'Communicate with advisor',
        payment_rejected: 'Payment rejected',
        keep_shopping: 'Keep shopping',
        error_occurred: 'A problem has occurred, please try again',
        program: 'Program',
        accredited: 'Accredited',
        un_link: 'Unlink',
        affiliation: 'Affiliation',
        converter: 'Converter',
        your_benefits: 'Your benefits',
        active: 'Active',
        inactive: 'Disabled',
        validating_data: 'Validating data',
        'invalid-fields': 'Invalid data',
        'invalid-fields-name': 'No special characters, final consumer numbers are allowed.',
        'required-fields-alert': 'Remember to fill out the required fields',
        please_reload: 'Something was wrong, please reload the page',
        verification_failed: 'Verification failed',
        tipti_team: 'Tipti Care Team',
        attempts_remaining: 'Remaining attempts: ',
        value_incorrect: 'The entered value is incorrect, please try again',
        ups: 'Ups!',
        and: 'and',
        dont_close_app: 'Do not close the app or this tab until the process is finished!',
        dont_close_browser: 'please do not close the browser.',
        verifying: 'Verifying',
        approx: 'approx.',
        success: 'Success',
        leave_comment: 'Leave here your comments',
        recipes: 'No recipes | One recipe | {count} Recipes',
        'tipti-recipes': "Tipti's recipes",
        error: 'Error',
        skip: 'Skip',
        benefits: 'Benefits',
        yes: 'Yes',
        no: 'no',
        option: 'Option',
        has_problem: 'A problem has occurred',
        activated: 'Activated',
        'retail-name': 'Retailer name',
        'retail-type': 'Type of retailer',
        'enterprise-type': 'Business type',
        'contact-name': 'Contact name',
        featured: 'Featured',
        'product-out-stock': 'This item is out of stock.',
        'month-year': 'MM/YY',
        'almost-ready': 'Almost ready!',
        'wait-a-second': 'Wait a second.. We are loading your data!',
        'product-added': 'Your product has been added to your cart.',
        'you-may-interested': 'You may also be interested',
        note: 'Note',
        other: 'Other',
        'describe-motive': 'Leave here your reason',
        'delivery-time': 'Delivery time',
        'pickup-time': 'Pickup Schedule',
        'pickup-place': 'Pickup center',
        'load-more': 'Load more',
        items: 'one item | {count} items',
        'set-as-default': 'Set as default',
        'required-fields': 'Required fields',
        incorrect_number: 'Incorrect number',
        'billing-data': 'Billing data',
        statistics: 'Statistics',
        'by-weight': 'weight',
        'in-units': 'units',
        description: 'Description',
        high: 'High',
        medium: 'Medium',
        low: 'Low',
        fat: 'Fat',
        sugar: 'Sugar',
        salt: 'Salt',
        'your-message-sent': 'Your message was sent',
        'your-message-not-sent': 'Your message didn´t send, try it again.',
        'credit-debit-card': 'Credit / Debit Card',
        'special-price': 'Special price',
        'regular-price': 'Price',
        'product-name': "Product's name",
        'download-the-app': 'Download the app:',
        'expert-shoppers': 'Expert shoppers',
        see: 'See',
        'name-and-surname': 'Name and Surname',
        total: 'Total',
        'become-part-of': 'Become part of our family',
        'client-service-option': 'Client service ',
        'elder-people-service': 'Elder people service',
        'about-us': 'About us',
        sectors: 'Sectors',
        select_address_note: 'You can select your address by scrolling the map',
        map_address_direction: 'Enter here the address',
        cardAddress__reference: 'Building, floor, apartment',
        cardAddress__alias: 'Quito House',
        billing: 'Billing',
        retailers: 'Retailers',
        'see-retailers': 'See retailers',
        'payment-method': 'Payment method',
        news: 'Press room',
        'next-delivery': 'Next delivery',
        'terms-and-conditions': 'Terms and Conditions',
        'error-to': 'Error to {message}, try again..',
        copy: 'Copy',
        code: 'Code',
        name: 'Name',
        spanish: 'Spanish',
        english: 'English',
        'last-name': 'Last names',
        'document-type': 'Document type',
        dni: 'Identification document',
        dni_document: 'This ID number.',
        email: 'Email',
        cellphone: 'Phone number',
        genre: 'Genre',
        male: 'Male',
        order: 'Order',
        female: 'Female',
        password: 'Password',
        birthday: 'Birthday',
        'select-delivery-sector': 'Select your delivery sector',
        address: 'Address',
        delivery: 'Delivery',
        promotions: 'Promotions',
        default: 'Default',
        price: 'Price',
        discounts: 'Discounts',
        unit: 'Unit',
        'pick-up': 'Pickup',
        'not-information': 'Not information',
        'delivery-schedule': 'Delivery schedule',
        'billing-information': 'Billing information',
        filters: 'Filters',
        date: 'Date',
        hour: 'Hour',
        participants: 'No participants | One participant | {count} Participants',
        products: 'No items | One item | {count} Items',
        'payment-methods': 'Payment Methods',
        'payment-cards': 'Credit/Debit Cards',
        'payment-cash': 'Cash',
        'payment-deferred': 'Deferred payment',
        'payment-current': 'Current payment',
        'payment-wire-transfer': 'Wire transfer',
        'payment-online-bank-services': 'Online bank services',
        'payment-payphone': 'Payphone',
        'payment-tipti-card': 'Tipti Card',
        'payment-bank-check': 'Bank check',
        'payment-nequi': 'Nequi Payment',
        'payment-yappy': 'Yappy Payment',
        'search-on': 'Search on',
        'search-on-stores': 'Search in Tipti',
        share: 'Share',
        'no-products': 'No Products',
        send: 'Send',
        'covid-msg': 'Find out about our regulations with COVID-19',
        city: 'City',
        phone: 'Phone',
        message: 'Message',
        annual: 'Annual',
        monthly: 'Monthly',
        'best-offer': 'Best offer',
        'a-month': 'A month',
        'appply-terms-conditions': 'Apply terms and conditions',
        try: 'Try',
        by: 'by',
        'a-month-free': 'a Month Free',
        subtotal: 'SUBTOTAL',
        'subtotal-fee': 'SUBTOTAL FEE',
        iva: 'SALES TAX',
        savings: 'SAVINGS',
        'code-to-activate': 'DO YOU HAVE A CODE TO ACTIVATE?',
        'total-order': 'Total to pay',
        'your-bill': 'Your bill is',
        'select-payment-method': 'Select your payment method',
        select: 'Select',
        finalize: 'Finalize',
        subscribe: 'Subscribe',
        'available-balance': 'Available balance',
        'card-number': 'Card Number',
        'use-balance': 'Use Balance',
        'new-number': 'Add new phone number',
        notifications: 'Notifications',
        'accept-contact-me':
            'Procedure, I accept that Tipti or its representatives may contact me by email, phone or text message to the data that I register, including commercial purposes',
        'required-field': 'This field is required',
        'enter-valid-mail': 'Enter a valid email',
        calories: 'Calories',
        preparation: 'Preparation',
        'products-added-cart': 'The products were successfully added to the cart',
        'products-not-added-cart': 'An error occurred while adding the products',
        'payment-institutional': 'Institutional Payment',
        'institutional-assigned': 'Assigned institutions',
        ruc: 'T.I.N',
        number_ruc: 'Enter your T.I.N',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        'code-to-refill': 'DO YOU HAVE A REFILL CODE?',
        apply: 'Apply',
        'code-successfully': 'The code was entered successfully',
        'code-incorrect-or-already': 'The code entered is incorrect or has already been activated.',
        thanks: 'Thanks',
        'no-thanks': 'No, Thanks',
        no_thanks: 'No thanks',
        attention: 'Attention',
        resume: 'Purchase summary',
        number: 'Number',
        amount: 'Amount',
        'msj-not-value': "Don't forget to enter the value",
        language: 'en',
        'promotion-date': 'Promotion date',
        'participant-cities': 'Participating cities',
        'participant-retailers': 'Participating retailers',
        'groceries-advisor': 'Groceries advisor',
        'shopper-deliver': 'Delivery shopper',
        'tipticard-error': 'Activate yout TiptiCard',
        example: 'Eg: {message}',
        'select-retailer': 'Select your retailer',
        'recharge-tipticard': 'Recharge your TiptiCard',
        'buy-giftcard': 'Buy GiftCard',
        'transfer-amount': 'Transfer amount',
        'buy-gift-success': 'You have purchased a GiftCard of {currency} {value}',
        'open-product': 'Open product',
        'open-retailer': 'Open store',
        'open-recipe': 'Open recipe',
        'open-list': 'Open list',
        'see-recipe': 'See recipe',
        'open-categories': 'Open categories',
        'open-promotion': 'Open promotion',
        'open-stories': 'Open story',
        'open-screen': 'Open screen',
        'help-contacts': 'Help and Contacts',
        'client-service-option-mobile': 'Option {count}',
        'elder-people': 'Elder People?',
        'enter-your-coupon': 'Enter your coupon',
        'recharge-code': 'Redeem code',
        loading: 'Loading...',
        'fill-fields-to-continue': 'Fill all changes to continue',
        here: 'here',
        'see-more-products': 'see more products',
        'header-user-img': 'header user image',
        'header-user-generic-img': 'header user generic image',
        'page-not-found': 'Page not found',
        'close-button': 'Close button',
        'profile-picture': 'Profile picture',
        'profile-picture-generic': 'Profile picture generic',
        'remplace-for': 'Replace with',
        'coverage-sector': 'COVERAGE SECTORS',
        added: 'added',
        modified: 'modified',
        available: 'Available',
        redeemed: 'Redeemed',
        in: 'in',
        'order-without-changes': 'Order without changes',
        'delete-billing-information': 'Delete billing data',
        orders: 'No Orders | Order | Orders',
        year: 'Year',
        country: 'Country',
        'home-register': 'Are you new?',
        'deeplink-modal__general-error': 'No information related to this link was found',
        'billing-information__not_found': 'No billing information related to this order.',
        'issue-date': 'Date of issue',
        document: 'Document',
        'number-document': 'Document number',
        bill: 'Bill',
        'bills-documents': 'Bills and documents',
        'no-available': 'No available',
        dollar: 'Dollar|Dollars',
        'string-number': 'Three',
        'your-products': 'Your products',
        'products-general': 'Products',
        'search-results': 'Search results',
        'from-date': ', ',
        'general-login-with': 'Sign in with',
        'general-register-with': 'Sign up with',
        'general-error': 'Something was wrong',
        'try-again': 'Please, try again later',
        from: 'from',
        to: 'to',
        'transaction-error': 'An error occurred when we try the transaction, please try again later',
        chat: 'Chat',
        edit_credit_card: 'Edit card',
        before: 'Before',
        tipti_card: 'Tipti Card',
        tipti_card_prime: 'Want FREE shipping?',
        tipti_card_prime_benefits: 'Get benefits with',
        'news-text-one':
            'We work to extend our service so that more people can safely and digitally shop for their groceries. Every day, we prepare ourselves to serve better and create a pleasant experience for our customers.',
        'news-text-two':
            'In this section you will find our press releases and logos with their different applications.',
        'news-text-three': 'In addition, our most relevant news in the media are available.',
        'see-more-newsletters': 'See more newsletters',
        'press-releases': 'Press Releases',
        'news-in-the-med': 'News in the med',
        'download-complete-kit': 'Download Complete Kit',
        'download-brand-manual': 'Download Brand Manual',
        'brand-text':
            "Select the format(s) of our Artwork's that you need to download. Remember that by downloading any file from our site you are agreeing to have read and respected the",
        'brand-manual': 'Brand Manual',
        'file-formats': 'SVG/PNG FILE FORMATS',
        'for-websites-and-applications': 'For websites and applications',
        'logo-with-description': 'LOGO WITH DESCRIPTION',
        'flat-logo': 'FLAT LOGO',
        'logo-with-slogan': 'LOGO WITH SLOGAN',
        'logo-text-brand':
            'Each file contains the official TIPTI S.A. logo with its official colors, both positive and negative.',
        'download-svg': 'Download SVG',
        'related-content': 'Related content',
        copied: '¡Copied!',
        copied_text: 'Copied',
        source: 'Source',
        'news-press-room': 'News',
        'hello-news': 'Hello',
        'text-shared-news': 'has shared the following news with you:',
        'text-shared-news-two': 'you can find it at',
        'share-news': 'Share',
        'privacy-policy-about':'Privacy Policy',
        'help':'Help',
        'protection-of-personal-data':'Protection of Personal data',
        'talent-hotbeds': 'Talent Hotbeds'
    },

    icon: {
        card: 'Card',
        reload: 'Reload',
        attach_file: 'Attach file',
        send: 'Send',
        double_check_unread: 'Mensaje recibido',
        double_check_read: 'Mensaje recibido y leído',
        single_check: 'Message unsent',
        reply: 'Reply',
        default_card: 'Default card',
        validated_card: 'Validated card',
        success: 'Success',
        replace: 'Replace',
        close: 'Close',
        delete: 'Delete it',
        already_in_cart: 'In the cart',
        update_quantity: 'Update quantity',
        add_to_cart: 'Add to cart',
        add_to_lists: 'Add to list',
        address: 'Address',
        no_content: 'No content',
        adult: 'Censored, adults only',
        all_stores: 'All the stores',
        all_stores_selected: 'All the stores - selected',
        arrow: 'Arrow',
        hide: 'Hide',
        hidden: 'Hidden',
        show: 'Show',
        shown: 'Visible',
        back: 'Return',
        backward: 'Go backward',
        forward: 'Go forward',
        replacement: 'Replacement selected',
        auto_replace: 'Automatic replacement',
        no_profile_picture: 'No profile picture',
        shopping_bag: 'Shopping bag',
        bank: 'Bank',
        notifications: 'Notifications',
        bill: 'Bill',
        digital_documents: 'Bills and Digital documents',
        care_team: 'Care Team',
        cart: 'Cart',
        full_cart: 'Full Cart',
        cash: 'Cash as payment method',
        categories: 'Look out categories',
        copy_to_clipboard: 'Copy to clipboard',
        chat: 'Open chat',
        recipes: 'Recipes',
        error: 'Error',
        remove: 'Remove it',
        clean_and_good_presentation: 'Clean and good presentation',
        client_profile: "Client's profile",
        settings: 'Open settings',
        coupons: 'Coupons',
        credit_card: 'Credit card as payment method',
        yappy: 'yappy',
        delivered_and_rated: 'delivered and rated',
        document: 'Identity document',
        unfold: 'Unfold it',
        edit: 'Edit it',
        elder: 'Elder people',
        enter_code: 'Enter code',
        favorite: 'Mark as favorite',
        facebook: 'Facebook',
        delivery_frequency: 'Delivery frequency',
        friendly: 'Friendly',
        gallery: 'Gallery',
        email: 'Email',
        send_email: 'send Email',
        good_attention: 'Good attention',
        help: 'Help',
        home: 'Home',
        add: 'Add',
        information: 'Information',
        instagram: 'Instagram',
        institution: 'Institution as payment method',
        linked_in: 'Linked In',
        location: 'Location',
        logout: 'Log out',
        email_recovery: 'Recover email',
        menu: 'Menu',
        mute: 'Mute',
        lists: 'Lists',
        note: 'Note',
        save_note: 'Save Note',
        show_note: 'Show Note',
        online_bank: 'Online bank service',
        order_accepted: 'Order accepted',
        order_cancelled: 'Order cancelled',
        order_delivered: 'Order delivered',
        orders: 'My orders',
        order_packaging: 'Orden in packaging',
        password_recovery: 'Password recovery',
        payment_success: 'Payment success',
        payment_methods: 'Payment methods',
        payment_rejected: 'Payment rejected',
        payphone: 'Payphone',
        person: 'Made for',
        phone: 'Phone',
        prime: 'Tipti Prime',
        prime_day: 'Prime Day',
        prime_delivery: 'Prime Delivery',
        prime_diamond: 'Prime Diamond',
        prime_discounts: 'Prime discounts',
        prime_trial: 'Prime Trial',
        prime_badge: 'Prime badge',
        featured: 'Featured',
        prime_store: 'Prime store',
        product_added: 'Product added',
        product_description: "Product's description",
        professionalism: 'Professionalism',
        promotions: 'Promotions',
        puntuality: 'Puntuality',
        referreds: 'Referreds',
        schedule: 'Schedule',
        search: 'Search',
        share: 'Share',
        shared_cart: 'Shared cart',
        cart_members: 'Cart members',
        shipping: 'Shipping',
        shopper_assigned: 'Shopper assigned',
        shopper_buying: 'Shopper buying',
        shopper_delivering: 'Shopper delivering',
        shopper_in_destiny: 'Shopper in the destiny',
        rate: 'Rate',
        store_purchase_completed: 'Store purchase completed',
        store: 'Store',
        subscription: 'Subscriptions',
        tipti: "Tipti's logo",
        tipti_card: 'Tipti Card',
        buy_recharge: 'Buy recharge',
        my_gift_card: 'My Gift Cards',
        send_gift_card: 'Send Gift Card',
        transfer_amount: 'Transfer amount',
        twitter: 'Twitter',
        warning: 'Warning',
        whatsapp: 'Whatsapp',
        wire_transfer: 'Wire transfer as payment method',
        zoom: 'Zoom in',
        exchange: 'Convertir',
        broken_card: 'Sin tarjetas',
        data_protection: 'Data Protection',
        rewards: 'Rewards',
        arrow_shaft: 'Arrow with shaft',
        minus_circle: 'Circle wiht plus sign',
        plus_circle: 'Circle with minus sign',
        historyExchange: 'history Exchange',
    },
};
