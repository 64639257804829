<template>
    <background-with-transition :is-active="openSidebar" @on-bg-click="openSidebar = false">
        <main-menu @on-close="openSidebar = false" />
    </background-with-transition>

    <header ref="headerRef" class="header-container">
        <Suspense>
            <TheHeaderBanner @loaded="updateHeight" />
            <template #fallback>
                <BaseBone class="banner_skeleton" />
            </template>
        </Suspense>
        <header-main-mobile @show-menu="openSidebar = true" />
        <section class="header-main">
            <div class="nav-left-top">
                <div style="margin-right: 1rem" @click="openSidebar = true">
                    <icon-menu-hamburger :size="0.15" />
                </div>
                <router-link to="/">
                    <icon-tipti :size="0.6" />
                </router-link>
                <router-link v-if="!isHomePage || filterApplied" class="all_stores" to="/" @click="goToHome">
                    <icon-arrow class="all_stores__arrow" :size="0.6" color="#ff9012" />
                    <p class="all_stores__txt">
                        <span v-if="filterApplied">{{ $t('general.go_back') }}</span>
                        <span v-else>{{ $t('general.all_stores') }}</span>
                    </p>
                </router-link>
            </div>
            <div class="nav-middle-top">
                <base-btn
                    class="the-change-sector"
                    :is-loading="isLoading"
                    bg-color="white"
                    border-color="#DFDFDF"
                    border-radius="7px"
                    outline
                    padding=".5rem"
                    @click="openAddresses"
                >
                    <icon-location :size="0.7" color="#ff9012" />
                    <p class="the-change-sector__txt">
                        {{ addressName }}
                    </p>
                    <p class="the-change-sector__txt the-change-sector__txt--link">{{ $t('cta.change') }}</p>
                </base-btn>
                <search-products :origin-section="originSection" @on-search="onSearch" />
                <tipti-prime-card />
            </div>
            <the-cart-btn />
        </section>
        <TheStatusOrder v-if="$route.meta.show_notification_rate || $route.meta.is_detail_product" />
        <section v-if="$route.meta.is_detail_product" class="header-product"></section>
    </header>

    <alert-surplus />
    <tipti-map-modal
        v-if="showAddressModal"
        :has-close="false"
        is-from-register
        section="REGISTER"
        @on-close="onCloseMap"
    />
    <base-modal v-if="showRetailerNotFoundModal" :has-close="false" @on-close="showRetailerNotFoundModal = false">
        <div class="retailer-modal-content">
            <icon-order-cancelled :size="0.3" color="#f03f51" />
            <h2 class="retailer-modal-content__title">{{ $t('alerts.oops') }}</h2>
            <p class="retailer-modal-content__body">
                {{ $t('alerts.deeplink__retailer_not_found') }}
            </p>
        </div>
    </base-modal>
    <informative-modal v-if="showInformativeModal" />
    <address-modal v-if="modalAddressShowConfig.show" v-model="isLoading" :section="modalAddressShowConfig.section" />
</template>

<script lang="ts" setup>
import { CountriesModel } from '@/models/external/CountriesModel';
import { Sections } from '@/enums/sectionsOrigin';
import IconOrderCancelled from '@/components/icons/IconOrderCancelled.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import SearchProducts from '@/components/inputs/searchProducts/SearchProducts.vue';
import TheCartBtn from './components/TheCartBtn.vue';
import HeaderMainMobile from './mobile/HeaderMainMobile.vue';
import AlertSurplus from '@/components/alerts/alertSurplus/AlertSurplus.vue';
import IconMenuHamburger from '@/components/icons/IconMenuHamburger.vue';
import MainMenu from '@/modules/main-menu/components/MainMenu.vue';
import TiptiPrimeCard from '@/components/headers/headerMain/TiptiPrimeCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import { paymentMethodService } from '@/modules/payment-methods/infraestructure/PaymentMethodService';
import TheStatusOrder from '@/modules/main-products/components/TheStatusOrder/TheStatusOrder.vue';
import { useUser } from '@/composables/useUser';
import { useChat } from '@/composables/useChat';
import { useHeader } from '@/components/headers/headerMain/useHeader';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';
import { computed, defineAsyncComponent, ref, watch, watchEffect } from 'vue';
import { useApp } from '@/composables/useApp';
import { useCountry } from '@/composables/useCountry';
import { useCart } from '@/composables/useCart';
import { usePayments } from '@/composables/usePayments';
import { useDeeplink } from '@/composables/useDeeplink';
import TheHeaderBanner from '@/components/headers/HeaderBanner/TheHeaderBanner.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { usePromotion } from '@/composables/usePromotion';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import IconLocation from '@/components/icons/IconLocation.vue';

const TiptiMapModal = defineAsyncComponent(() => import('@/components/modal/tiptiMapModal/TiptiMapModal.vue'));
const AddressModal = defineAsyncComponent(() => import('@/components/modal/addressModal/AddressModal.vue'));
const InformativeModal = defineAsyncComponent(
    () => import('@/components/modal/informativeModal/informativeModal/InformativeModal.vue'),
);
const { $store, $t, $router, $route, notifier } = useApp();
const $emit = defineEmits<(e: 'loaded', value: boolean) => void>();
const { isHomePage } = useRoutes();
const { informativeBanner, informativeStory } = usePromotion();
const { fetchQuickCart } = useCart();
const { countries, getCountries } = useCountry();
const { getSurplusPayments, getDefaultPaymentTypes } = usePayments();
const { initChat, isConnected, connectUser, messagesUnread } = useChat();
const {
    baseConfiguration,
    requireAuth,
    addressDefault,
    modalAddressShowConfig,
    isAuth,
    fetchBaseConfiguration,
    profile,
    getProfile,
    userLocation,
    getAddresses,
} = useUser();
const { updateHeight, headerRef } = useHeader();

const { isPageNotifications, isScreenCheckout } = useRoutes();
const { messageFromShowModal, showRetailerNotFoundModal, showModal, successModal } = useDeeplink();
const { filterApplied } = useRetailer();

const showInformativeModal = computed(() => informativeBanner.value || informativeStory.value);

watch(
    () => $route.fullPath,
    () => {
        updateHeight();
        $store.commit('orders/isSelectedOrder', $route.meta.isInOrderPage ?? false);
        $store.commit('lists/isCategoryFromList', $route.meta.isCategoryList ?? false);
    },
);
watchEffect(() => {
    if (!showModal.value) return;
    notifier({
        type: successModal.value ? 'SUCCESS' : 'ERROR',
        body: messageFromShowModal.value,
        actions: {
            click: () => {
                showModal.value = false;
            },
            close: () => {
                showModal.value = false;
            },
        },
    });
});

const setupChat = async () => {
    if (!isAuth.value) return;
    $store.dispatch('notifications/setDeviceNotifications');
    if (!baseConfiguration.value?.chatServiceEnabled) return;
    initChat();
    await connectUser();
    await messagesUnread();
};

const showAddressModal = ref(false);
const openSidebar = ref(false);

const originSection = computed(() => (!isHomePage.value ? Sections.HOMES_RETAILER : Sections.RETAILERS));

const updateUserCountry = async (): Promise<void> => {
    await getCountries();
    const usersCountry: CountriesModel = profile.value?.currentSector
        ? countries.value?.find(
              (country: CountriesModel) => country.code === profile.value?.currentSector.city.country.code,
          )
        : countries.value?.[0];
    $store.commit('external/countrySelected', usersCountry ?? countries.value?.[0]);
};

const init = async () => {
    $emit('loaded', false);
    await Promise.allSettled([
        fetchBaseConfiguration(),
        paymentMethodService.deletedPromotions(),
        !isScreenCheckout.value ? fetchQuickCart() : Promise.resolve(),
        !isPageNotifications ? getSurplusPayments() : Promise.resolve(),
        getProfile(),
    ]);
    updateUserCountry();
    if (!userLocation.value) await getAddresses();
    $emit('loaded', true);
    if (!userLocation.value && isAuth.value) {
        showAddressModal.value = true;
        return;
    }
    setupChat();
    getDefaultPaymentTypes();
    $store.dispatch('segment/identify');
};

init();
const onCloseMap = (): void => {
    if (!userLocation.value) return;
    showAddressModal.value = false;
    init();
};
const onSearch = (value: string): void => {
    $router.push({
        name: appRoutesMap.retail.search,
        params: {
            searchOn: value,
        },
    });
};

const goToHome = () => {
    if (!filterApplied.value) return;
    filterApplied.value = undefined;
};

const isLoading = ref(false);

const openAddresses = (): void => {
    if (!isAuth.value) {
        requireAuth.value = true;
        return;
    }
    modalAddressShowConfig.show = true;
    modalAddressShowConfig.section = 'HEADER';
};

const getAddress = computed(() => userLocation.value ?? addressDefault.value);

const addressName = computed(() => {
    if (getAddress.value?.comment?.length) return getAddress.value.comment;
    else if (getAddress.value?.name?.length) return getAddress.value.name;
    return $t('txt.address_add-address');
});
</script>

<style lang="scss" scoped>
@import './header-main.scss';
</style>
