<template>
    <aside class="sidebar-menu">
        <section class="back-arrow" @click="$emit('on-close')">
            <icon-back color="#FF9012" />
        </section>
        <section class="profile-img">
            <UserProfilePhoto origin="menu" />
            <div class="personal-user-information">
                <h1>{{ profile.name }} {{ profile.lastname }}</h1>
                <h2>ID: {{ profile.id }}</h2>
            </div>
        </section>
        <tipti-prime-card class="sidebar-menu__banner" />
        <section class="options padding-x">
            <RouterLink
                v-for="option in sideBarOptions"
                :key="option.code"
                class="options__option"
                :to="{
                    name: option.route,
                }"
                @click="$emit('on-close')"
            >
                <component :is="option.component" class="option__component" :size="option.size" color="#606060" />
                <p class="profile-options__txt">{{ option.title }}</p>
            </RouterLink>
        </section>
        <section class="options-secondary padding-x">
            <RouterLink
                v-for="option in sideBarSecundaryOptions"
                :key="option.code"
                class="options__option"
                :target="option.target"
                :to="{ name: option.route }"
                @click="$emit('on-close')"
            >
                <component :is="option.component" class="option__component" :size="option.size" color="#606060" />
                <p class="profile-options__txt">{{ option.title }}</p>
            </RouterLink>
        </section>
        <section v-if="isAuth" class="close-session cursor-pointer" @click="showCloseSessionModal = true">
            <icon-logout :size="0.7" color="#606060" />
            <p class="profile-options__txt">{{ $t('cta.close-session') }}</p>
        </section>
    </aside>
    <close-session-modal v-if="showCloseSessionModal" @on-close="showCloseSessionModal = false" />
</template>

<script lang="ts" setup>
import IconBack from '@/components/icons/IconBack.vue';
import { useMainMenuOptions } from '@/modules/main-menu/composables/useMainMenuOptions';
import TiptiPrimeCard from '@/components/headers/headerMain/TiptiPrimeCard.vue';
import { useUser } from '@/composables/useUser';
import IconLogout from '@/components/icons/IconLogout.vue';
import CloseSessionModal from '@/components/modal/closeSessionModal/CloseSessionModal.vue';
import { ref } from 'vue';
import UserProfilePhoto from '@/views/myAccount/views/profile/components/profileOptions/UserProfilePhoto.vue';

defineEmits<(e: 'on-close') => void>();
const { sideBarOptions, sideBarSecundaryOptions, setBarOption } = useMainMenuOptions();
const { isAuth, profile } = useUser();
const showCloseSessionModal = ref(false);

setBarOption();
</script>

<style lang="scss" scoped>
@import 'main-menu.scss';
</style>
